import React from 'react';
import nextId from 'react-id-generator';
import PropTypes from 'prop-types';

import styles from './Progress.module.scss';

/**
 * Компонент отображает шкалу прогресса
 * по шагам
 */
class Progress extends React.Component {
    static propTypes = {
        /** Общее кол-во шагов */
        count: PropTypes.number.isRequired,
        /** Текущий шаг */
        current: PropTypes.number.isRequired,
    };

    render() {
        const { count, current } = this.props;
        const arrCount = new Array(count).fill('');
        return (
            <div
                className={styles.progress}
            >
                {
                    arrCount.map((item, index) => (
                        <div
                            className={`
                                ${styles.progress__item}
                                ${index <= current - 1 ? styles['progress__item--filled'] : ''}
                            `}
                            key={nextId('progress__item')}
                        />
                    ))
                }
            </div>
        );
    }
}

export default Progress;
