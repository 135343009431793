import {
    decorate,
    observable,
    action,
    computed,
} from 'mobx';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import projectsQuery from './queries';
import { getItem } from '../../utils/localStorage';
import eventEmitter from '../../utils/eventEmitter';

class ProjectListStore {
    constructor({
        statusListStore,
    }) {
        this.statusListStore = statusListStore;
    }

    loading = false;

    _activeProjectId = null;

    get activeProjectId() {
        if (this._activeProjectId === null) return null;
        return Number(this._activeProjectId);
    }

    list = [];

    get listWithStatuses() {
        const { list, statusListStore } = this;
        const result = [];
        list.forEach((project) => {
            const { requestssummary } = project;
            let qty = 0;
            if (requestssummary && requestssummary.length) {
                requestssummary.forEach((statusInfo) => {
                    const { requeststatus_id: statusId, count } = statusInfo;
                    if (statusListStore.statusIsActive(statusId)) {
                        qty += count;
                    }
                });
            }
            const data = {
                ...project,
                qty,
            };
            result.push(data);
        });
        return result;
    }

    init() {
        this.getData();
    }

    /**
     * Метод для добавления нового проекта
     * в список
     *
     * @param {object} param0 -
     * @param {string} param0.id id
     * @param {string} param0.name имя проекта
     */
    addToList({
        id,
        name,
        requestssummary,
    }) {
        const duplicate = this.list.find((item) => Number(item.id) === Number(id));
        if (!duplicate) {
            this.list.push({
                id,
                name,
                link: `/requests?project=${id}`,
                hasUnread: false,
                requestssummary,
            });
        }
    }

    /**
     * Метод для получения проектов пользователя
     *
     * @returns {Promise} promise request
     */
    getData() {
        this.loading = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        this.list = [];
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: projectsQuery,
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.projects
                && result.res.data.projects.data
            ) {
                result.res.data.projects.data.forEach((project) => {
                    this.addToList({
                        id: project.id,
                        name: project.name,
                        requestssummary: project.requestssummary,
                    });
                });
                eventEmitter.emit('project:list_loaded');
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    get options() {
        const { listWithStatuses: list } = this;
        const options = [];
        let totalQty = 0;
        if (list && list.length) {
            list.forEach((project) => {
                totalQty += project.qty || 0;
                const projectLabel = `${project.name}${project.qty > 0 ? ` (${project.qty})` : ''}`;
                options.push({
                    label: projectLabel,
                    value: project.id,
                });
            });
        }
        options.unshift({
            label: `Все${totalQty > 0 ? ` (${totalQty})` : ''}`,
            value: null,
        });
        return options;
    }

    get clearOptions() {
        const { listWithStatuses: list } = this;
        const options = [];
        if (list && list.length) {
            list.forEach((project) => {
                const projectLabel = `${project.name}${project.qty > 0 ? ` ${project.qty}` : ''}`;
                options.push({
                    label: projectLabel,
                    value: project.id,
                });
            });
        }
        return options;
    }

    /**
     * Метод возвращает опцию выбранного проекта
     *
     * @returns {object} опция активного проекта
     */
    get activeProjectOption() {
        const { activeProjectId } = this;
        let activeOption = null;
        if (this.options && this.options.length) {
            activeOption = this.options.find(
                (project) => Number(project.value) === Number(activeProjectId),
            );
        }
        return activeOption;
    }

    setActiveProjectId(newState) {
        this._activeProjectId = newState;
        eventEmitter.emit('project:update_active_id');
    }

    /**
     * Метод для полного сброса состояния модели
     */
    reset() {
        this.loading = false;
        this._activeProjectId = null;
        this.list = [];
    }
}

decorate(ProjectListStore, {
    loading: observable,
    _activeProjectId: observable,
    activeProjectId: computed,
    list: observable,
    getData: action,
    options: computed,
    setActiveProjectId: action,
});

export default ProjectListStore;
