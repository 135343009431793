import nextId from 'react-id-generator';
import Form from '../Form/FormModel';

class LoginForm extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [{
                name: 'email',
                label: 'E-mail',
                rules: 'required|email',
                id: nextId(),
            }, {
                name: 'password',
                label: 'Пароль',
                rules: 'required|string|min:6',
                id: nextId(),
            }],
        };
    }
}

export default LoginForm;
