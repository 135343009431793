import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import HeaderUnAuthorized from './HeaderUnAuthorized';
import HeaderAuthorized from './HeaderAuthorized';
import { AUTH_STATES } from '../../constants';

import styles from './Header.module.scss';

class Header extends React.Component {
    static propTypes = {
        isAuthorized: PropTypes.oneOf([
            0,
            1,
            2,
        ]).isRequired,
    };

    render() {
        const { isAuthorized } = this.props;
        const theme = isAuthorized === AUTH_STATES.authorized ? 'blue' : 'white';
        return (
            <Container>
                <Row>
                    <Col>
                        <header
                            className={`
                                ${styles.header}
                                ${styles[`header--${theme}`]}
                            `}
                        >
                            {
                                isAuthorized === AUTH_STATES.authorized
                                    ? <HeaderAuthorized />
                                    : (
                                        <HeaderUnAuthorized />
                                    )
                            }
                        </header>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Header;
