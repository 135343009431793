import React from 'react';
import Dropdown from 'react-dropdown';
import PropTypes from 'prop-types';
import { SelectArrow } from '../SvgIcons';

import 'react-dropdown/style.css';
import styles from './Select.module.scss';

class Select extends React.Component {
    static propTypes = {
        defaultOption: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                label: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ).isRequired,
        onSelect: PropTypes.func.isRequired,
        theme: PropTypes.oneOf(['default', 'rounded']),
        label: PropTypes.string,
        fullWidth: PropTypes.bool,
        arrowClosed: PropTypes.node,
        arrowOpen: PropTypes.node,
    };

    static defaultProps = {
        defaultOption: null,
        theme: 'default',
        label: null,
        fullWidth: true,
        arrowClosed: (<span><SelectArrow /></span>),
        arrowOpen: (<span><SelectArrow reverse /></span>),
    };

    render() {
        const {
            defaultOption,
            options,
            onSelect,
            theme,
            label,
            fullWidth,
            arrowClosed,
            arrowOpen,
        } = this.props;
        return (
            <div
                className={`
                    ${styles.container}
                    ${fullWidth === true ? styles['container--full-width'] : ''}
                `}
            >
                {
                    label
                        ? (
                            <div className={styles.label}>{label}</div>
                        )
                        : null
                }
                <Dropdown
                    options={options}
                    onChange={onSelect}
                    value={defaultOption}
                    placeholder="Выберите"
                    className={`
                        ${styles.root}
                        ${`${styles[`root--${theme}`]}`}
                    `}
                    controlClassName={`
                        ${styles.control}
                        ${`${styles[`control--${theme}`]}`}
                    `}
                    menuClassName={`
                        ${styles.menu}
                        ${`${styles[`menu--${theme}`]}`}
                    `}
                    placeholderClassName={`
                        ${styles.placeholder}
                        ${`${styles[`placeholder--${theme}`]}`}
                    `}
                    arrowClosed={arrowClosed}
                    arrowOpen={arrowOpen}
                />
            </div>
        );
    }
}

export default Select;
