import React from 'react';
import { Provider } from 'mobx-react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import 'moment/locale/ru';
import moment from 'moment';
import Store from './stores';
import LoginPage from './pages/LoginPage';
import UserStore from './stores/UserStore';
import RegistrationPage from './pages/RegistrationPage';
import RestorePass from './pages/RestorePass';
import LastEventsModel from './stores/LastEvents';
import ProjectListStore from './stores/ProjectListStore';
import MembersCompanyStore from './stores/MembersCompany';
import DialogStore from './stores/DialogStore';
import RequestListStore from './stores/RequestListStore';
import RequestStore from './stores/RequestStore';
import InvoiceListStore from './stores/InvoiceListStore';
import InvoiceStore from './stores/InvoiceStore';
import AppStore from './stores/AppStore';
import RequestChatStore from './stores/RequestStore/RequestChat';
import Dialog from './containers/Dialog/Dialog';
import { NewRequest } from './containers/NewRequest';
import PrivatePage from './pages/PrivatePage';
import StatusListStore from './stores/StatusListStore';

moment.locale('ru');
require('../src/utils/socket');

const store = new Store();
const userStore = new UserStore();
const statusListStore = new StatusListStore();
const lastEvents = new LastEventsModel();
const projectListStore = new ProjectListStore({
    statusListStore,
});
const membersStore = new MembersCompanyStore();
const dialogStore = new DialogStore();
const requestChatStore = new RequestChatStore();
const requestListStore = new RequestListStore({
    projectListStore,
    statusListStore,
});
const requestStore = new RequestStore({
    requestChatStore,
});
const invoiceListStore = new InvoiceListStore();
const invoiceStore = new InvoiceStore();
const appStore = new AppStore();

class App extends React.Component {
    render() {
        return (
            <Provider
                store={store}
                userStore={userStore}
                lastEvents={lastEvents}
                projectListStore={projectListStore}
                membersStore={membersStore}
                dialogStore={dialogStore}
                requestListStore={requestListStore}
                requestStore={requestStore}
                requestChatStore={requestChatStore}
                invoiceListStore={invoiceListStore}
                invoiceStore={invoiceStore}
                appStore={appStore}
                statusListStore={statusListStore}
            >
                <Router>
                    <Switch>
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/registration" component={RegistrationPage} />
                        <Route exact path="/restore-pass" component={RestorePass} />
                        <Route path="/" component={PrivatePage} />
                    </Switch>
                    <Dialog
                        name="new_request"
                        size="lg"
                        footer={null}
                        header="Новая заявка"
                    >
                        <NewRequest />
                    </Dialog>
                </Router>
            </Provider>
        );
    }
}

export default App;
