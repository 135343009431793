import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import UserStore from '../../stores/UserStore';
import { PageWrapper, OnlyUnAuthWrapper } from '../../containers/PageWrapper';
import { LoginFormWrapper } from '../../components/Login';
import { withTitle } from '../../components/TitleComponent';

import styles from './LoginPage.module.scss';

const decorator = inject(
    'userStore',
    'statusListStore',
);

class LoginPage extends React.Component {
    static propTypes = {
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    handleSubmit = (form) => {
        const { email, password } = form.values();
        const { userStore, history } = this.props;
        return userStore
            .authorize(email, password)
            .then(() => {
                history.push('/startup');
            });
    }

    render() {
        return (
            <OnlyUnAuthWrapper>
                <PageWrapper>
                    <Container>
                        <Row>
                            <Col md={3} lg={4} />
                            <Col md={6} lg={4}>
                                <div
                                    className={styles['login-page']}
                                >
                                    <div
                                        className={styles['login-page__inner']}
                                    >
                                        <h2
                                            className={styles['login-page__title']}
                                        >
                                            Вход
                                        </h2>
                                        <LoginFormWrapper
                                            handleSuccess={this.handleSubmit}
                                        />
                                    </div>
                                    <div
                                        className={styles['login-page__bottom']}
                                    >
                                        <div>Нет аккаунта?</div>
                                        <Link
                                            to="/registration"
                                            className={styles['login-page__bottom-link']}
                                        >
                                            Зарегистрируйтесь
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </PageWrapper>
            </OnlyUnAuthWrapper>
        );
    }
}

export default decorator(
    withRouter(
        withTitle({
            component: observer(LoginPage),
            title: 'Страница авторизации',
        }),
    ),
);
