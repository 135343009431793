import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RequestStore from '../../stores/RequestStore';
import DialogStore from '../../stores/DialogStore';
import {
    RequestTop,
    RequestDescription,
} from '../../components/Request';
import PageLoader from '../../components/PageLoader';
import { RequestChat } from '../RequestChat';
import RequestAdditional from './RequestAdditional';
import EmptyState from '../../components/EmptyState';

import styles from './Request.module.scss';

class Request extends React.Component {
    static propTypes = {
        requestStore: PropTypes.instanceOf(RequestStore).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }).isRequired,
        dialogStore: PropTypes.instanceOf(DialogStore).isRequired,
    };

    handleEditClick = () => {
        const { dialogStore } = this.props;
        dialogStore.show('edit_request');
    }

    render() {
        const { requestStore, requestStore: { loading } } = this.props;
        if (loading === true) return (<PageLoader />);

        const {
            project,
            title,
            sum,
            workingHours,
            description,
            isNullState,
            status,
        } = requestStore;

        return (
            <>
                {
                    isNullState === false
                        ? (
                            <div
                                className={styles.container}
                            >
                                <h2 className={styles.project}>{project}</h2>
                                <RequestTop
                                    sum={sum}
                                    workingHours={workingHours}
                                    title={title}
                                    status={status}
                                    handleEditClick={this.handleEditClick}
                                />
                                <RequestDescription
                                    description={description}
                                />
                                <div className={styles.basic}>
                                    <RequestChat />
                                    <RequestAdditional />
                                </div>
                            </div>
                        )
                        : (
                            <EmptyState
                                text="Заявка с таким id не существует"
                            />
                        )
                }
            </>
        );
    }
}

export default inject(
    'requestStore',
    'dialogStore',
    'statusListStore',
)(
    withRouter(observer(Request)),
);
