/* global document */
import { extendObservable, action, decorate } from 'mobx';
import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import { plugins } from '../../utils/validatorjs';

class FormModel extends Form {
    constructor(data) {
        const { fields, hooks } = data || {};
        super({ fields });
        extendObservable(this, { loading: false });
        Object.keys(hooks).forEach((hook) => {
            this.addHook(hook, hooks[hook]);
        });
    }

    // eslint-disable-next-line class-methods-use-this
    plugins() {
        return { dvr: dvr(plugins.dvr) };
    }

    hooks() {
        const $this = this;
        return {
            onSubmit: (form) => {
                $this.setLoading(true);
                $this.fireHook('onSubmitHook', form);
                // Trigger hide mobile keyboard
                document.activeElement.blur();
            },
            onSuccess: (form) => {
                const promise = $this.fireHook('onSuccessHook', form);
                if (promise) {
                    promise
                        .catch(() => {})
                        .finally(() => {
                            $this.setLoading(false);
                        });
                }
            },
            onError: (form) => {
                $this.setLoading(false);
                $this.fireHook('onErrorHook', form);
            },
        };
    }

    addHook(hook, fn) {
        this[`${hook}Hook`] = fn;
    }

    setLoading(status) {
        this.loading = status;
    }

    fireHook(hook, form) {
        const fire = this[hook];
        if (fire && typeof fire === 'function') {
            return fire(form);
        }
        return null;
    }
}

decorate(FormModel, {
    addHook: action,
    setLoading: action,
});

export default FormModel;
