import React from 'react';
import Loader from '../../components/Loader';

import styles from './RequestChat.module.scss';

const RequestChatLoader = () => (
    <div
        className={styles.chat__loader}
    >
        <div
            className={styles['chat__loader-inner']}
        >
            <Loader
                color="#457eca"
                spaceSize={1}
                widthLine={3}
                heightLine={13}
            />
            <div
                className={styles['chat__loader-text']}
            >
                Соединение с socket-сервером...
            </div>
        </div>
    </div>
);

export default RequestChatLoader;
