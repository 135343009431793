import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import AddNewMember from './AddNewMember';
import AddNewMemberForm from '../../stores/AddNewMemberForm';
import MembersCompanyStore from '../../stores/MembersCompany';
import DialogStore from '../../stores/DialogStore';

import styles from './AddNewMember.module.scss';

class AddNewMemberWrapper extends React.Component {
    static propTypes = {
        membersStore: PropTypes.instanceOf(MembersCompanyStore).isRequired,
        dialogStore: PropTypes.instanceOf(DialogStore).isRequired,
    };

    handleSuccess = (form) => {
        const { membersStore, dialogStore } = this.props;
        const values = form.values();
        return membersStore
            .addUser(values.email)
            .then(() => {
                form.clear();
                dialogStore.hide();
            });
    }

    render() {
        const newMemberForm = new AddNewMemberForm({
            hooks: {
                onSuccess: this.handleSuccess,
            },
        });
        return (
            <div
                className={styles.container}
            >
                <div
                    className={styles.text}
                >
                    Сотруднику придет оповещение на e-mail
                    <br />
                    и тогда ему будет доступна вся информация
                    <br />
                    по проектам, как только он войдет в систему
                </div>
                <AddNewMember
                    form={newMemberForm}
                />
            </div>
        );
    }
}

export default inject(
    'membersStore',
    'dialogStore',
)(AddNewMemberWrapper);
