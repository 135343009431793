import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { PageWrapper } from '../../containers/PageWrapper';
import InvoiceStore from '../../stores/InvoiceStore';
import Invoice from '../../components/Invoice';
import { TitleComponent } from '../../components/TitleComponent';

class InvoicePage extends React.Component {
    static propTypes = {
        invoiceStore: PropTypes.instanceOf(InvoiceStore).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { location, invoiceStore } = this.props;
        const parsed = queryString.parse(location && location.search);
        const { id } = parsed;
        invoiceStore.getData(id);
    }

    componentWillUnmount() {
        const { invoiceStore } = this.props;
        invoiceStore.reset();
    }

    render() {
        const { invoiceStore } = this.props;
        return (
            <PageWrapper>
                <TitleComponent title={`Страница заявки #${invoiceStore.docnumber}`} />
                <Container>
                    <Row>
                        <Col md={1} />
                        <Col md={10}>
                            <Invoice
                                {...invoiceStore}
                                project={invoiceStore.project}
                                sumFormatted={invoiceStore.sumFormatted}
                                leftSum={invoiceStore.leftSum}
                                duedate={invoiceStore.duedate}
                                docdate={invoiceStore.docdate}
                                docnumber={invoiceStore.docnumber}
                            />
                        </Col>
                    </Row>
                </Container>
            </PageWrapper>
        );
    }
}

export default inject('invoiceStore')(
    observer(InvoicePage),
);
