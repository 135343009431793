import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

class FormField extends Component {
    static propTypes = {
        autoComplete: PropTypes.oneOf(['on', 'off']),
        field: PropTypes.shape({
            onChange: PropTypes.func.isRequired,
            validate: PropTypes.func.isRequired,
            id: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            placeholder: PropTypes.string,
            isValid: PropTypes.bool,
            label: PropTypes.string,
            error: PropTypes.string,
        }).isRequired,
        children: PropTypes.element.isRequired,
        validateOnBlur: PropTypes.bool,
        validateOnChange: PropTypes.bool,
        onClick: PropTypes.func,
        label: PropTypes.string,
        error: PropTypes.string,
    };

    static defaultProps = {
        autoComplete: 'off',
        validateOnBlur: false,
        validateOnChange: false,
        onClick: null,
        label: null,
        error: null,
    };

    render() {
        const {
            autoComplete,
            field,
            children,
            validateOnBlur,
            validateOnChange,
            onClick,
            label,
            error,
        } = this.props;

        const handleBlur = children.props.onBlur && typeof children.props.onBlur === 'function'
            ? children.props.onBlur
            : () => {};

        const handleChange = (e) => {
            field.onChange(e);
            if (children.props.onChange) children.props.onChange(e);
        };

        const handleClick = () => {
            if (onClick) onClick();
            if (children.props.onClick && typeof children.props.onClick === 'function') {
                children.props.onClick();
            }
        };
        const onBlur = validateOnBlur
            ? (e) => { field.validate(); handleBlur(e); }
            : handleBlur;

        const onChange = validateOnChange
            ? (e) => { handleChange(e); field.validate(); }
            : handleChange;

        return React.cloneElement(children, {
            autoComplete,
            onBlur,
            onChange,
            onClick: handleClick,
            id: field.id,
            value: field.value,
            placeholder: field.placeholder,
            isValid: field.isValid,
            label: label || field.label,
            error: error || field.error,
        });
    }
}
export default observer(FormField);
