/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import EmptyState from '../EmptyState';
import RequestItem from './RequestItem';

const Container = ({
    list,
    projectVisibility,
    newRequestId,
    pendingOrderRequest,
}) => {
    let pendingId = null;
    if (pendingOrderRequest && pendingOrderRequest.id) {
        pendingId = pendingOrderRequest.id;
    }
    return (
        <>
            {
                list && list.length
                    ? list.map((item, index) => (
                        <RequestItem
                            {...item}
                            price={item.price}
                            workingHours={item.workingHours}
                            projectVisibility={projectVisibility}
                            lastMileStone={item.lastMileStone}
                            colorItem={item.colorItem}
                            isNew={
                                Number(newRequestId)
                                === Number(item.id)
                            }
                            isPending={pendingId === item.id}
                            index={index}
                            key={`request_item-${item.id}`}
                            pendingOrderRequest={pendingOrderRequest}
                        />
                    ))
                    : <EmptyState text="В данный момент заявок в таком статусе нет" />
            }
        </>
    );
};

Container.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({}),
    ).isRequired,
    projectVisibility: PropTypes.bool.isRequired,
    newRequestId: PropTypes.number,
    onDragEnd: PropTypes.func.isRequired,
    pendingOrderRequest: PropTypes.shape({
        id: PropTypes.string,
    }),
};

Container.defaultProps = {
    newRequestId: null,
    pendingOrderRequest: null,
};

export default Container;
