import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import UserStore from '../../stores/UserStore';
import { AUTH_STATES } from '../../constants';

const decorator = inject('userStore');

/**
 * Обертка компонента для переадресации на страницу
 * Стартового экрана, если пользователь авторизован
 * и находится на странице не авторизованного
 * пользователя
 */
class OnlyUnAuthWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        userStore: PropTypes.instanceOf(UserStore).isRequired,
    };

    render() {
        const { children, userStore } = this.props;
        const { isAuthorized } = userStore;
        if (isAuthorized === AUTH_STATES.authorized) {
            return <Redirect to="/startup" />;
        }
        return (children);
    }
}

export default decorator(observer(OnlyUnAuthWrapper));
