import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import { inject } from 'mobx-react';
import queryString from 'query-string';
import {
    Tab,
    Tabs,
    TabList,
    TabPanel,
} from 'react-tabs';
import { PageWrapper, OnlyUnAuthWrapper } from '../../containers/PageWrapper';
import Progress from '../../components/Progress';
import {
    FirstStepWrapper,
    SecondStepWrapper,
    ThirdStepWrapper,
} from '../../components/RestorePass';
import { withTitle } from '../../components/TitleComponent';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import {
    changePasswordStep1,
    changePasswordStep2,
    changePasswordStep3,
} from './queries';
import UserStore from '../../stores/UserStore';
import eventEmitter from '../../utils/eventEmitter';
import SecondStepForm from '../../stores/RestorePassForm/SecondStepForm';

import 'react-tabs/style/react-tabs.css';
import styles from './RestorePassPage.module.scss';

const decorator = inject('userStore');

class RestorePassPage extends React.Component {
    secondForm = new SecondStepForm({
        hooks: {
            onSuccess: (form) => this.handleSubmitSecondForm(form),
        },
    });

    static propTypes = {
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor() {
        super();
        this.state = {
            tabIndex: 0,
            firstForm: {
                loading: false,
            },
            secondForm: {
                loading: false,
            },
            passForm: {
                loading: false,
            },
        };
    }

    componentDidMount() {
        const { location, history } = this.props;
        const parsed = queryString.parse(location && location.search);
        const { step, key } = parsed;
        if (step) {
            this.setState({
                tabIndex: Number(step) - 1,
            });
        }
        // Очистим переданные параметры
        history.push('/restore-pass');
        // Проверяем, что параметр key не пустой
        if (key && key === '') {
            eventEmitter.emit('modal:error', { message: 'Передана некорректная ссылка' });
            return;
        }
        if (key) {
            this.secondForm.$('code').set(key || '');
            this.setState((prevState) => ({
                secondForm: {
                    ...prevState.secondForm,
                    code: key,
                },
            }));
            this.secondForm.submit();
        }
    }

    handleSubmitFirstForm = (form) => {
        this.setState({
            firstForm: {
                ...form.values(),
                loading: true,
            },
        });
        const { firstForm } = this.state;
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            data: {
                query: changePasswordStep1,
                variables: {
                    email: firstForm.email,
                },
            },
        }).then((result) => {
            form.clear();
            console.log(result.res.data);
            this.setState({
                tabIndex: 1,
            });
        }).finally(() => {
            this.setState((prevState) => ({
                firstForm: {
                    ...prevState.firstForm,
                    loading: false,
                },
            }));
        });
    }

    handleSubmitSecondForm = (form) => {
        this.setState({
            secondForm: {
                ...form.values(),
                loading: true,
            },
        });
        const { secondForm } = this.state;
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            data: {
                query: changePasswordStep2,
                variables: {
                    code: secondForm.code,
                },
            },
        }).then(() => {
            form.clear();
            this.setState({
                tabIndex: 2,
            });
        }).finally(() => {
            this.setState((prevState) => ({
                secondForm: {
                    ...prevState.secondForm,
                    loading: false,
                },
            }));
        });
    }

    handleSubmit = (form) => {
        this.setState({
            passForm: {
                ...form.values(),
                loading: true,
            },
        });
        const { firstForm, secondForm, passForm } = this.state;
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            data: {
                query: changePasswordStep3,
                variables: {
                    code: secondForm.code,
                    password: passForm.password,
                },
            },
        }).then((result) => {
            form.clear();
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.ChangePasswordStep3
                && result.res.data.ChangePasswordStep3.access_token
                && result.res.data.ChangePasswordStep3.expires_in
            ) {
                const { userStore, history } = this.props;
                userStore.setAuthorizedState({
                    email: firstForm.email,
                    accessToken: result.res.data.ChangePasswordStep3.access_token,
                    expiresIn: result.res.data.ChangePasswordStep3.expires_in,
                });
                form.clear();
                this.resetState();
                history.push('/startup');
            }
        }).finally(() => {
            this.setState((prevState) => ({
                passForm: {
                    ...prevState.passForm,
                    loading: false,
                },
            }));
        });
    }

    resetState = () => {
        this.setState({
            tabIndex: 0,
            firstForm: {
                loading: false,
            },
            secondForm: {
                loading: false,
            },
            passForm: {
                loading: false,
            },
        });
    }

    render() {
        const {
            tabIndex,
            firstForm,
            secondForm,
            passForm,
        } = this.state;
        return (
            <OnlyUnAuthWrapper>
                <PageWrapper>
                    <Container>
                        <Row>
                            <Col md={3} lg={4} />
                            <Col md={6} lg={4}>
                                <Tabs
                                    selectedIndex={tabIndex}
                                    onSelect={(index) => this.setState({ tabIndex: index })}
                                >
                                    <div
                                        className={styles['restore-pass-page']}
                                    >
                                        <TabList className={styles.tab__list}>
                                            <Tab />
                                            <Tab />
                                            <Tab />
                                        </TabList>
                                        <div className={styles.progress__container}>
                                            <Progress
                                                count={3}
                                                current={tabIndex + 1}
                                            />
                                        </div>
                                        <div
                                            className={styles['restore-pass-page__inner']}
                                        >
                                            <TabPanel>
                                                <FirstStepWrapper
                                                    handleSuccess={this.handleSubmitFirstForm}
                                                    loading={firstForm.loading}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <SecondStepWrapper
                                                    form={this.secondForm}
                                                    loading={secondForm.loading}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <ThirdStepWrapper
                                                    handleSuccess={this.handleSubmit}
                                                    loading={passForm.loading}
                                                />
                                            </TabPanel>
                                        </div>
                                    </div>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </PageWrapper>
            </OnlyUnAuthWrapper>
        );
    }
}

export default decorator(
    withRouter(
        withTitle({
            component: RestorePassPage,
            title: 'Забыли пароль?',
        }),
    ),
);
