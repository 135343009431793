import { decorate, observable, action } from 'mobx';
import { GRAPHQL_HOST } from '../../constants';
import request from '../../utils/request';
import { getItem } from '../../utils/localStorage';
import { invoicesQuery } from './queries';
import InvoiceItem from './InvoiceItem';
import PaginationModel from '../Pagination';

const DEFAULT_COUNT_PER_PAGE = 20;

class InvoiceListStore {
    constructor() {
        this.pagination = new PaginationModel({
            activePage: 1,
            handleChange: this.handlePaginationChange,
            itemsCountPerPage: DEFAULT_COUNT_PER_PAGE,
            totalItemsCount: this.list.length,
            pageRangeDisplayed: 7,
        });
    }

    handlePaginationChange(page) {
        this.pagination.update({
            activePage: page,
        });
        this.getData();
    }

    init() {
        this.getData();
    }

    loading = false;

    list = [];

    setData(newState) {
        const { data, paginatorInfo } = newState;
        this.pagination.update({
            totalItemsCount: DEFAULT_COUNT_PER_PAGE * paginatorInfo.lastPage,
            itemsCountPerPage: paginatorInfo.perPage,
        });
        if (data && data.length) {
            this.list = [];
            const result = [];
            data.forEach((invoiceItem) => {
                result.push(new InvoiceItem(invoiceItem));
            });
            this.list = result;
        }
    }

    /**
     * Метод для получения счетов
     *
     * @returns {Promise} request promise
     */
    getData() {
        const { activePage } = this.pagination;
        this.loading = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: invoicesQuery,
                variables: {
                    page: activePage,
                },
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.invoices
            ) {
                this.setData(result.res.data.invoices);
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    reset() {
        this.loading = false;
        this.list = [];
    }
}

decorate(InvoiceListStore, {
    loading: observable,
    list: observable,
    setData: action,
    getData: action,
});

export default InvoiceListStore;
