/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const TitleComponent = ({ title }) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

const withTitle = ({ component: Component, title }) => (
    class Title extends Component {
        render() {
            return (
                <React.Fragment>
                    <TitleComponent title={title} />
                    <Component {...this.props} />
                </React.Fragment>
            );
        }
    }
);

TitleComponent.propTypes = {
    title: PropTypes.string,
};

TitleComponent.defaultProps = {
    title: 'WhiteBox',
};

export default TitleComponent;

export { TitleComponent, withTitle };
