const requestReorder = `
    mutation myRequestReorder(
        $after: Int!
        $id: Int!
    ) {
        RequestReorder(
            after: $after
            id: $id
        )
    }
`;

export default requestReorder;

export {
    requestReorder,
};
