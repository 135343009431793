import React from 'react';
import {
    Tab,
    Tabs,
    TabList,
    TabPanel,
} from 'react-tabs';
import RequestFiles from './RequestFiles';
import RequestDeadlines from './RequestDeadlines';
import RequestChecklists from './RequestChecklists';

import 'react-tabs/style/react-tabs.css';
import styles from './RequestAdditional.module.scss';

class RequestAdditional extends React.Component {
    constructor() {
        super();
        this.state = { tabIndex: 0 };
    }

    render() {
        const { tabIndex } = this.state;
        return (
            <div
                className={styles.additional}
            >
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => this.setState({ tabIndex: index })}
                    selectedTabClassName={styles['tab--selected']}
                    className={styles.tabs}
                >
                    <div>
                        <TabList className={styles.tab__list}>
                            <Tab className={styles.tab}>Файлы</Tab>
                            <Tab className={styles.tab}>Дедлайны</Tab>
                            <Tab className={styles.tab}>Чек-лист</Tab>
                        </TabList>
                        <div>
                            <TabPanel className={styles.tab__panel}>
                                <RequestFiles />
                            </TabPanel>
                            <TabPanel className={styles.tab__panel}>
                                <RequestDeadlines />
                            </TabPanel>
                            <TabPanel className={styles.tab__panel}>
                                <RequestChecklists />
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        );
    }
}

export default RequestAdditional;
