import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import RequestStore from '../../stores/RequestStore';
import { EMPTY_DATA_STRING } from '../../constants';
import {
    CheckMarkIcon, CircleIcon,
} from '../../components/SvgIcons';

import styles from './RequestChecklists.module.scss';
import stylesBasic from './Request.module.scss';

class RequestChecklists extends React.Component {
    static propTypes = {
        requestStore: PropTypes.instanceOf(RequestStore).isRequired,
    };

    renderChecklistBlock = (data) => (
        data && data.childs
            ? (
                <div className={styles.checklist__section}>
                    <div className={styles['checklist__section-title']}>
                        <span>
                            {data.caption || EMPTY_DATA_STRING}
                        </span>
                    </div>
                    {
                        data.childs.map((item) => (
                            <div
                                key={nextId('checklist__item')}
                                className={`
                                    ${styles.checklist__item}
                                    ${item.checked === true ? styles['checklist__item--complete'] : ''}
                                `}
                            >
                                <div
                                    className={styles['checklist__item-icon']}
                                >
                                    {
                                        item.checked === true
                                            ? (
                                                <CheckMarkIcon />
                                            )
                                            : <CircleIcon />
                                    }
                                </div>
                                <span className={styles['checklist__item-text']}>
                                    {item.caption}
                                </span>
                            </div>
                        ))
                    }
                </div>
            )
            : null
    );

    render() {
        const { requestStore } = this.props;
        const { checkLists } = requestStore;
        return (
            <>
                {
                    checkLists && checkLists.length
                        ? (
                            <div
                                className={styles.checklists}
                            >
                                {
                                    checkLists.map((item) => (
                                        <div key={nextId('request_checklist_section')}>
                                            {this.renderChecklistBlock(item)}
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        : (
                            <div className={stylesBasic.empty}>
                                <div className={stylesBasic.empty__inner}>
                                    Чек-лист пока не создан
                                </div>
                            </div>
                        )
                }
            </>
        );
    }
}

export default inject(
    'requestStore',
)(
    observer(RequestChecklists),
);
