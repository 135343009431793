import React from 'react';
import Dropdown from 'react-dropdown';
import PropTypes from 'prop-types';
import { SelectArrow } from '../SvgIcons';

import 'react-dropdown/style.css';
import styles from './Select.module.scss';

class FormSelect extends React.Component {
    static propTypes = {
        defaultOption: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                label: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ).isRequired,
        onSelect: PropTypes.func.isRequired,
        theme: PropTypes.oneOf(['default', 'rounded']),
        label: PropTypes.string,
        fullWidth: PropTypes.bool,
        arrowClosed: PropTypes.node,
        arrowOpen: PropTypes.node,
        error: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        defaultOption: null,
        theme: 'default',
        label: null,
        fullWidth: true,
        arrowClosed: (<span><SelectArrow /></span>),
        arrowOpen: (<span><SelectArrow reverse /></span>),
        error: null,
        name: '',
        value: '',
        onChange: () => {},
        placeholder: 'Выберите',
    };

    componentDidMount() {
        const {
            defaultOption,
            onChange,
        } = this.props;
        const defaultValue = defaultOption && defaultOption.value
            ? defaultOption.value
            : '';
        if (onChange) onChange(defaultValue);
    }

    handleSelect = (option) => {
        const {
            onSelect,
            onChange,
        } = this.props;
        if (onSelect) onSelect(option);
        if (onChange) onChange(option.value);
    }

    render() {
        const {
            defaultOption,
            options,
            theme,
            label,
            fullWidth,
            arrowClosed,
            arrowOpen,
            error,
            name,
            value,
            onChange,
            placeholder,
        } = this.props;
        return (
            <div
                className={`
                    ${styles.container}
                    ${fullWidth === true ? styles['container--full-width'] : ''}
                `}
            >
                <input
                    type="hidden"
                    className={styles.input}
                    name={name}
                    value={value}
                    onChange={onChange}
                />
                {
                    label
                        ? (
                            <div className={styles.label}>{label}</div>
                        )
                        : null
                }
                <Dropdown
                    options={options}
                    onChange={this.handleSelect}
                    value={defaultOption}
                    placeholder={placeholder}
                    className={`
                        ${styles.root}
                        ${`${styles[`root--${theme}`]}`}
                    `}
                    controlClassName={`
                        ${styles.control}
                        ${`${styles[`control--${theme}`]}`}
                    `}
                    menuClassName={`
                        ${styles.menu}
                        ${`${styles[`menu--${theme}`]}`}
                    `}
                    placeholderClassName={`
                        ${styles.placeholder}
                        ${`${styles[`placeholder--${theme}`]}`}
                    `}
                    arrowClosed={arrowClosed}
                    arrowOpen={arrowOpen}
                />
                {
                    error && error !== ''
                        ? (
                            <div className={styles.select__error}>{error}</div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default FormSelect;
