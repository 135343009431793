import React from 'react';
import PropTypes from 'prop-types';
import FirstStep from './FirstStep';
import FirstStepForm from '../../stores/RestorePassForm/FirstStepForm';

class FirstStepWrapper extends React.Component {
    static propTypes = {
        handleSuccess: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const { handleSuccess, loading } = this.props;
        const firstStepForm = new FirstStepForm({
            hooks: {
                onSuccess: (form) => handleSuccess(form),
            },
        });
        return <FirstStep form={firstStepForm} loading={loading} />;
    }
}

export default FirstStepWrapper;
