/**
 * Метод добавляет пробелы в сумму для разделения
 * по порядкам
 *
 * @param {string|number} sum сумма для преобразования
 * @returns {string} сумма с пробелами
 */
const sumFormatted = (sum) => {
    if (!sum) return 0;
    return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export default sumFormatted;
