import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import FormField from '../../components/FormField/FormField';
import Input from '../../components/Input';
import Button from '../../components/Button';
import FormSelect from '../../components/Select/FormSelect';
import NewRequestDropZone from './NewRequestDropzone';

import styles from './NewRequest.module.scss';

class NewRequestForm extends React.Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func.isRequired,
            $: PropTypes.func.isRequired,
        }).isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({}),
        ).isRequired,
        documents: PropTypes.arrayOf(
            PropTypes.shape({}),
        ).isRequired,
        handleProjectSelect: PropTypes.func.isRequired,
        defaultOption: PropTypes.shape({}),
        handleDrop: PropTypes.func.isRequired,
        handleRemoveFile: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        defaultOption: null,
    };

    render() {
        const {
            form,
            options,
            handleProjectSelect,
            defaultOption,
            documents,
            handleDrop,
            handleRemoveFile,
            loading,
        } = this.props;
        return (
            <div
                className={styles.container}
            >
                <div
                    className={styles.text}
                >
                    Заявка сразу отобразится в списке задач, затем созвонимся и
                    <br />
                    согласуем ее, оценим время/стоимость выполнения
                </div>
                <form onSubmit={form.onSubmit} autoComplete="off" className={styles.form}>
                    <FormField field={form.$('name')} validateOnBlur>
                        <Input
                            type="text"
                            id="request_edit_name"
                            withIcon={false}
                        />
                    </FormField>
                    <FormField field={form.$('description')} validateOnBlur>
                        <Input
                            type="text"
                            id="request_edit_description"
                            withIcon={false}
                            multiline
                        />
                    </FormField>
                    <Row>
                        <Col md={6}>
                            <NewRequestDropZone
                                onDrop={handleDrop}
                                onRemoveFile={handleRemoveFile}
                                list={documents}
                            />
                        </Col>
                        <Col md={6}>
                            <FormField field={form.$('project')}>
                                <FormSelect
                                    options={options}
                                    defaultOption={defaultOption}
                                    onSelect={handleProjectSelect}
                                    label="Проект"
                                    fullWidth
                                />
                            </FormField>
                            <div
                                className={styles.container__button}
                            >
                                <Button
                                    type="submit"
                                    theme="green"
                                    loading={loading}
                                >
                                    Создать заявку
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
}

export default NewRequestForm;
