import React from 'react';
import PropTypes from 'prop-types';
import SecondStep from './SecondStep';

class SecondStepWrapper extends React.Component {
    static propTypes = {
        form: PropTypes.shape({}).isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const { form, loading } = this.props;
        return <SecondStep form={form} loading={loading} />;
    }
}

export default SecondStepWrapper;
