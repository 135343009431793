import nextId from 'react-id-generator';
import Form from '../Form/FormModel';

class RequestCreateForm extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [
                {
                    name: 'name',
                    label: 'Название задачи',
                    rules: 'required',
                    id: nextId(),
                },
                {
                    name: 'description',
                    label: 'Описание задачи',
                    rules: 'required',
                    id: nextId(),
                },
                {
                    name: 'project',
                    label: 'Проект',
                    rules: 'required',
                    id: nextId(),
                },
            ],
        };
    }
}

export default RequestCreateForm;
