import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from 'react-tooltip-lite';
import { EMPTY_DATA_STRING } from '../../constants';

import styles from './RequestChat.module.scss';

const RequestChatMessage = ({
    message,
    user,
    datetime,
    name,
    isAuthor,
}) => (
    <div
        className={`
            ${styles['chat__message-item']}
            ${isAuthor === true ? styles['chat__message-item--author'] : ''}
        `}
        name={name}
    >
        <div className={styles['chat__message-item-inner']}>
            <div className={styles['chat__message-item-author']}>
                {`${isAuthor === true ? 'Вы' : user.firstname}`}
            </div>
            <div className={styles['chat__message-item-text']}>
                {message}
            </div>
            <div className={styles['chat__message-item-time']}>
                <Tooltip content={moment(datetime).format('YYYY MMMM DD hh:mm:ss')}>
                    {moment(datetime).format('h:mm')}
                </Tooltip>
            </div>
        </div>
    </div>
);

RequestChatMessage.propTypes = {
    message: PropTypes.string.isRequired,
    user: PropTypes.shape({
        firstname: PropTypes.string,
    }),
    datetime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isAuthor: PropTypes.bool.isRequired,
};

RequestChatMessage.defaultProps = {
    user: {
        firstname: EMPTY_DATA_STRING,
    },
};

export default RequestChatMessage;
