import React from 'react';
import PropTypes from 'prop-types';

const SelectArrow = ({
    width,
    height,
    color,
    reverse,
}) => (
    <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform={`${reverse ? 'rotate(180)' : ''}`}
    >
        <rect
            x="0.707031"
            y="3.79297"
            width="7"
            height="1"
            transform="rotate(45 0.707031 3.79297)"
            fill={color}
        />
        <rect
            width="7"
            height="1"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 9.19238 3.79297)"
            fill={color}
        />
    </svg>
);

SelectArrow.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    reverse: PropTypes.bool,
};

SelectArrow.defaultProps = {
    width: 11,
    height: 42,
    color: '#457eca',
    reverse: false,
};

export default SelectArrow;
