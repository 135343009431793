import React from 'react';
import PropTypes from 'prop-types';

const DocumentIcon = ({
    size,
    color,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.5 1H3C2.73478 1 2.48043 1.10536 2.29289 1.29289C2.10536 1.48043 2 1.73478 2 2V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H9C9.26522 11 9.51957 10.8946 9.70711 10.7071C9.89464 10.5196 10 10.2652 10 10V4.5L6.5 1Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.5 1V4.5H10"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

DocumentIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

DocumentIcon.defaultProps = {
    size: 12,
    color: '#457eca',
};

export default DocumentIcon;
