import nextId from 'react-id-generator';
import Form from '../Form/FormModel';

class RequestChatForm extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [
                {
                    name: 'message',
                    label: 'Сообщение',
                    rules: 'required',
                    placeholder: 'Написать сообщение...',
                    id: nextId(),
                },
            ],
        };
    }
}

export default RequestChatForm;
