/* global document */
import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppStore from '../../stores/AppStore';
import logo from '../../assets/images/logo--blue.png';
import { MenuIcon } from '../../components/SvgIcons';

import styles from './Menu.module.scss';

class Menu extends React.Component {
    static propTypes = {
        appStore: PropTypes.instanceOf(AppStore).isRequired,
        children: PropTypes.node.isRequired,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        const { appStore } = this.props;
        if (
            this.refToggler
            && !this.refToggler.contains(event.target)
            && this.refContainerInner
            && !this.refContainerInner.contains(event.target)
        ) {
            appStore.closeMenu();
        }
    }

    handleClick = () => {
        const { appStore } = this.props;
        appStore.toggleMenu();
    }

    render() {
        const { appStore, children } = this.props;
        const { menuIsOpen } = appStore;
        return (
            <div
                className={`
                    ${styles.menu}
                    ${menuIsOpen === true ? styles['menu--is-open'] : ''}
                `}
            >
                <div className={styles['menu-logo']}>
                    <img src={logo} alt="Logo" className={styles['menu-logo__icon']} />
                    <div className={styles['menu-logo__text']}>
                        <span>W</span>
                        <span>h</span>
                        <span>i</span>
                        <span>t</span>
                        <span>e</span>
                        <span>b</span>
                        <span>o</span>
                        <span>x</span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={this.handleClick}
                    ref={(ref) => { this.refToggler = ref; }}
                    className={styles.menu__button}
                >
                    <MenuIcon />
                </button>
                <div
                    className={styles.menu__container}
                    ref={(ref) => { this.refContainerInner = ref; }}
                >
                    {children}
                </div>
            </div>
        );
    }
}

export default inject(
    'appStore',
)(observer(Menu));
