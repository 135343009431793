import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import PageLoader from '../PageLoader';
// import InvoiceListSection from './InvoiceListSection';
import InvoiceItem from './InvoiceItem';
import EmptyState from '../EmptyState';
import Pagination from '../Pagination';
import PaginationModel from '../../stores/Pagination';

import styles from './InvoiceList.module.scss';

class InvoiceList extends React.Component {
    static propTypes = {
        list: PropTypes.arrayOf(
            PropTypes.shape({}),
        ).isRequired,
        loading: PropTypes.bool.isRequired,
        pagination: PropTypes.instanceOf(PaginationModel).isRequired,
        handlePaginationChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            list,
            loading,
            pagination,
            handlePaginationChange,
        } = this.props;
        if (loading === true) return (<PageLoader />);
        return (
            <div className={styles.container}>
                <div
                    className={styles.table}
                >
                    <div
                        className={styles.tr}
                    >
                        <div
                            className={styles.th}
                        >
                            Счет
                        </div>
                        <div
                            className={styles.th}
                        >
                            Статус
                        </div>
                        <div
                            className={styles.th}
                        >
                            К оплате
                        </div>
                    </div>
                    {
                        list && list.length
                            ? (
                                list.map((item) => (
                                    <InvoiceItem
                                        {...item}
                                        sumFormatted={item.sumFormatted}
                                        docnumber={item.docnumber}
                                        docdate={item.docdate}
                                        key={nextId('invoice_list_item')}
                                    />
                                    // <InvoiceListSection
                                    //     list={item.list}
                                    //     title={item.title}
                                    //     key={nextId('invoice_list_section')}
                                    // />
                                ))
                            )
                            : <EmptyState text="У вас ещё нет счетов для отображения" />
                    }
                    <Pagination
                        activePage={pagination.activePage}
                        lastPage={pagination.lastPage}
                        handlePageChange={(page) => {
                            handlePaginationChange(page);
                        }}
                        itemsCountPerPage={pagination.itemsCountPerPage}
                        totalItemsCount={pagination.totalItemsCount}
                        pageRangeDisplayed={pagination.pageRangeDisplayed}
                    />
                </div>
            </div>
        );
    }
}

export default InvoiceList;
