const projectsQuery = `
    query{
        projects {
            data {
                id
                name
                requestssummary {
                    requeststatus_id
                    count
                }
            }
        }
    }
`;

export default projectsQuery;
