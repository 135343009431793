import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import ProjectItem from './ProjectItem';
import ProjectListStore from '../../stores/ProjectListStore';
import PageLoader from '../../components/PageLoader';

import styles from './Projects.module.scss';

class Projects extends React.Component {
    static propTypes = {
        projectListStore: PropTypes.instanceOf(ProjectListStore).isRequired,
    };

    render() {
        const { projectListStore } = this.props;
        const { loading, listWithStatuses } = projectListStore;
        return (
            <div
                className={styles.container}
            >
                <h3>
                    Проекты
                </h3>
                {
                    loading === true
                        ? (<PageLoader minHeight={200} />)
                        : (
                            <ul className={styles.list}>
                                {
                                    listWithStatuses && listWithStatuses.length
                                        ? (
                                            listWithStatuses.map((project) => (
                                                <li
                                                    className={styles.item}
                                                    key={nextId('project_list_item')}
                                                >
                                                    <ProjectItem
                                                        link={project.link}
                                                        name={project.name}
                                                        count={project.qty}
                                                        hasUnread={project.hasUnread}
                                                    />
                                                </li>
                                            ))
                                        )
                                        : 'Проектов нет'
                                }
                            </ul>
                        )
                }
            </div>
        );
    }
}

export default inject(
    'projectListStore',
)(observer(Projects));
