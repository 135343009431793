import React from 'react';
import PropTypes from 'prop-types';

const RubCircleIcon = ({
    size,
    color,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.00035 0.00305176C3.58008 0.00305176 0.00341797 3.58037 0.00341797 7.99999C0.00341797 12.4203 3.58074 15.9969 8.00035 15.9969C12.4206 15.9969 15.9973 12.4196 15.9973 7.99999C15.9973 3.57968 12.42 0.00305176 8.00035 0.00305176ZM8.00035 15.0598C4.09807 15.0598 0.940559 11.9017 0.940559 7.99999C0.940559 4.09767 4.09863 0.940193 8.00035 0.940193C11.9026 0.940193 15.0602 4.09826 15.0602 7.99999C15.0602 11.9023 11.9021 15.0598 8.00035 15.0598Z"
            fill={color}
        />
        <path
            d="M9.40579 4.72005H6.59437V7.53147H5.65723V8.46861H6.59437V9.40575H5.65723V10.3429H6.59437V11.28H7.53151V10.3429H8.46865V9.40575H7.53151V8.46861H9.40579C10.4393 8.46861 11.2801 7.62781 11.2801 6.59433C11.2801 5.56085 10.4393 4.72005 9.40579 4.72005ZM9.40579 7.53147H7.53151V5.65719H9.40579C9.92253 5.65719 10.3429 6.07759 10.3429 6.59433C10.3429 7.11107 9.92253 7.53147 9.40579 7.53147Z"
            fill={color}
        />
    </svg>
);

RubCircleIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

RubCircleIcon.defaultProps = {
    size: 16,
    color: '#457eca',
};

export default RubCircleIcon;
