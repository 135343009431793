import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormField from '../FormField/FormField';
import Input from '../Input';
import Button from '../Button';

import styles from './Request.module.scss';

const RequestEdit = ({
    form,
    loading,
}) => (
    <div
        className={styles['edit-container']}
    >
        <div
            className={styles['edit-container__text']}
        >
            При значительном изменении описания задачи мы
            <br />
            созвонимся для утверждения
        </div>
        <form onSubmit={form.onSubmit} autoComplete="off">
            <FormField field={form.$('name')} validateOnBlur>
                <Input
                    type="text"
                    id="request_edit_name"
                    withIcon={false}
                />
            </FormField>
            <FormField field={form.$('description')} validateOnBlur>
                <Input
                    type="text"
                    id="request_edit_description"
                    withIcon={false}
                    multiline
                />
            </FormField>
            <div
                className={styles['edit-container__button']}
            >
                <Button
                    type="submit"
                    theme="green"
                    fullWidth={false}
                    loading={loading}
                >
                    Принять изменения
                </Button>
            </div>
        </form>
    </div>
);

RequestEdit.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func.isRequired,
        $: PropTypes.func.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default observer(RequestEdit);
