import {
    decorate,
    observable,
    action,
    computed,
} from 'mobx';
import { GRAPHQL_HOST, EMPTY_DATA_STRING } from '../../constants';
import request from '../../utils/request';
import { getItem } from '../../utils/localStorage';
import { invoiceQuery } from './queries';
import sumFormatted from '../../utils/units';

class InvoiceStore {
    /** Если true, то такой заявки не существует */
    isNullState = false;

    loading = false;

    _project = null;

    id = null;

    date = null;

    _duedate = null;

    _docdate = null;

    _docnumber = null;

    status = {};

    sum = null;

    paid = null;

    text = null;

    documents = [];

    link = null;

    extra = [];

    setData({
        project,
        id,
        createdate,
        invoicestatus,
        sum,
        paid,
        text,
        documents,
        link,
        extra,
        duedate,
        docdate,
        docnumber,
    }) {
        this.isNullState = false;
        this._project = project;
        this.id = id;
        this.date = createdate;
        this.status = invoicestatus;
        this.sum = sum;
        this.paid = paid;
        this.text = text;
        this.documents = documents;
        this.link = link;
        this.extra = extra;
        this._duedate = duedate;
        this._docdate = docdate;
        this._docnumber = docnumber;
    }

    /**
     * Метод для получения счёта
     * по id
     *
     * @param {number|string} id id заявки
     * @returns {Promise} promise request
     */
    getData(id) {
        this.loading = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: invoiceQuery(id),
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.invoice
            ) {
                this.setData(result.res.data.invoice);
            } else {
                this.isNullState = true;
            }
        }).catch(() => {
            this.isNullState = true;
        }).finally(() => {
            this.loading = false;
        });
    }

    get project() {
        return this._project && this._project.name
            ? this._project.name
            : EMPTY_DATA_STRING;
    }

    get sumFormatted() {
        const sum = sumFormatted(this.sum);
        return `${sum} ₽`;
    }

    get leftSum() {
        const { sum, paid } = this;
        if (
            (sum || sum === 0)
            && (paid || paid === 0)
        ) {
            return `${sumFormatted(Number(sum) - Number(paid))} ₽`;
        }
        return '0 ₽';
    }

    get duedate() {
        return this._duedate || EMPTY_DATA_STRING;
    }

    get docdate() {
        return this._docdate || EMPTY_DATA_STRING;
    }

    get docnumber() {
        return this._docnumber || EMPTY_DATA_STRING;
    }

    reset() {
        this.isNullState = false;
        this._project = null;
        this.id = null;
        this.date = null;
        this.status = {};
        this.sum = null;
        this.paid = null;
        this.text = null;
        this.documents = [];
        this.link = null;
        this.extra = [];
        this._duedate = null;
        this._docdate = null;
        this._docnumber = null;
    }
}

decorate(InvoiceStore, {
    isNullState: observable,
    loading: observable,
    list: observable,
    sum: observable,
    paid: observable,
    _docdate: observable,
    _docnumber: observable,
    _duedate: observable,
    _project: observable,
    setData: action,
    getData: action,
    project: computed,
    sumFormatted: computed,
    leftSum: computed,
    duedate: computed,
    docdate: computed,
    docnumber: computed,
});

export default InvoiceStore;
