import React from 'react';
import PropTypes from 'prop-types';

const ArrowDownIcon = ({
    width,
    height,
    color,
    reverse,
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform={`${reverse ? 'rotate(180)' : ''}`}
    >
        <path
            d="M0.580077 1.94013L0.382185 1.75676L0.580077 1.57338L1.39674 0.816625L1.56667 0.659168L1.73659 0.816625L4.25 3.14565L6.76341 0.816625L6.93333 0.659168L7.10326 0.816625L7.91992 1.57338L8.11781 1.75676L7.91992 1.94013L4.41992 5.18337L4.25 5.34083L4.08008 5.18337L0.580077 1.94013Z"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
        />
    </svg>
);

ArrowDownIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    reverse: PropTypes.bool,
};

ArrowDownIcon.defaultProps = {
    width: 10,
    height: 6,
    color: '#457eca',
    reverse: false,
};

export default ArrowDownIcon;
