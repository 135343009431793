import nextId from 'react-id-generator';
import Form from '../Form/FormModel';

class FirstStepForm extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [{
                name: 'email',
                label: 'Email',
                rules: 'required|string|email',
                value: '',
                id: nextId(),
            }],
        };
    }
}

export default FirstStepForm;
