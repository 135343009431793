import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import Tooltip from 'react-tooltip-lite';
import moment from 'moment';
import { Link } from 'react-router-dom';
import LastEventsModel from '../../stores/LastEvents';
import Pagination from '../../components/Pagination';
import PageLoader from '../../components/PageLoader';

import styles from './LastEvents.module.scss';

class LastEvents extends React.Component {
    static propTypes = {
        lastEvents: PropTypes.instanceOf(LastEventsModel).isRequired,
    };

    render() {
        const { lastEvents } = this.props;
        const {
            pagination,
            data,
            loading,
        } = lastEvents;

        return (
            <div
                className={styles.container}
            >
                <h3>
                    Последние события
                </h3>
                {
                    loading === true
                        ? (<PageLoader minHeight={200} />)
                        : (
                            <div className={styles.content}>
                                <div className="table table--full-width">
                                    <div className="tr">
                                        <td className="th" colSpan="5">
                                            Событие
                                        </td>
                                        <td className="th" colSpan="2">
                                            Время
                                        </td>
                                    </div>
                                    {
                                        data && data.length
                                            ? (
                                                data.map((item) => (
                                                    <Link
                                                        to={`/request?id=${item.url.replace('/', '')}`}
                                                        className="tr"
                                                        key={nextId('last_event_row_data')}
                                                    >
                                                        <td className={`td ${styles['event-cell']}`} colSpan={5}>
                                                            <Tooltip className={styles.tooltip} content={item.text}>
                                                                {item.text}
                                                            </Tooltip>
                                                        </td>
                                                        <td className={`td ${styles['time-cell']}`} colSpan={2}>
                                                            {moment(item.datetime).calendar(null, {
                                                                lastDay: '[Вчера в] HH:mm',
                                                                sameElse: 'DD.MM.YYYY [в] HH:mm',
                                                                lastWeek: 'DD.MM.YYYY [в] HH:mm',
                                                                nextWeek: 'DD.MM.YYYY [в] HH:mm',
                                                            })}
                                                        </td>
                                                    </Link>
                                                ))
                                            )
                                            : (
                                                <div className="tr">
                                                    <td className="td" colSpan={7}>
                                                        <div
                                                            className={styles.table__empty}
                                                        >
                                                            <div
                                                                className={styles['table__empty-inner']}
                                                            >
                                                                Скоро здесь начнут отображаться события
                                                                <br />
                                                                по всем вашим заявкам
                                                            </div>
                                                        </div>
                                                    </td>
                                                </div>
                                            )
                                    }
                                </div>
                                <Pagination
                                    activePage={pagination.activePage}
                                    lastPage={pagination.lastPage}
                                    handlePageChange={(page) => {
                                        lastEvents.handlePaginationChange(page);
                                    }}
                                    itemsCountPerPage={pagination.itemsCountPerPage}
                                    totalItemsCount={pagination.totalItemsCount}
                                    pageRangeDisplayed={pagination.pageRangeDisplayed}
                                />
                            </div>
                        )
                }
            </div>
        );
    }
}

export default inject(
    'lastEvents',
)(
    observer(LastEvents),
);
