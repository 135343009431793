import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Select from '../../components/Select/Select';
import StatusFilter from '../../components/StatusFilter/StatusFilter';
import RequestListStore from '../../stores/RequestListStore';
import ProjectListStore from '../../stores/ProjectListStore';
import { ArrowDownIcon } from '../../components/SvgIcons';
import StatusListStore from '../../stores/StatusListStore';

import styles from './Sidebar.module.scss';

class Sidebar extends React.Component {
    static propTypes = {
        requestListStore: PropTypes.instanceOf(RequestListStore).isRequired,
        projectListStore: PropTypes.instanceOf(ProjectListStore).isRequired,
        statusListStore: PropTypes.instanceOf(StatusListStore).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { location, projectListStore, requestListStore } = this.props;
        // Не исполнять код не на странице Заявок
        if (location && location.pathname !== '/requests') return;
        const parsed = queryString.parse(location && location.search);
        const { project, statuses } = parsed;
        let statusesArr = [];
        if (statuses) {
            statusesArr = statuses.split(',');
        }
        const statusesArrNumber = statusesArr.map((statusId) => Number(statusId));
        if (project && project !== '') {
            projectListStore.setActiveProjectId(project);
        }
        if (statuses && statuses !== '') {
            requestListStore.setActiveStatuses(statusesArrNumber);
        }
        requestListStore.getFullData();
    }

    /**
     * Метод получает список опций на основе проектов
     * пользователя
     *
     * @returns {Array} массив опций
     */
    get options() {
        const { projectListStore } = this.props;
        return projectListStore.options;
    }

    /**
     * Метод возвращает опцию выбранного проекта
     *
     * @returns {object} опция активного проекта
     */
    get activeProjectOption() {
        const { projectListStore } = this.props;
        const { activeProjectOption } = projectListStore;
        return activeProjectOption;
    }

    /**
     * Метод для получения строки запроса. На
     * основе выбранных значений фильтра и
     * проекта
     *
     * @returns {string} строка запроса
     */
    getActualQueryString = () => {
        const { projectListStore, requestListStore } = this.props;
        const { activeProjectId } = projectListStore;
        const { activeStatusesId } = requestListStore;
        return queryString.stringify({
            project: activeProjectId,
            statuses: activeStatusesId,
        }, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
        });
    }

    /**
     * Обработка изменения проекта
     *
     * @param {object} option выбранная опция
     */
    handleProjectSelect = (option) => {
        const {
            projectListStore,
            history,
            requestListStore,
            statusListStore,
        } = this.props;
        const { loading } = requestListStore;
        if (loading === true) return;
        projectListStore.setActiveProjectId(option.value);
        const string = this.getActualQueryString();
        history.push({
            pathname: '/requests',
            search: string,
        });
        requestListStore.getFullData();
        // Вероятно, в будущем, будем сбрасывать
        // выбранный статус, если есть (так как
        // сейчас все статусы одинаковые для всех
        // проектов, мы этого не делаем)
        statusListStore.getStatuses();
    }

    /**
     * Обработка изменения выбранного фильтра
     *
     * @param {string} id id выбранного фильтра
     * @param {Event} event событие клика на кнопке
     */
    handleFilterChange = (id, event) => {
        const { requestListStore, history } = this.props;
        const { loading, activeStatusesId } = requestListStore;
        if (loading === true) return;
        // Проверяем зажата ли кнопка Control
        if (event.ctrlKey === true) {
            const isSelected = activeStatusesId.indexOf(id) > -1;
            // Если статус уже выбран, то убираем его
            // из списка выбранных
            if (isSelected) {
                requestListStore.deleteActiveStatus(id);
            // Иначе добавляем его к списку
            } else {
                requestListStore.addActiveStatus(id);
            }
        // Под 0 пункт Все активные
        } else if (id === 0 || id === '0') {
            requestListStore.setActiveStatuses();
        } else {
            // Если условия выше не выполнены, то
            // выбирается только переданный id
            requestListStore.setActiveStatuses([id]);
        }
        const string = this.getActualQueryString();
        history.push({
            pathname: '/requests',
            search: string,
        });
        requestListStore.getFullData();
    }

    render() {
        const {
            handleProjectSelect,
            handleFilterChange,
            options,
            activeProjectOption,
        } = this;
        return (
            <div
                className={styles.container}
            >
                <Select
                    options={options}
                    defaultOption={activeProjectOption}
                    onSelect={handleProjectSelect}
                    theme="rounded"
                    arrowClosed={(<span><ArrowDownIcon /></span>)}
                    arrowOpen={(<span><ArrowDownIcon reverse /></span>)}
                />
                <StatusFilter
                    onFilterChange={handleFilterChange}
                />
            </div>
        );
    }
}

export default inject(
    'projectListStore',
    'requestListStore',
    'statusListStore',
)(withRouter(observer(Sidebar)));
