/* eslint-disable react/jsx-props-no-spreading */
/* global FormData */
import React from 'react';
import autosize from 'autosize';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import moment from 'moment';
import { Element, scroller } from 'react-scroll';
import RequestChatForm from '../../stores/RequestChatForm';
import RequestChatStore from '../../stores/RequestStore/RequestChat';
import RequestChatMessage from './RequestChatMessage';
import { getItem } from '../../utils/localStorage';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import RequestStore from '../../stores/RequestStore';
import { uploadFile } from './queries';
import eventEmitter from '../../utils/eventEmitter';
import Loader from '../../components/Loader';
import UserStore from '../../stores/UserStore';
import RequestChatTextarea from './RequestChatTextarea';

import styles from './RequestChat.module.scss';

class RequestChatContent extends React.Component {
    form = new RequestChatForm({
        hooks: {
            onSuccess: () => this.submitMessage(),
        },
    });

    static propTypes = {
        requestChatStore: PropTypes.instanceOf(RequestChatStore).isRequired,
        requestStore: PropTypes.instanceOf(RequestStore).isRequired,
        userStore: PropTypes.instanceOf(UserStore).isRequired,
    };

    constructor() {
        super();
        this.state = {
            firstLoad: true,
            fileUploading: false,
        };
    }

    componentDidMount() {
        autosize(this.textarea);
        eventEmitter.on('chat:new_message', this.scrollToNewMessage);
        eventEmitter.on('chat:message_loaded', this.scrollToLast);
        this.chatRef.addEventListener('scroll', this.handleScrollChat);
    }

    componentWillUnmount() {
        eventEmitter.off('chat:new_message', this.scrollToNewMessage);
        eventEmitter.off('chat:message_loaded', this.scrollToLast);
    }

    handleScrollChat = () => {
        const { requestChatStore } = this.props;
        const { _messages, loadingMessage } = requestChatStore;
        // позиция скролла меньше которого
        // будет срабатывать событие
        // Лучше использовать 1, т.к. при
        // использовании большего значения
        // этот метоед будет срабатывать повторно
        // и запрашивать доп. данные
        const OFFSET_TRIGGER = 1;
        // Минимальное кол-во сообщений в чате
        // для срабатывания события
        const MIN_QTY_MESSAGE = 10;
        const node = this.chatRef;
        const { scrollTop } = node;
        if (
            scrollTop < OFFSET_TRIGGER
            && _messages.length >= MIN_QTY_MESSAGE
            && !loadingMessage
        ) {
            requestChatStore.getData();
        }
    }

    handleUpload = (acceptedFiles) => {
        const { requestStore, requestChatStore } = this.props;
        const { requestId } = requestChatStore;
        const authToken = getItem('accessToken');
        if (!authToken) return;
        if (acceptedFiles && acceptedFiles[0]) {
            const firstFile = acceptedFiles[0];
            const o = {
                query: uploadFile,
                variables: {
                    file: null,
                    entity_type: 2,
                    entity_id: requestId,
                },
            };
            const map = {
                0: ['variables.file'],
            };
            const fd = new FormData();
            fd.append('operations', JSON.stringify(o));
            fd.append('map', JSON.stringify(map));
            fd.append(0, firstFile, firstFile.name);
            this.setState({ fileUploading: true });
            request({
                method: 'POST',
                url: GRAPHQL_HOST,
                authToken,
                data: fd,
            }).then((result) => {
                if (
                    result
                    && result.res
                    && result.res.data
                    && result.res.data.UploadFile
                ) {
                    requestStore.addFile({
                        filename: firstFile.path,
                        uid: result.res.data.UploadFile,
                    });
                }
            }).finally(() => {
                this.setState({ fileUploading: false });
            });
        }
    }

    keyPressed = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.submitMessage();
        }
    }

    submitMessage = () => {
        const { requestChatStore } = this.props;
        const { form } = this;
        const { message } = form.values();
        requestChatStore.sendMessage(message);
        form.clear();
        eventEmitter.emit('request_chat:cleared');
    }

    isNearBottom = () => {
        const node = this.chatRef;
        const bottomOffset = 120;
        const { scrollHeight, scrollTop, clientHeight } = node;
        return (scrollHeight - scrollTop - bottomOffset) <= clientHeight;
    }

    scrollToNewMessage = () => {
        if (
            this.isNearBottom()
        ) {
            this.setState({ firstLoad: false });
            scroller.scrollTo('bottomChat', {
                duration: 0,
                delay: 0,
                smooth: true,
                containerId: 'chatContainerId',
                offset: 0,
            });
        }
    }

    scrollToLast = () => {
        const { requestChatStore } = this.props;
        const { firstLoad } = this.state;
        const { lastTargetMessageId } = requestChatStore;
        if (
            this.isNearBottom()
            || firstLoad
        ) {
            this.setState({ firstLoad: false });
            scroller.scrollTo('bottomChat', {
                duration: 0,
                delay: 0,
                smooth: true,
                containerId: 'chatContainerId',
                offset: 0,
            });
        } else {
            scroller.scrollTo(`message--${lastTargetMessageId}`, {
                duration: 0,
                delay: 0,
                smooth: true,
                containerId: 'chatContainerId',
                offset: -10,
            });
        }
    }

    render() {
        const { requestChatStore, userStore } = this.props;
        const { form } = this;
        const { fileUploading } = this.state;
        const { messages, loadingMessage } = requestChatStore;
        const { userId } = userStore;
        const messageSections = Object.entries(messages);
        return (
            <div
                className={styles.chat__content}
            >
                <div
                    className={styles['chat__content-inner']}
                    // id нужен для корректной работы скролла
                    // через react-scroll
                    id="chatContainerId"
                    ref={(el) => { this.chatRef = el; }}
                >
                    {
                        loadingMessage === true
                            ? (
                                <div className={styles['chat__message-loader']}>
                                    <Loader
                                        color="#457eca"
                                        spaceSize={1}
                                        widthLine={3}
                                        heightLine={13}
                                    />
                                    <span>
                                        Загрузка сообщений...
                                    </span>
                                </div>
                            )
                            : null
                    }
                    {
                        messageSections && messageSections.length
                            ? (
                                messageSections.map((section) => (
                                    <div
                                        key={nextId('message_section')}
                                    >
                                        <div
                                            className={styles['chat__message-date-title']}
                                        >
                                            <span>
                                                {moment(section[0]).format('D MMMM YYYY')}
                                            </span>
                                        </div>
                                        {
                                            section[1]
                                            && section[1].list
                                            && section[1].list.length
                                                ? (
                                                    section[1].list.map((messageItem) => (
                                                        <RequestChatMessage
                                                            key={nextId('message_section_item')}
                                                            {...messageItem}
                                                            name={`message--${messageItem.id}`}
                                                            isAuthor={
                                                                messageItem.user
                                                                && messageItem.user.id
                                                                && userId
                                                                && Number(messageItem.user.id)
                                                                === Number(userId)
                                                            }
                                                        />
                                                    ))
                                                )
                                                : null
                                        }
                                    </div>
                                ))
                            )
                            : (
                                <div className={styles.chat__empty}>
                                    <div className={styles['chat__empty-inner']}>
                                        Для общения и обмена файлами по
                                        <br />
                                        заявке используйте этот чат
                                    </div>
                                </div>
                            )
                    }
                    <Element name="bottomChat" />
                </div>
                <RequestChatTextarea
                    form={form}
                    keyPressed={this.keyPressed}
                    handleUpload={this.handleUpload}
                    disabled={fileUploading}
                />
            </div>
        );
    }
}

export default inject(
    'requestChatStore',
    'requestStore',
    'userStore',
)(
    observer(RequestChatContent),
);
