import React from 'react';
import PropTypes from 'prop-types';

const SendIcon = ({
    width,
    height,
    color,
}) => (
    <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="M17.5853 9.99988H4.6217"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.5849 9.9996L1.67502 17.6599L4.62129 9.9996L1.67502 2.33927L17.5849 9.9996Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

SendIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

SendIcon.defaultProps = {
    width: 20,
    height: 20,
    color: 'currentColor',
};

export default SendIcon;
