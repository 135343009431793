import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import queryString from 'query-string';
import { Container, Row, Col } from 'react-grid-system';
import {
    Tab,
    Tabs,
    TabList,
    TabPanel,
} from 'react-tabs';
import { PageWrapper, OnlyUnAuthWrapper } from '../../containers/PageWrapper';
import Progress from '../../components/Progress';
import {
    FirstStepWrapper,
    SecondStepWrapper,
    ThirdStepWrapper,
} from '../../components/Registration';
import { GRAPHQL_HOST } from '../../constants';
import {
    registerUserStep1,
    registerUserStep2,
    registerUserStep3,
} from './queries';
import request from '../../utils/request';
import UserStore from '../../stores/UserStore';
import { withTitle } from '../../components/TitleComponent';
import SecondStepForm from '../../stores/RegistrationForm/SecondStepForm';
import eventEmitter from '../../utils/eventEmitter';

import 'react-tabs/style/react-tabs.css';
import styles from './RegistrationPage.module.scss';

const decorator = inject('userStore');

class RegistrationPage extends React.Component {
    secondStepForm = new SecondStepForm({
        hooks: {
            onSuccess: (form) => this.handleSubmitSecondForm(form),
        },
    });

    static propTypes = {
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor() {
        super();
        this.state = {
            tabIndex: 0,
            firstForm: {
                loading: false,
            },
            secondForm: {
                loading: false,
            },
            passForm: {
                loading: false,
            },
        };
    }

    componentDidMount() {
        const { location, history } = this.props;
        const parsed = queryString.parse(location && location.search);
        const { step, key, email } = parsed;
        if (step) {
            this.setState({
                tabIndex: Number(step) - 1,
            });
        }
        // Очистим переданные параметры
        history.push('/registration');
        // Проверяем, что переданы оба параметра
        // email и key
        if (
            ((key && !email) || (key && email === ''))
            || ((email && !key) || (email && key === ''))
        ) {
            eventEmitter.emit('modal:error', { message: 'Передана некорректная ссылка' });
            return;
        }
        if (key && email) {
            this.secondStepForm.$('code').set(key || '');
            this.setState((prevState) => ({
                firstForm: {
                    ...prevState.firstForm,
                    email,
                },
                secondForm: {
                    ...prevState.secondForm,
                    code: key,
                },
            }));
            this.secondStepForm.submit();
        }
    }

    handleSubmitFirstForm = (form) => {
        this.setState({
            firstForm: {
                ...form.values(),
                loading: true,
            },
        });
        const { firstForm } = this.state;
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            data: {
                query: registerUserStep1,
                variables: {
                    email: firstForm.email,
                    phone: firstForm.phone,
                    firstname: firstForm.name,
                    token: firstForm.captcha_token,
                },
            },
        }).then((result) => {
            console.log(result.res.data);
            form.clear();
            this.setState({
                tabIndex: 1,
            });
        }).finally(() => {
            this.setState((prevState) => ({
                firstForm: {
                    ...prevState.firstForm,
                    loading: false,
                },
            }));
        });
    }

    handleSubmitSecondForm = (form) => {
        this.setState({
            secondForm: {
                ...form.values(),
                loading: true,
            },
        });
        const { secondForm, firstForm } = this.state;
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            data: {
                query: registerUserStep2,
                variables: {
                    code: secondForm.code,
                    email: firstForm.email,
                },
            },
        }).then(() => {
            form.clear();
            this.setState({
                tabIndex: 2,
            });
        }).finally(() => {
            this.setState((prevState) => ({
                secondForm: {
                    ...prevState.secondForm,
                    loading: false,
                },
            }));
        });
    }

    handleSubmit = (form) => {
        this.setState({
            passForm: {
                ...form.values(),
                loading: true,
            },
        });
        const { firstForm, secondForm, passForm } = this.state;
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            data: {
                query: registerUserStep3,
                variables: {
                    email: firstForm.email,
                    code: secondForm.code,
                    password: passForm.password,
                },
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.RegisterUserStep3
                && result.res.data.RegisterUserStep3.access_token
                && result.res.data.RegisterUserStep3.expires_in
            ) {
                const { userStore, history } = this.props;
                userStore.setAuthorizedState({
                    email: firstForm.email,
                    accessToken: result.res.data.RegisterUserStep3.access_token,
                    expiresIn: result.res.data.RegisterUserStep3.expires_in,
                });
                form.clear();
                this.resetState();
                history.push('/startup');
            }
        }).finally(() => {
            this.setState((prevState) => ({
                passForm: {
                    ...prevState.passForm,
                    loading: false,
                },
            }));
        });
    }

    resetState = () => {
        this.setState({
            tabIndex: 0,
            firstForm: {
                loading: false,
            },
            secondForm: {
                loading: false,
            },
            passForm: {
                loading: false,
            },
        });
    }

    render() {
        const {
            tabIndex,
            firstForm,
            secondForm,
            passForm,
        } = this.state;
        return (
            <OnlyUnAuthWrapper>
                <PageWrapper>
                    <Container>
                        <Row>
                            <Col md={3} lg={4} />
                            <Col md={6} lg={4}>
                                <Tabs
                                    selectedIndex={tabIndex}
                                    onSelect={(index) => this.setState({ tabIndex: index })}
                                >
                                    <div
                                        className={styles['registration-page']}
                                    >
                                        <TabList className={styles.tab__list}>
                                            <Tab />
                                            <Tab />
                                            <Tab />
                                        </TabList>
                                        <div className={styles.progress__container}>
                                            <Progress
                                                count={3}
                                                current={tabIndex + 1}
                                            />
                                        </div>
                                        <div
                                            className={styles['registration-page__inner']}
                                        >
                                            <TabPanel>
                                                <FirstStepWrapper
                                                    handleSuccess={this.handleSubmitFirstForm}
                                                    loading={firstForm.loading}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <SecondStepWrapper
                                                    form={this.secondStepForm}
                                                    loading={secondForm.loading}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <ThirdStepWrapper
                                                    handleSuccess={this.handleSubmit}
                                                    loading={passForm.loading}
                                                />
                                            </TabPanel>
                                        </div>
                                        {
                                            tabIndex === 0
                                                ? (
                                                    <div
                                                        className={styles['registration-page__bottom']}
                                                    >
                                                        <div>Уже есть аккаунт?</div>
                                                        <Link
                                                            to="/login"
                                                            className={styles['registration-page__bottom-link']}
                                                        >
                                                            Войдите
                                                        </Link>
                                                    </div>
                                                )
                                                : null
                                        }
                                    </div>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </PageWrapper>
            </OnlyUnAuthWrapper>
        );
    }
}

export default decorator(
    withRouter(
        withTitle({
            component: RegistrationPage,
            title: 'Страница Регистрации',
        }),
    ),
);
