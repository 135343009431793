import {
    decorate,
    observable,
    action,
    computed,
} from 'mobx';
import request from '../../utils/request';
import { GRAPHQL_HOST, EMPTY_DATA_STRING } from '../../constants';
import { getItem } from '../../utils/localStorage';
import { requestInfo } from './queries';

/** Модель заявки */
class RequestStore {
    constructor({
        requestChatStore,
    }) {
        this.requestChatStore = requestChatStore;
    }

    id = null;

    /** Если true, то такой заявки не существует */
    isNullState = false;

    /** Состояние загрузки, меняется при получении информации */
    loading = false;

    /** Имя проекта к которому принадлежит заявка */
    project = null;

    /** Заголовок заявки */
    title = null;

    /** Описание заявки */
    description = null;

    /** Файлы и документы заявки */
    files = [];

    /** Дэдлайны по проекту */
    milestones = [];

    /** Список чек-листов по заявке */
    checkLists = [];

    get deadlines() {
        const result = [];
        if (this.milestones && this.milestones.length) {
            this.milestones.forEach((milestone) => {
                result.push({
                    id: milestone.id,
                    status: milestone.status_id,
                    title: milestone.caption,
                    hint: milestone.text || EMPTY_DATA_STRING,
                    date: milestone.date,
                });
            });
        }
        return result;
    }

    /**
     * Метод для добавления нового файла в стор
     *
     * @param {object} file файл для добавления
     */
    addFile(file) {
        if (!file) return;
        this.files.push(file);
    }

    /**
     * Получение параметра по его имени
     *
     * @param {string} caption имя
     * @returns {object} объект параметра
     */
    getParamByCaption(caption) {
        if (!this.params || !this.params.length) return null;
        return this.params.find(
            (param) => param.requestparamtype.caption === caption,
        );
    }

    /**
     * Цена за заявку
     *
     * @returns {string} цена
     */
    get sum() {
        const priceParam = this.getParamByCaption('Цена');
        return (priceParam && priceParam.varcharval) || EMPTY_DATA_STRING;
    }

    /**
     * Кол-во времени требуемое на выполнение
     *
     * @returns {string} время работы
     */
    get workingHours() {
        const workingHoursParam = this.getParamByCaption('Оценка времени');
        const result = `${EMPTY_DATA_STRING} ч`;
        if (
            workingHoursParam
            && workingHoursParam.varcharval
        ) {
            const hours = Math.floor(Number(workingHoursParam.varcharval) / 60);
            const minutes = Math.floor(Number(workingHoursParam.varcharval)) - (hours * 60);
            return `${hours ? `${hours} ч` : ''} ${minutes ? ` ${minutes} м` : ''}`;
        }
        return result;
    }

    setData(data) {
        this.id = Number(data.id);
        this.project = data.project && data.project.name;
        this.title = data.caption;
        this.description = data.text;
        this.files = data.files || [];
        this.milestones = data.milestones || [];
        this.checkLists = JSON.parse(data.checklist) || [];
        this.status = data.requeststatus || null;
        this.params = data.params || [];
        this.isNullState = false;
    }

    /**
     * Метод для получения заявки
     * по id
     *
     * @param {number|string} id id заявки
     * @returns {Promise} promise
     */
    getData(id) {
        this.loading = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: requestInfo(id),
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.request
            ) {
                this.setData(result.res.data.request);
                this.loading = false;
                this.requestChatStore.init(id);
                return Promise.resolve(result);
            }
            this.isNullState = true;
            this.loading = false;
            return Promise.reject(result);
        });
    }

    /**
     * Метод для очистки состояния
     */
    reset() {
        this.project = null;
        this.title = null;
        this.description = null;
        this.files = [];
        this.milestones = [];
        this.checkLists = [];
        this.isNullState = false;
    }
}

decorate(RequestStore, {
    id: observable,
    loading: observable,
    project: observable,
    title: observable,
    description: observable,
    files: observable,
    milestones: observable,
    checkLists: observable,
    isNullState: observable,
    sum: computed,
    workingHours: computed,
    deadlines: computed,
    setData: action,
    getData: action,
    reset: action,
});

export default RequestStore;
