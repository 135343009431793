import React from 'react';
import PropTypes from 'prop-types';

const DragAndDropIcon = ({
    width,
    height,
    color,
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 12 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="4"
            height="4"
            rx="1"
            fill={color}
        />
        <rect
            y="8"
            width="4"
            height="4"
            rx="1"
            fill={color}
        />
        <rect
            y="16"
            width="4"
            height="4"
            rx="1"
            fill={color}
        />
        <rect
            x="8"
            width="4"
            height="4"
            rx="1"
            fill={color}
        />
        <rect
            x="8"
            y="8"
            width="4"
            height="4"
            rx="1"
            fill={color}
        />
        <rect
            x="8"
            y="16"
            width="4"
            height="4"
            rx="1"
            fill={color}
        />
    </svg>
);

DragAndDropIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

DragAndDropIcon.defaultProps = {
    width: 12,
    height: 20,
    color: 'currentColor',
};

export default DragAndDropIcon;
