import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import EmailIcon from '../../components/SvgIcons/entities/EmailIcon';

import styles from './Projects.module.scss';

const ProjectItem = ({
    link,
    name,
    count,
    hasUnread,
}) => (
    <Link
        to={link}
        className={styles.item__link}
    >
        <div
            className={styles.item__inner}
        >
            <Tooltip content={name}>
                <div
                    className={styles.item__title}
                >
                    {name}
                </div>
            </Tooltip>
            <div
                className={styles.item__info}
            >
                Заявок активно
                <span className={styles.item__count}>{count}</span>
                {
                    hasUnread
                        ? (
                            <span className={styles.item__icon}>
                                <EmailIcon />
                            </span>
                        )
                        : null
                }
            </div>
        </div>
    </Link>
);

ProjectItem.propTypes = {
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    hasUnread: PropTypes.bool.isRequired,
};

export default ProjectItem;
