import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({
    width,
    height,
    fill,
}) => (
    <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.01427 17.2957C0.30873 16.5902 0.30873 15.4463 1.01427 14.7407L14.3715 1.38346C15.0771 0.677925 16.221 0.677924 16.9265 1.38346C17.6321 2.089 17.6321 3.23291 16.9265 3.93844L3.56925 17.2957C2.86371 18.0012 1.71981 18.0012 1.01427 17.2957Z"
            fill={fill}
        />
        <path
            d="M1.01426 1.38347C1.7198 0.67793 2.8637 0.67793 3.56924 1.38347L16.9265 14.7407C17.632 15.4463 17.632 16.5902 16.9265 17.2957C16.221 18.0013 15.0771 18.0013 14.3715 17.2957L1.01426 3.93845C0.308723 3.23291 0.308723 2.08901 1.01426 1.38347Z"
            fill={fill}
        />
    </svg>
);

CloseIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string,
};

CloseIcon.defaultProps = {
    width: 18,
    height: 18,
    fill: '#e5ecf2',
};

export default CloseIcon;
