const reqStatusesQuery = `
    query {
        requeststatuses {
            id
            order
            caption
            colorscheme {
                textcolor
            }
            active
        }
    }
`;

export default reqStatusesQuery;

export {
    reqStatusesQuery,
};
