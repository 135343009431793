import { observable, decorate } from 'mobx';

class Store {
    text = 'Text from store';
}

decorate(Store, {
    text: observable,
});

export default Store;
