/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Row,
    Col,
    Hidden,
} from 'react-grid-system';
import nextId from 'react-id-generator';
import logo from '../../assets/images/logo-icon.png';
import FooterCategory from './FooterCategory';
import { AUTH_STATES, SITE_URL, EMPTY_DATA_STRING } from '../../constants';
import FooterCategoryAdditional from './FooterCategoryAdditional';

import styles from './Footer.module.scss';

const categoryData = require('../../data/data.json');
const links = require('../../data/links.json');
const lastCommit = require('../../data/lastcommit.json');

class Footer extends React.Component {
    static propTypes = {
        isAuthorized: PropTypes.oneOf([
            0,
            1,
            2,
        ]).isRequired,
    };

    getCategoryLink = (category) => {
        if (
            !category
            || !category.alias
            || !category.alias.length
        ) return `${SITE_URL}groups/error.html`;
        if (
            category.alias && category.alias[1]
        ) {
            return `${SITE_URL}groups/${category.alias[1]}.html`;
        }
        return `${SITE_URL}groups/${category.alias[0]}.html`;
    }

    // eslint-disable-next-line class-methods-use-this
    get footerLinks() {
        if (links && links.footer) return Object.values(links.footer);
        return [];
    }

    // eslint-disable-next-line class-methods-use-this
    get lastCommitValue() {
        return lastCommit && lastCommit.value ? lastCommit.value : EMPTY_DATA_STRING;
    }

    // eslint-disable-next-line class-methods-use-this
    get categoryLinks() {
        if (!categoryData || !categoryData.length) return [];
        const categoryArr = Object.values(categoryData);
        const result = [];
        const additional = [];
        categoryArr.forEach((category, index) => {
            let servicesResult = [];
            if (
                category.services
                && category.services.length
            ) {
                if (category.services.length > 5) {
                    servicesResult = category.services.slice(0, 5);
                    servicesResult.push({
                        link: this.getCategoryLink(category),
                        caption: 'Смотреть все',
                        expand: true,
                    });
                } else {
                    servicesResult = category.services;
                }
            }
            if (index < 7) {
                result.push({
                    ...category,
                    services: servicesResult,
                });
            } else {
                additional.push({
                    ...category,
                    link: this.getCategoryLink(category),
                    services: servicesResult,
                });
            }
        });
        return [result, additional];
    }

    // eslint-disable-next-line class-methods-use-this
    get phoneLink() {
        if (
            links
            && links.header
            && links.header.phone
            && links.header.phone.name
            && links.header.phone.name !== ''
            && links.header.phone.link
            && links.header.phone.link !== ''
        ) return links.header.phone;
        console.error(`Номер телефона из json-файла имеет неверный формат: ${JSON.stringify(links.header && links.header.phone)}`);
        return {
            name: '8 (499) 322-39-08',
            link: 'tel:+74993223908',
        };
    }

    render() {
        const { isAuthorized } = this.props;
        const {
            categoryLinks,
            footerLinks,
            lastCommitValue,
            phoneLink,
        } = this;
        const data = categoryLinks[0];
        const additional = categoryLinks[1];
        return (
            <Hidden xs sm md>
                {
                    isAuthorized === AUTH_STATES.authorized
                        ? (
                            <footer className={styles['footer--auth']}>
                                <Container>
                                    <Row>
                                        <Col>
                                            <div className={styles.footer__links} />
                                            <div className={styles.footer__lastchange}>
                                                {`Последнее изменение: ${lastCommitValue}`}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </footer>
                        )
                        : (
                            <footer
                                className={styles.footer}
                            >
                                <Container>
                                    <Row>
                                        <Col>
                                            <div className={styles.footer__top}>
                                                <div className={styles['footer__top-left']}>
                                                    <a href={SITE_URL} className={styles['footer-logo__link']}>
                                                        <div className={styles['footer-logo']}>
                                                            <div className={styles['footer-logo__icon']}>
                                                                <img src={logo} alt="Logo" />
                                                            </div>
                                                            <div className={styles['footer-logo__text']}>
                                                                <span>W</span>
                                                                <span>h</span>
                                                                <span>i</span>
                                                                <span>t</span>
                                                                <span>e</span>
                                                                <span>b</span>
                                                                <span>o</span>
                                                                <span>x</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className={styles['footer__top-right']}>
                                                    <a
                                                        href={phoneLink.link}
                                                        className={styles['footer-phone__link']}
                                                    >
                                                        {phoneLink.name}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className={styles.footer__grid}>
                                                {
                                                    data && data.length
                                                        ? (
                                                            data.map((category) => (
                                                                <FooterCategory
                                                                    key={nextId('footer__category')}
                                                                    {...category}
                                                                />
                                                            ))
                                                        )
                                                        : null
                                                }
                                                {
                                                    additional && additional.length
                                                        ? (
                                                            <FooterCategoryAdditional
                                                                categories={additional}
                                                            />
                                                        )
                                                        : null
                                                }
                                            </div>
                                            <div className={styles.footer__bottom}>
                                                <div className={styles.footer__links}>
                                                    <ul>
                                                        {
                                                            footerLinks && footerLinks.length
                                                                ? (
                                                                    footerLinks.map((footerLink) => (
                                                                        <li
                                                                            key={nextId('footer__link')}
                                                                        >
                                                                            <a
                                                                                href={`${SITE_URL}${footerLink.link}`}
                                                                            >
                                                                                {footerLink.name}
                                                                            </a>
                                                                        </li>
                                                                    ))
                                                                )
                                                                : null
                                                        }
                                                    </ul>
                                                </div>
                                                <div className={styles.footer__lastchange}>
                                                    {`Последнее изменение: ${lastCommitValue}`}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </footer>
                        )
                }
            </Hidden>
        );
    }
}

export default Footer;
