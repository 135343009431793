import {
    decorate,
    observable,
    action,
    computed,
    toJS,
} from 'mobx';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import { getItem } from '../../utils/localStorage';
import { requestsQuery, projectsSummaryQuery } from './queries';
import RequestListItem from './RequestListItem';

// FIXME hard code
export const LIST_ACTIVE_STATUSES = [
    // Новая
    1,
    // Обсуждение
    2,
    // В работе
    3,
    // Требует проверки
    4,
    // Ожидание оплаты
    5,
    // Ожидает старта
    10,
    // (оценено)
    14,
    // (ожидание ответа клиента)
    12,
    // (ожидание деплоя)
    13,
];

class RequestListStore {
    constructor({
        projectListStore,
        statusListStore,
    }) {
        this.projectListStore = projectListStore;
        this.statusListStore = statusListStore;
    }

    /** Загружаются ли список заявок */
    loading = false;

    /** Загружаются ли доп. информация по статусам заявок */
    loadingSummary = false;

    /** Модель списка проектов */
    projectListStore = null;

    /** id активных статусов, для работы фильтра */
    activeStatusesId = LIST_ACTIVE_STATUSES;

    /** Есть ли вообще заявки у пользователя */
    withoutRequests = false;

    /** Список заявок */
    list = [];

    /**
     * Содержит информацию о заявке при изменении
     * порядка заявок и отправке запроса.
     */
    pendingOrderRequest = null;

    /**
     * Данные о активных заявках в проектах
     * для разных статусов.
     */
    summaryData = null;

    /**
     * Видимость проекта в элементе заявки.
     * Показывается только тогда, когда пользователь
     * смотрит заявки по всем проектам
     *
     * @returns {boolean} нужное состояние
     */
    get projectVisibility() {
        const { projectListStore } = this;
        const project = (projectListStore && projectListStore.activeProjectId) || null;
        if (!project) return true;
        return false;
    }

    /**
     * Получаем актуальный список статусов
     * с дополнительной информацией о кол-ве
     * заявок в этом статусе для проекта
     * выбранного в фильтре
     *
     * @returns {Array} массив статусов с информацией
     */
    get statusList() {
        const { statusListStore, summaryData } = this;
        let rawSummary = [];
        const { statusList } = statusListStore;
        const list = toJS(statusList);
        const clonedStatusList = [...list];
        if (summaryData && summaryData.length) {
            summaryData.forEach((data) => {
                rawSummary = rawSummary.concat(data.requestssummary);
            });
        }
        if (rawSummary && rawSummary.length) {
            rawSummary.forEach((data) => {
                const { requeststatus_id: statusId, count } = data;
                const targetStatusIndex = clonedStatusList.findIndex(
                    (clonedStatus) => clonedStatus.id === statusId,
                );
                if (targetStatusIndex > -1) {
                    const oldQty = clonedStatusList[targetStatusIndex].qty;
                    const newQty = Number(oldQty) + Number(count);
                    clonedStatusList[targetStatusIndex].qty = newQty;
                }
            });
        }
        return clonedStatusList;
    }

    setRequestsData(newState) {
        this.list = [];
        newState.forEach((requestItem) => {
            this.list.push(new RequestListItem(requestItem));
        });
    }

    /**
     * Метод для установки активных статусов.
     * Если ничего не передано, то ставит активные
     * статусы по умолчанию LIST_ACTIVE_STATUSES
     * Перезаписывает текущее состояние!
     *
     * @param {Array} statuses массив статусов
     */
    setActiveStatuses(statuses) {
        const newState = (
            statuses && statuses.length
                ? statuses
                : LIST_ACTIVE_STATUSES
        );
        this.activeStatusesId = newState;
    }

    /**
     * Метод добавляет новый активный статус
     * к массиву активных статусов
     *
     * @param {number} newStatus id нового статуса
     */
    addActiveStatus(newStatus) {
        this.activeStatusesId.push(newStatus);
    }

    /**
     * Метод удаляет активный статус из списка
     *
     * @param {number} statusId id статуса для
     * удаления из списка
     */
    deleteActiveStatus(statusId) {
        const { activeStatusesId } = this;
        const targetIndex = activeStatusesId.findIndex(
            (activeStatus) => activeStatus === statusId,
        );
        activeStatusesId.splice(targetIndex, 1);
        this.setActiveStatuses(activeStatusesId);
    }

    setPendingOrderRequest(newState) {
        this.pendingOrderRequest = newState;
    }

    /**
     * Метод для получения заявок по
     * выбранному проекту с фильтром
     *
     * @returns {Promise} запрос
     */
    getActualData() {
        // Предотвратим вызов дублирующегося запроса
        if (this.loading === true) return Promise.resolve(null);
        const { projectListStore } = this;
        this.loading = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        const statuses = this.activeStatusesId;
        const project = (projectListStore && projectListStore.activeProjectId) || null;
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: requestsQuery(statuses, project),
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.requests
            ) {
                this.setRequestsData(result.res.data.requests);
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    /**
     * Метод получает информацию о кол-ве заявок для статусов.
     *
     * @returns {Promise} Promise
     */
    getSummaryData() {
        // Предотвратим вызов дублирующегося запроса
        if (this.loadingSummary === true) return Promise.resolve(null);
        const { projectListStore } = this;
        this.loadingSummary = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        const project = projectListStore && projectListStore.activeProjectId
            ? `[${[projectListStore.activeProjectId]}]`
            : '[]';
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: projectsSummaryQuery(project),
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.projects
                && result.res.data.projects.data
            ) {
                this.summaryData = result.res.data.projects.data;
            }
        }).finally(() => {
            this.loadingSummary = false;
        });
    }

    getFullData() {
        this.getActualData();
        this.getSummaryData();
    }

    /**
     * Метод для нахождения заявки по id
     *
     * @param {number} id id
     * @returns {object} @see RequestListItem (модель заявки)
     */
    findRequestByd(id) {
        if (!id && id !== 0) return null;
        return this.list.find((requestItem) => requestItem.id === id);
    }

    /**
     * Метод для пересортировки после
     * перетаскивания элемента
     *
     * @param {number} startIndex индекс где был элемент
     * @param {number} endIndex индекс куда элемент был
     * перемещён
     */
    reorder(startIndex, endIndex) {
        const result = Array.from(this.list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        this.list = result;
    }

    /**
     * Метод для полного сброса состояния модели
     */
    reset() {
        this.loading = false;
        this.loadingSummary = false;
        this.projectListStore = null;
        this.activeStatusesId = [];
        this.withoutRequests = false;
        this.list = [];
    }
}

decorate(RequestListStore, {
    loading: observable,
    loadingSummary: observable,
    summaryData: observable,
    projectListStore: observable,
    activeStatusesId: observable,
    statusList: computed,
    pendingOrderRequest: observable,
    projectVisibility: computed,
    list: observable,
    addActiveStatus: action,
    deleteActiveStatus: action,
    setActiveStatuses: action,
    setPendingOrderRequest: action,
});

export default RequestListStore;
