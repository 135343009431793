import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { EmailIcon, LockIcon } from '../SvgIcons';
import Input from '../Input';
import Button from '../Button';
import FormField from '../FormField/FormField';

import styles from './Login.module.scss';

class LoginFormComponent extends React.Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func.isRequired,
            $: PropTypes.func.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    render() {
        const { form } = this.props;
        return (
            <form onSubmit={form.onSubmit}>
                <FormField field={form.$('email')} validateOnBlur>
                    <Input
                        type="text"
                        id="login_email"
                        icon={(<EmailIcon color="currentColor" />)}
                    />
                </FormField>
                <FormField field={form.$('password')} validateOnBlur>
                    <Input
                        type="password"
                        id="login_password"
                        icon={(<LockIcon color="currentColor" />)}
                    />
                </FormField>
                <div
                    className={styles.login__button}
                >
                    <Button
                        type="submit"
                        loading={form.loading}
                    >
                        Войти
                    </Button>
                </div>
                <div
                    className={styles['login__inner-bottom']}
                >
                    <Link
                        to="/restore-pass"
                        className={styles['login__link-restore']}
                    >
                        Забыли пароль?
                    </Link>
                </div>
            </form>
        );
    }
}

export default observer(LoginFormComponent);
