import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import FormData from 'form-data';
import ProjectListStore from '../../stores/ProjectListStore';
import NewRequestForm from './NewRequestForm';
import RequestCreateForm from '../../stores/RequestCreateForm';
import request from '../../utils/request';
import { GRAPHQL_HOST, REQUEST_STATUSES } from '../../constants';
import { getItem, setItem } from '../../utils/localStorage';
import { createRequest, uploadFile, deleteFile } from './queries';
import DialogStore from '../../stores/DialogStore/DialogStore';
import RequestListStore from '../../stores/RequestListStore';
import eventEmitter from '../../utils/eventEmitter';

class NewRequest extends React.Component {
    form = new RequestCreateForm({
        hooks: {
            onSuccess: (form) => this.handleSuccess(form),
        },
    });

    static propTypes = {
        projectListStore: PropTypes.instanceOf(ProjectListStore).isRequired,
        requestListStore: PropTypes.instanceOf(RequestListStore).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        dialogStore: PropTypes.instanceOf(DialogStore).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor() {
        super();
        this.state = {
            loading: false,
            documents: [],
            activeProjectOption: null,
        };
    }

    componentDidMount() {
        this.setState({
            activeProjectOption: this.defaultOption,
        });
        eventEmitter.on('project:update_active_id', this.handleProjectUpdateActiveId);
        eventEmitter.on('project:list_loaded', this.handleProjectUpdateActiveId);
    }

    componentWillUnmount() {
        eventEmitter.off('project:update_active_id', this.handleProjectUpdateActiveId);
        eventEmitter.off('project:list_loaded', this.handleProjectUpdateActiveId);
    }

    /**
     * Метод получает список опций на основе проектов
     * пользователя
     *
     * @returns {Array} массив опций
     */
    get options() {
        const { projectListStore } = this.props;
        return projectListStore.clearOptions;
    }

    /**
     * Метод возвращает опцию выбранного проекта
     *
     * @returns {object} опция активного проекта
     */
    get defaultOption() {
        const { projectListStore } = this.props;
        const { activeProjectOption } = projectListStore;
        let activeOption = activeProjectOption;
        if (!activeProjectOption || !activeProjectOption.value) {
            activeOption = {
                label: 'Проект не выбран',
                value: null,
            };
        }
        return activeOption;
    }

    /** Вызывается при изменении активного проекта */
    handleProjectUpdateActiveId = () => {
        this.setState({
            activeProjectOption: this.defaultOption,
        });
        this.setProjectField();
    }

    setProjectField = () => {
        const { form } = this;
        form.$('project').set((this.defaultOption && this.defaultOption.value) || '');
    }

    handleDrop = (acceptedFiles) => {
        const authToken = getItem('accessToken');
        if (!authToken) return;
        if (acceptedFiles && acceptedFiles[0]) {
            const firstFile = acceptedFiles[0];
            const o = {
                query: uploadFile,
                variables: {
                    file: null,
                },
            };
            const map = {
                0: ['variables.file'],
            };
            const fd = new FormData();
            fd.append('operations', JSON.stringify(o));
            fd.append('map', JSON.stringify(map));
            fd.append(0, firstFile, firstFile.name);
            request({
                method: 'POST',
                url: GRAPHQL_HOST,
                authToken,
                data: fd,
            }).then((result) => {
                if (
                    result
                    && result.res
                    && result.res.data
                    && result.res.data.UploadFile
                ) {
                    const id = result.res.data.UploadFile;
                    this.setState((prevState) => ({
                        documents: [
                            ...prevState.documents,
                            {
                                id,
                                ...firstFile,
                            },
                        ],
                    }));
                }
            });
        }
    }

    handleRemoveFile = (index) => {
        const { documents } = this.state;
        const array = [...documents];
        const targetDocument = array[index];
        const authToken = getItem('accessToken');
        if (!authToken) return;
        request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: deleteFile,
                variables: {
                    uid: targetDocument.id,
                },
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.DeleteFile
                && result.res.data.DeleteFile === targetDocument.id
            ) {
                if (index !== -1) {
                    array.splice(index, 1);
                    this.setState({ documents: array });
                }
            }
        });
    }

    handleProjectSelect = (option) => {
        this.setState({
            activeProjectOption: option,
        });
    }

    prepareDocuments = (documents) => {
        if (!documents || !documents.length) return [];
        const result = [];
        documents.forEach((document) => {
            result.push(document.id);
        });
        return result;
    }

    handleSuccess = (form) => {
        const {
            dialogStore,
            history,
            location,
            projectListStore,
            requestListStore,
        } = this.props;
        const {
            documents,
            activeProjectOption,
        } = this.state;
        const data = {
            ...form.values(),
            files: this.prepareDocuments(documents),
            project: activeProjectOption
                && activeProjectOption.value
                ? Number(activeProjectOption.value)
                : null,
        };
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        this.setState({ loading: true });
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: createRequest,
                variables: {
                    caption: data.name,
                    text: data.description,
                    project_id: data.project,
                    files: data.files,
                    requestStatus: REQUEST_STATUSES.NEW,
                },
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.CreateRequest
            ) {
                setItem({
                    name: 'newRequestId',
                    value: result.res.data.CreateRequest.id,
                });
                this.setState(() => ({
                    documents: [],
                }));
                form.clear();
                // Это поле должно быть заполнено
                this.setProjectField();
                dialogStore.hide();
                if (location && location.pathname === '/requests') {
                    projectListStore.setActiveProjectId(data.project);
                    requestListStore.setActiveStatuses();
                    requestListStore.getFullData();
                    history.push(`/requests?project=${data.project}`);
                } else {
                    history.push(`/requests?project=${data.project}`);
                }
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { documents, activeProjectOption, loading } = this.state;
        const { options, handleProjectSelect } = this;
        const { form } = this;
        return (
            <NewRequestForm
                form={form}
                documents={documents}
                options={options}
                handleProjectSelect={handleProjectSelect}
                defaultOption={activeProjectOption}
                handleDrop={this.handleDrop}
                handleRemoveFile={this.handleRemoveFile}
                loading={loading}
            />
        );
    }
}

export default inject(
    'projectListStore',
    'requestListStore',
    'dialogStore',
)(
    withRouter(
        observer(NewRequest),
    ),
);
