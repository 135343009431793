/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* global ab */
import eventEmitter from './eventEmitter';
import { getCleanObj } from '.';

require('./autobahn');

const { REACT_APP_WS_HOST, REACT_APP_WS_PORT } = process.env;

class Socket {
    constructor() {
        this.createConnection();
    }

    /**
     * Метод для создания соединения
     *
     * @param {Object} cb колбэки
     */
    createConnection(cb) {
        const ws = new ab.Session(
            `${REACT_APP_WS_HOST}:${REACT_APP_WS_PORT}`,
            () => {
                this.onOpen();
                if (cb && cb.onOpen) cb.onOpen();
            },
            () => {
                this.onClose();
                if (cb && cb.onClose) cb.onClose();
            },
            (error) => { this.onError(error); },
        );
        this.ws = ws;
    }

    /**
     * Метод для пересоздания соединения
     *
     * @param {Object} cb колбэки
     */
    reConnection(cb) {
        this.ws = null;
        this.createConnection(cb);
    }

    onOpen() {
        console.log(`Connection established on ${REACT_APP_WS_PORT} port!`);
        eventEmitter.emit('ws:connected');
    }

    onClose() {
        console.warn('WebSocket connection closed');
        eventEmitter.emit('ws:closed');
    }

    onError(error) {
        console.warn('WebSocket connection error', error);
        eventEmitter.emit('ws:error', error);
    }

    onMessage(response) {
        if (!response || !response.data) return;
        const { data } = response;
        console.log('ws:update');
        eventEmitter.emit('ws:update', data);
    }

    /**
     * Метод для отправки данных
     *
     * @param {object} param0 -
     * @param {string} param0.query query запроса
     * @param {string} param0.uid уникальный id запроса,
     * всегда должен начинаться с 0
     * @param {object} param0.headers заголовки для запроса
     * @param {string} param0.authToken токен авторизации
     * пользователя
     * @param {string} param0.subscribeName имя канала
     * по которому нужно отправить данные
     * @param {string} param0.variables переменные для
     * query запроса
     */
    send({
        query,
        authToken,
        subscribeName,
        variables,
    }) {
        const { ws } = this;
        const params = getCleanObj({
            query,
            token: authToken,
            variables,
        });
        ws.publish(subscribeName, params);
    }
}

const socket = new Socket();
window.socket = socket;

export default socket;
