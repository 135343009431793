import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';

import styles from './RequestChat.module.scss';

const RequestChatSocketError = ({
    onClick,
}) => (
    <div
        className={styles.chat__error}
    >
        <div
            className={styles['chat__error-inner']}
        >
            <div
                className={styles['chat__error-text']}
            >
                Отсутствует соединение с socket-сервером
            </div>
            <Button
                theme="with-border-square"
                onClick={onClick}
                fullWidth={false}
            >
                Подключиться еще раз
            </Button>
        </div>
    </div>
);

RequestChatSocketError.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default RequestChatSocketError;
