import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PhoneIcon } from '../SvgIcons';
import Input from '../Input';
import Button from '../Button';
import Checkbox from '../Checkbox';
import FormField from '../FormField/FormField';

import styles from './Registration.module.scss';

const FirstStep = ({
    form,
    loading,
}) => (
    <>
        <h2
            className={styles.registration__title}
        >
            Регистрация
        </h2>
        <form onSubmit={form.onSubmit} autoComplete="off">
            <FormField field={form.$('name')} validateOnBlur>
                <Input
                    id="registration_name"
                />
            </FormField>
            <FormField field={form.$('phone')} validateOnBlur>
                <Input
                    type="number"
                    id="registration_phone"
                    icon={(<PhoneIcon color="currentColor" />)}
                />
            </FormField>
            <FormField field={form.$('email')} validateOnBlur>
                <Input
                    id="registration_email"
                    icon={(<PhoneIcon color="currentColor" />)}
                />
            </FormField>
            <div
                className="captcha__container"
            >
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                    onChange={(token) => {
                        form.$('captcha_token').set(token);
                    }}
                />
            </div>
            <FormField field={form.$('captcha_token')} validateOnBlur>
                <Input
                    id="registration_captcha_token"
                    type="hidden"
                    icon={null}
                />
            </FormField>
            <FormField field={form.$('privacy-policy')} validateOnBlur>
                <Checkbox
                    id="registration__policy"
                    name="privacy-policy"
                >
                    Принимаю&nbsp;
                    <a href="/usloviya">условия оферты</a>
                    &nbsp;и согласен c&nbsp;
                    <a href="/policy">политикой конфиденциальности</a>
                </Checkbox>
            </FormField>
            <div
                className={styles.registration__button}
            >
                <Button
                    type="submit"
                    loading={loading}
                >
                    Продолжить
                </Button>
            </div>
        </form>
    </>
);

FirstStep.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func.isRequired,
        $: PropTypes.func.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default observer(FirstStep);
