import { decorate, observable, computed } from 'mobx';
import { EMPTY_DATA_STRING } from '../../constants';
import sumFormatted from '../../utils/units';

class InvoiceItem {
    id = null;

    _docnumber = null;

    _docdate = null;

    invoicestatus = null;

    text = null;

    sum = null;

    constructor(data) {
        this.id = data.id;
        this._docnumber = data.docnumber;
        this._docdate = data.docdate;
        this.invoicestatus = data.invoicestatus;
        this.text = data.text;
        this.sum = data.sum;
    }

    get docnumber() {
        return this._docnumber || EMPTY_DATA_STRING;
    }

    get docdate() {
        return this._docdate || EMPTY_DATA_STRING;
    }

    get sumFormatted() {
        return sumFormatted(`${this.sum}  ₽`);
    }
}

decorate(InvoiceItem, {
    id: observable,
    invoicestatus: observable,
    text: observable,
    sum: observable,
    docnumber: computed,
    docdate: computed,
    sumFormatted: computed,
});

export default InvoiceItem;
