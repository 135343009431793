const EMPTY_DATA_STRING = '-/-';
// TODO удалить после использования реальных данных
const DELAY_TIMEOUT = 1000;

// Адрес бэкэнда
const BE_HOST = process.env.REACT_APP_BE_HOST;
// Адрес GRAPHQL
const GRAPHQL_HOST = `${BE_HOST}/graphql`;

const SITE_URL = 'http://whitebox1.io/';

const AUTH_STATES = {
    unauthorized: 0,
    authorized: 1,
    undefined: 2,
};

const REQUEST_STATUSES = {
    NEW: 1,
};

export {
    EMPTY_DATA_STRING,
    DELAY_TIMEOUT,
    BE_HOST,
    GRAPHQL_HOST,
    AUTH_STATES,
    SITE_URL,
    REQUEST_STATUSES,
};
