import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import Button from '../Button';
import { LockIcon } from '../SvgIcons';
import FormField from '../FormField/FormField';

import styles from './RestorePass.module.scss';

const ThirdStep = ({
    form,
    loading,
}) => (
    <>
        <h2
            className={`
                ${styles['restore-pass__title']}
                ${styles['restore-pass__title--third']}
            `}
        >
            Восстановление пароля
        </h2>
        <p
            className={styles['restore-pass__text']}
        >
            Придумайте новый пароль
            <br />
            Пароль должен быть не менее 6 символов
        </p>
        <form onSubmit={form.onSubmit} autoComplete="off">
            <FormField field={form.$('password')} validateOnBlur>
                <Input
                    type="password"
                    id="restore-pass_password"
                    icon={(<LockIcon color="currentColor" />)}
                />
            </FormField>
            <FormField field={form.$('password_repeat')} validateOnBlur>
                <Input
                    type="password"
                    id="restore-pass_password_repeat"
                    icon={(<LockIcon color="currentColor" />)}
                />
            </FormField>
            <div
                className={styles['restore-pass__button']}
            >
                <Button
                    type="submit"
                    loading={loading}
                >
                    Завершить
                </Button>
            </div>
        </form>
    </>
);

ThirdStep.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func.isRequired,
        $: PropTypes.func.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ThirdStep;
