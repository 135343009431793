const editRequest = `
    mutation myEditRequest(
        $id: ID!
        $caption: String
        $text: String
    ) {
        EditRequest(
            id: $id
            caption: $caption
            text: $text
        ) {
            id
        }
    }
`;

export default editRequest;
