import { decorate, observable, action } from 'mobx';
import moment from 'moment';
import request from '../../utils/request';
import { GRAPHQL_HOST, EMPTY_DATA_STRING, AUTH_STATES } from '../../constants';
import { setItem, getItem, removeItem } from '../../utils/localStorage';
import { mutationLogin, userInfo } from './queries';
import eventEmitter from '../../utils/eventEmitter';

class UserStore {
    isAuthorized = AUTH_STATES.undefined;

    userName = EMPTY_DATA_STRING;

    userId = null;

    init() {
        const accessToken = getItem('accessToken');
        const dateExpires = getItem('dateExpires');
        const email = getItem('email');
        // TODO change logic for dateExpires
        if (accessToken && dateExpires) {
            const timeNow = moment(new Date());
            const timeExpired = moment(dateExpires);
            if (timeExpired.diff(timeNow) > 0) {
                this.userName = email || EMPTY_DATA_STRING;
                this.setAuthorized(AUTH_STATES.authorized);
                eventEmitter.emit('user:authorized');
            } else {
                this.setAuthorized(AUTH_STATES.unauthorized);
                eventEmitter.emit('user:unauthorized');
            }
        } else {
            this.setAuthorized(AUTH_STATES.unauthorized);
            eventEmitter.emit('user:unauthorized');
        }
    }

    /**
     * Метод для изменения состояния авторизации
     *
     * @param {boolean} newState новое состояние
     * авторизации
     */
    setAuthorized(newState) {
        if (newState === AUTH_STATES.authorized) {
            console.log('user:authorized');
            eventEmitter.emit('user:authorized');
            this.initFromStorage();
        }
        this.isAuthorized = newState;
    }

    initFromStorage() {
        const userInfoFromStorage = getItem('userInfo');
        if (!userInfoFromStorage) return;
        const userInfoObj = JSON.parse(userInfoFromStorage);
        this.userId = userInfoObj.id;
        this.email = userInfoObj.email;
        this.role = userInfoObj.role;
        this.userName = userInfoObj.firstname;
    }

    setUserInfo(newState) {
        this.userId = newState.id;
        this.email = newState.email;
        this.role = newState.role;
        this.userName = newState.firstname;
        const userInfoObj = {
            id: newState.id,
            email: newState.email,
            role: newState.role,
            firstname: newState.firstname,
        };
        setItem({ name: 'userInfo', value: JSON.stringify(userInfoObj) });
    }

    getUserInfo() {
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: userInfo,
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.me
            ) {
                this.setUserInfo(result.res.data.me);
            }
        });
    }

    authorize(email, pass) {
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            data: {
                query: mutationLogin,
                variables: {
                    email,
                    password: pass,
                },
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.Login
                && result.res.data.Login.access_token
                && result.res.data.Login.expires_in
            ) {
                this.setAuthorizedState({
                    accessToken: result.res.data.Login.access_token,
                    expiresIn: result.res.data.Login.expires_in,
                });
                this.getUserInfo();
            }
        });
    }

    /**
     * Устанавливает авторизованное состояние
     * с записью всей информации в localStorage
     *
     * @param {object} param0 -
     */
    setAuthorizedState({
        accessToken,
        expiresIn,
    }) {
        const dateExpires = moment().add(expiresIn * 1000).utc().format();
        setItem({ name: 'accessToken', value: accessToken });
        setItem({ name: 'dateExpires', value: dateExpires });
        this.setAuthorized(AUTH_STATES.authorized);
    }

    /**
     * Выход из авторизованного состояния
     */
    logOut() {
        this.setAuthorized(AUTH_STATES.unauthorized);
        removeItem('email');
        removeItem('accessToken');
        removeItem('dateExpires');
        removeItem('statuses');
    }
}

decorate(UserStore, {
    userName: observable,
    userId: observable,
    isAuthorized: observable,
    setAuthorizedState: action,
    logOut: action,
});

export default UserStore;
