const changePasswordStep1 = `
    mutation myChangePasswordStep1(
        $email: String!
    ) {
        ChangePasswordStep1(
            email: $email
        )
    }
`;

const changePasswordStep2 = `
    mutation myChangePasswordStep2(
        $code: String!
    ) {
        ChangePasswordStep2(
            code: $code
        )
    }
`;

const changePasswordStep3 = `
    mutation myChangePasswordStep3(
        $code: String!
        $password: String!
    ) {
        ChangePasswordStep3(
            code: $code
            password: $password
        ) {
            access_token
            expires_in
        }
    }
`;

export {
    changePasswordStep1,
    changePasswordStep2,
    changePasswordStep3,
};
