const downloadFile = `
    mutation ($uid: String!) {
        DownloadFile(uid: $uid)
    }
`;

export default downloadFile;

export {
    downloadFile,
};
