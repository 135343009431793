/* global localStorage */

/**
 * Метод для получения значения из localStorage
 *
 * @param {string} key название поля
 * @returns {string} значение поля из хранилища
 */
export const getItem = (key) => (
    localStorage.getItem(key)
);

/**
 * Метод для записи значений в localStorage
 *
 * @param {object} param0 -
 * @param {string} param0.name имя под которым будет сохранено значение
 * @param {string} param0.value значение, которое необходимо сохранить
 */
export const setItem = ({ name, value }) => {
    localStorage.setItem(name, value);
};

/**
 * Метод удаляет значение из localStorage
 *
 * @param {string} name имя параметра для удаления
 */
export const removeItem = (name) => {
    localStorage.removeItem(name);
};

export default {
    getItem,
    setItem,
    removeItem,
};
