/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import WithoutRequests from './WithoutRequests';
import PageLoader from '../PageLoader';
import { getItem, removeItem } from '../../utils/localStorage';
import Container from './Container';

import styles from './RequestList.module.scss';

class RequestList extends React.Component {
    static propTypes = {
        list: PropTypes.arrayOf(
            PropTypes.shape({}),
        ).isRequired,
        projectVisibility: PropTypes.bool.isRequired,
        withoutRequests: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        onDragEnd: PropTypes.func.isRequired,
        pendingOrderRequest: PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        }),
    };

    static defaultProps = {
        pendingOrderRequest: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.list.length !== prevState.items.length) {
            return { items: nextProps.list };
        }
        return null;
    }

    constructor(props) {
        super(props);
        const {
            list,
        } = props;
        this.state = {
            items: list,
        };
    }

    componentDidMount() {
        const newRequestId = getItem('newRequestId');
        this.newRequestId = newRequestId;
        if (newRequestId && newRequestId !== '') {
            this.scrollToLast();
            setTimeout(() => {
                removeItem('newRequestId');
            }, 200);
        }
    }

    componentDidUpdate() {
        const newRequestId = getItem('newRequestId');
        this.newRequestId = newRequestId;
        if (newRequestId && newRequestId !== '') {
            this.scrollToLast();
            setTimeout(() => {
                removeItem('newRequestId');
            }, 200);
        }
    }

    // a little function to help us with reordering the result
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    scrollToLast = () => {
        if (this.endList) this.endList.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Метод для получения предыдущего элемента
     * по индексу из переданного массива
     *
     * @param {Array} list массив для поиска
     * @param {number} index индекс
     * @returns {any} предыдущий элемент из массива
     */
    getPreviousItem = (list, index) => (list[index - 1])

    /**
     * Метод для получения следующего элемента
     * по индексу из переданного массива
     *
     * @param {Array} list массив для поиска
     * @param {number} index индекс
     * @returns {any} следующий элемент из массива
     */
    getNextItem = (list, index) => (list[index + 1])

    onDragEnd = (result) => {
        const { items } = this.state;
        console.log('result', result);
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if (
            result.destination
            && result.source
            && result.destination.index === result.source.index
        ) return;
        const newItemsState = this.reorder(
            items,
            result.source.index,
            result.destination.index,
        );
        this.setState({
            items: newItemsState,
        });
        const previousItem = this.getPreviousItem(newItemsState, result.destination.index);
        const nextItem = this.getNextItem(newItemsState, result.destination.index);
        const targetItem = newItemsState[result.destination.index];
        const { onDragEnd } = this.props;
        onDragEnd(targetItem, {
            previousItem,
            nextItem,
        });
    }

    render() {
        const {
            projectVisibility,
            withoutRequests,
            loading,
            pendingOrderRequest,
        } = this.props;
        const { items } = this.state;
        if (loading === true) return (<PageLoader />);
        if (withoutRequests) return (<WithoutRequests />);
        return (
            <div className={styles.container}>
                <div
                    className={styles.table}
                >
                    <div
                        className={`
                            ${styles.tr}
                            ${styles.tr__head}
                        `}
                    >
                        <div
                            className={styles.th}
                        >
                            Название
                        </div>
                        <div
                            className={styles.th}
                        >
                            Дедлайн
                        </div>
                        <div
                            className={styles.th}
                        >
                            Оценка
                        </div>
                        <div
                            className={styles.th}
                        />
                    </div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {
                                (provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        <Container
                                            list={items}
                                            projectVisibility={projectVisibility}
                                            newRequestId={this.newRequestId}
                                            pendingOrderRequest={pendingOrderRequest}
                                            onDragEnd={this.onDragEnd}
                                            snapshotOpacity={snapshot.isDragging ? 0 : 1}
                                        />
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>
                    </DragDropContext>
                    <div
                        style={{
                            marginTop: '-80px',
                        }}
                        ref={(el) => { this.endList = el; }}
                    />
                </div>
            </div>
        );
    }
}

export default observer(RequestList);
