import { decorate, observable } from 'mobx';
import { getItem } from '../../utils/localStorage';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import { membersQuery, addUserMutation } from './queries';
import MemberItem from './MemberItem';

class MembersCompanyStore {
    loading = false;

    list = [];

    init() {
        this.getActualData();
    }

    /**
     * Метод для получения заявок по
     * выбранному проекту с фильтром
     *
     * @returns {Promise} запрос
     */
    getActualData() {
        this.loading = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        this.list = [];
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: membersQuery,
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && (result.res.data.users || result.res.data.invitations)
            ) {
                const resultList = [];
                if (result.res.data.users) {
                    result.res.data.users.forEach((user) => {
                        resultList.push(new MemberItem({ ...user, isInvite: false }));
                    });
                }
                if (result.res.data.invitations) {
                    result.res.data.invitations.forEach((user) => {
                        resultList.push(new MemberItem({ ...user, isInvite: true }));
                    });
                }
                this.setMembersData(resultList);
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    setMembersData(data) {
        if (!data || !data.length) return;
        data.forEach((member) => {
            this.add(member);
        });
    }

    add(newMember) {
        const duplicate = this.list.find((item) => item.email === newMember.email);
        if (!duplicate) {
            this.list.push(newMember);
        }
    }

    addUser(email) {
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: addUserMutation,
                variables: {
                    email,
                },
            },
        }).then((result) => {
            console.log('result adduser', result);
            if (
                result
                && result.res
                && result.res.data
            ) {
                this.getActualData();
            }
        });
    }

    reset() {
        this.loading = false;
        this.list = [];
    }
}

decorate(MembersCompanyStore, {
    loading: observable,
    list: observable,
});

export default MembersCompanyStore;
