import React from 'react';
import PropTypes from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';
import { EMPTY_DATA_STRING } from '../../constants';
import { toTextWithWrapper } from '../../utils';

import styles from './Request.module.scss';

const ReadMoreEllipsis = ({
    toggleTruncate,
}) => (
    <span
        style={{
            paddingLeft: '5px',
        }}
    >
        <button
            type="button"
            onClick={toggleTruncate}
            className={styles['truncate-button']}
        >
            Показать все...
        </button>
    </span>
);

ReadMoreEllipsis.propTypes = {
    toggleTruncate: PropTypes.func.isRequired,
};

class RequestDescription extends React.Component {
    static propTypes = {
        description: PropTypes.string,
    };

    static defaultProps = {
        description: EMPTY_DATA_STRING,
    };

    constructor() {
        super();
        this.state = {
            shouldTruncate: true,
        };
    }

    toggleTruncate = () => {
        this.setState((prevState) => ({
            shouldTruncate: !prevState.shouldTruncate,
        }));
    }

    render() {
        const { shouldTruncate } = this.state;
        const { description } = this.props;
        const descriptionText = toTextWithWrapper(description);
        return (
            <div
                className={styles.description}
            >
                {
                    shouldTruncate === true
                        ? (
                            <TruncateMarkup
                                tokenize="words"
                                lines={5}
                                ellipsis={(
                                    <ReadMoreEllipsis
                                        toggleTruncate={this.toggleTruncate}
                                    />
                                )}
                            >
                                <div>
                                    {descriptionText}
                                </div>
                            </TruncateMarkup>
                        )
                        : (
                            <div>
                                {descriptionText}
                                <button
                                    type="button"
                                    onClick={this.toggleTruncate}
                                    className={styles['truncate-button']}
                                >
                                    {' Свернуть'}
                                </button>
                            </div>
                        )
                }
            </div>
        );
    }
}

export default RequestDescription;
