import { decorate, observable, computed } from 'mobx';

const ADMIN_ROLE_ID = 0;
const DEFAULT_STATUS_TEXT = 'Не зарегистрирован';
const DEFAULT_NAME = 'Без имени';

const TYPES = {
    ADMIN: 'Админ',
    MEMBER: 'Сотрудник',
};

const COLORS = {
    ADMIN: '38c8a8',
    DEFAULT: '9ab4c8',
    MEMBER: '3c4f5a',
};

class MemberItem {
    id = null;

    email = null;

    role = null;

    _firstname = null;

    _status = {};

    /**
     * Является ли пользователь приглашённым
     * и не зарегистрированным
     */
    isInvite = false;

    constructor(data) {
        this.id = data.id;
        this.email = data.email;
        this.role = data.role;
        this._firstname = data.firstname;
        this._status = data.status;
        this.isInvite = data.isInvite;
    }

    get firstname() {
        if (this.isInvite) {
            return DEFAULT_NAME;
        }
        return this._firstname || DEFAULT_NAME;
    }

    get status() {
        if (this.isInvite) {
            return {
                text: DEFAULT_STATUS_TEXT,
                color: COLORS.DEFAULT,
            };
        }
        return {
            text: this.role === ADMIN_ROLE_ID ? TYPES.ADMIN : TYPES.MEMBER,
            color: this.role === ADMIN_ROLE_ID ? COLORS.ADMIN : COLORS.MEMBER,
        };
    }
}

decorate(MemberItem, {
    id: observable,
    email: observable,
    role: observable,
    _firstname: observable,
    _status: observable,
    isInvite: observable,
    firstname: computed,
    status: computed,
});

export default MemberItem;
