import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import nextId from 'react-id-generator';
import MembersCompanyStore from '../../stores/MembersCompany';
import Button from '../../components/Button';
import DialogStore from '../../stores/DialogStore';
import { EMPTY_DATA_STRING } from '../../constants';

import styles from './MembersCompany.module.scss';
import PageLoader from '../../components/PageLoader';

class MembersCompany extends React.Component {
    static propTypes = {
        membersStore: PropTypes.instanceOf(MembersCompanyStore).isRequired,
        dialogStore: PropTypes.instanceOf(DialogStore).isRequired,
    };

    handleClick = () => {
        const { dialogStore } = this.props;
        dialogStore.show('add_new_member');
    }

    render() {
        const { membersStore } = this.props;
        const { list, loading } = membersStore;
        return (
            <div
                className={styles.container}
            >
                <h3>
                    Сотрудники вашей компании
                </h3>
                {
                    loading === true
                        ? (<PageLoader minHeight={200} />)
                        : (
                            <>
                                <div className="table table--full-width">
                                    <div className="tr">
                                        <div className="th">
                                            Имя
                                        </div>
                                        <div className="th">
                                            E-mail
                                        </div>
                                        <div className="th">
                                            Статус
                                        </div>
                                    </div>
                                    {
                                        list && list.length
                                            ? (
                                                list.map((item) => (
                                                    <div
                                                        className="tr"
                                                        key={nextId('member_company_row_data')}
                                                    >
                                                        <div className={`td ${styles.cell} ${styles['cell--name']}`}>
                                                            {item.firstname}
                                                        </div>
                                                        <div className={`td ${styles.cell} ${styles['cell--email']}`}>
                                                            {item.email}
                                                        </div>
                                                        <div
                                                            className={`
                                                                td ${styles.cell}
                                                                td ${styles['cell--status']}
                                                            `}
                                                            style={{
                                                                color: item.status && item.status.color
                                                                    ? `#${item.status.color}`
                                                                    : null,
                                                            }}
                                                        >
                                                            {
                                                                item.status && item.status.text
                                                                    ? item.status.text
                                                                    : EMPTY_DATA_STRING
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                            : 'Сотрудников нет'
                                    }
                                </div>
                                <div className={styles.button__container}>
                                    <Button
                                        theme="with-border"
                                        fullWidth={false}
                                        onClick={this.handleClick}
                                    >
                                        + Добавить сотрудника
                                    </Button>
                                </div>
                            </>
                        )
                }
            </div>
        );
    }
}

export default inject('membersStore', 'dialogStore')(observer(MembersCompany));
