import React from 'react';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import PageLoader from '../PageLoader';
import { EMPTY_DATA_STRING } from '../../constants';
import { DocumentIcon } from '../SvgIcons';
import EmptyState from '../EmptyState';
import { toTextWithWrapper } from '../../utils';

import styles from './Invoice.module.scss';

class Invoice extends React.Component {
    static propTypes = {
        isNullState: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        project: PropTypes.string.isRequired,
        docnumber: PropTypes.string,
        docdate: PropTypes.string,
        duedate: PropTypes.string,
        status: PropTypes.shape({
            name: PropTypes.string,
            color: PropTypes.string,
        }).isRequired,
        text: PropTypes.string,
        files: PropTypes.arrayOf(
            PropTypes.shape({
                ext: PropTypes.string,
                link: PropTypes.string,
            }),
        ),
        sumFormatted: PropTypes.string,
        leftSum: PropTypes.string,
        extra: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    };

    static defaultProps = {
        docnumber: EMPTY_DATA_STRING,
        docdate: EMPTY_DATA_STRING,
        duedate: EMPTY_DATA_STRING,
        text: EMPTY_DATA_STRING,
        files: [],
        sumFormatted: EMPTY_DATA_STRING,
        leftSum: EMPTY_DATA_STRING,
        extra: [],
    };

    render() {
        const {
            isNullState,
            loading,
            project,
            docnumber,
            docdate,
            duedate,
            status,
            text,
            files,
            sumFormatted,
            leftSum,
            extra,
        } = this.props;
        if (loading === true) return (<PageLoader />);
        return (
            <>
                {
                    isNullState === false
                        ? (
                            <div
                                className={styles.container}
                            >
                                <Row>
                                    <Col md={8}>
                                        <h3 className={styles.project}>{project}</h3>
                                        <h2 className={styles.title}>
                                            <span>
                                                {`Счет №${docnumber} от ${docdate}`}
                                            </span>
                                            <span
                                                className={styles.title__status}
                                                style={{
                                                    color: `#${status.color}`,
                                                }}
                                            >
                                                {status.name}
                                            </span>
                                        </h2>
                                        <p className={styles.description}>
                                            {toTextWithWrapper(text)}
                                        </p>
                                        <div>
                                            {
                                                files && files.length
                                                    ? (
                                                        files.map((file) => (
                                                            <div
                                                                key={nextId('invoice_document_item')}
                                                            >
                                                                <a
                                                                    className={
                                                                        styles.document__item
                                                                    }
                                                                    href={file.link}
                                                                    download
                                                                >
                                                                    <span className={styles['document__item-icon']}>
                                                                        <DocumentIcon />
                                                                    </span>
                                                                    <span>
                                                                        {`${file.filename}`}
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        ))
                                                    )
                                                    : null
                                            }
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className={styles.info__item}>
                                            <div className={styles['info__item-title']}>
                                                Сумма счета
                                            </div>
                                            <div className={styles['info__item-value']}>
                                                {sumFormatted}
                                            </div>
                                        </div>
                                        <div className={styles.info__item}>
                                            <div className={styles['info__item-title']}>
                                                Оплатить до
                                            </div>
                                            <div className={styles['info__item-value']}>
                                                {duedate}
                                            </div>
                                        </div>
                                        <div className={styles.info__item}>
                                            <div className={styles['info__item-title']}>
                                                Осталось оплатить
                                            </div>
                                            <div className={styles['info__item-value']}>
                                                {leftSum}
                                            </div>
                                        </div>
                                        {
                                            extra && extra.length
                                                ? (
                                                    extra.map((item) => (
                                                        <div
                                                            className={styles.info__item}
                                                            key={nextId('extra_info__item')}
                                                        >
                                                            <div className={styles['info__item-title']}>
                                                                {item.title}
                                                            </div>
                                                            {
                                                                item.type === 'link'
                                                                    ? (
                                                                        <a href={item.link}>
                                                                            {item.value}
                                                                        </a>
                                                                    )
                                                                    : (
                                                                        <div className={styles['info__item-value']}>
                                                                            {item.value}
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    ))
                                                )
                                                : null
                                        }
                                    </Col>
                                </Row>
                            </div>
                        )
                        : (<EmptyState text="Счет не существует" />)
                }
            </>
        );
    }
}

export default Invoice;
