const createRequest = `
    mutation myCreateRequest(
        $caption: String!
        $text: String!
        $project_id: ID!
        $files: [String]
        $requestStatus: ID!
    ) {
        CreateRequest(
            caption: $caption
            text: $text
            project_id: $project_id
            files: $files
            requeststatus_id: $requestStatus
        ) {
            id
        }
    }
`;

const uploadFile = `
    mutation ($file: Upload!) {
        UploadFile(file: $file)
    }
`;

const deleteFile = `
    mutation ($uid: String!) {
        DeleteFile(uid: $uid)
    }
`;

export default createRequest;

export {
    createRequest,
    uploadFile,
    deleteFile,
};
