/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import autosize from 'autosize';
import Dropzone from 'react-dropzone';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ClipIcon, SendIcon } from '../../components/SvgIcons';
import FormField from '../../components/FormField/FormField';
import RequestChatStore from '../../stores/RequestStore/RequestChat';
import RequestStore from '../../stores/RequestStore';
import eventEmitter from '../../utils/eventEmitter';

import styles from './RequestChat.module.scss';

class RequestChatTextarea extends React.Component {
    firstLoad = true;

    static propTypes = {
        requestChatStore: PropTypes.instanceOf(RequestChatStore).isRequired,
        requestStore: PropTypes.instanceOf(RequestStore).isRequired,
        form: PropTypes.shape({
            onSubmit: PropTypes.func.isRequired,
            $: PropTypes.func.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
        keyPressed: PropTypes.func.isRequired,
        handleUpload: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        autosize(this.textarea);
        eventEmitter.on('request_chat:cleared', this.handleChatClear);
    }

    handleChatClear = () => {
        autosize.update(this.textarea);
    }

    render() {
        const {
            form,
            keyPressed,
            handleUpload,
            disabled,
        } = this.props;
        return (
            <div
                className={styles['chat__textarea-container']}
            >
                <form onSubmit={form.onSubmit} autoComplete="off">
                    <div
                        className={styles['chat__textarea-inner']}
                    >
                        <FormField field={form.$('message')} validateOnBlur>
                            <textarea
                                className={styles.chat__textarea}
                                ref={(c) => { this.textarea = c; }}
                                rows={1}
                                onKeyPress={keyPressed}
                                disabled={disabled}
                            />
                        </FormField>
                        <Dropzone
                            onDrop={handleUpload}
                            multiple={false}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <input {...getInputProps()} />
                                    <button
                                        type="button"
                                        className={`
                                            ${styles.chat__button}
                                            ${styles['chat__button--upload']}
                                        `}
                                        {...getRootProps()}
                                    >
                                        <ClipIcon />
                                    </button>
                                </section>
                            )}
                        </Dropzone>
                        <button
                            type="submit"
                            className={`
                                ${styles.chat__button}
                                ${styles['chat__button--send']}
                            `}
                        >
                            <SendIcon />
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default inject(
    'requestChatStore',
    'requestStore',
)(
    observer(RequestChatTextarea),
);
