/* global FormData document DOMParser window */

/**
 * Метод создаёт FormData из объекта
 * с данными
 *
 * @param {object} objData данные для преобразования
 * @returns {FormData} FormData с нужными данными
 */
const getFormDataFromObj = (objData) => {
    const bodyFormData = new FormData();
    Object.keys(objData).forEach((key) => {
        bodyFormData.set(key, objData[key]);
    });
    return bodyFormData;
};

/**
 * Простая проверка на пустоту объекта
 *
 * @param {any} object объект для проверки
 * @returns {boolean} является ли переданное
 * значения пустым объектом
 */
const isEmptyObj = (object) => (
    JSON.stringify(object) === '{}'
);

/**
 * Метод удаляет из объекта пустые значения
 *
 * @param {object} obj объект, который
 * необходимо "очистить"
 * @returns {object} "очищенный" объект
 */
const getCleanObj = (obj) => {
    const propNames = Object.getOwnPropertyNames(obj);
    let i = 0;
    for (i; i < propNames.length; i += 1) {
        const propName = propNames[i];
        if (
            obj[propName] === null
            || obj[propName] === undefined
            || obj[propName] === ''
        ) {
            delete obj[propName];
        }
    }
    return obj;
};

/**
 * Метод очищает переданный объект от
 * пустых значений и формирует formData
 *
 * @param {object} obj данные для преобразования
 * @returns {FormData} FormData с нужными данными
 */
const getCleanFormData = (obj) => {
    const cleanObj = getCleanObj(obj);
    return getFormDataFromObj(cleanObj);
};

/**
 * Метод возврщает стили ноды
 *
 * @param n Нода для проверки
 * @param p Свойстов для проверки (обычно 'display').
 * @link http://www.quirksmode.org/dom/getstyles.html
 */
const getStyle = (n, p) => (
    n.currentStyle
        ? n.currentStyle[p]
        : document.defaultView.getComputedStyle(n, null).getPropertyValue(p)
);

/**
 * Конвертируем HTML в текст, сохранив семантику перенос строк
 * блочных элементов
 *
 * @param {Node} node - HTML-нода для получения текста
 * @returns {string} текст с переносами
 */
const toText = (node) => {
    let result = '';
    if (!node || !node.nodeType) return result;
    if (node.nodeType === document.TEXT_NODE) {
        // Замена повторяющихся пробелов, новых линий
        // и табов, одиночным пробелом. А также удаление
        // HTML-тэгов
        result = node.nodeValue
            .replace(/\s+/g, ' ')
            .replace(/<(.|\n)*?>/g, '');
    } else {
        for (let i = 0, j = node.childNodes.length; i < j; i += 1) {
            result += toText(node.childNodes[i]);
        }
        const d = getStyle(node, 'display');
        if (
            d.match(/^block/)
            || d.match(/list/)
            || d.match(/row/)
            || node.tagName === 'BR'
            || node.tagName === 'HR'
            || node.tagName === 'DIV'
        ) {
            result += '\n';
        }
    }

    return result;
};

/**
 * Метод оборачивает текст или html-структуру
 * div-элементом и преобразовывает результат
 * в строку с переносами. Метод создан для
 * преобразования строк, либо html-разметки
 * независимо от входного параметра
 *
 * @param {string|HTMLElement} text текст для
 * преобразования
 * @returns {string} готовая строка
 */
const toTextWithWrapper = (text) => {
    const dom = document.createElement('div');
    dom.innerHTML = text;
    return toText(dom);
};

export default getFormDataFromObj;

export {
    getFormDataFromObj,
    isEmptyObj,
    getCleanObj,
    getCleanFormData,
    toText,
    toTextWithWrapper,
};
