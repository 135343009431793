import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import { SITE_URL } from '../../constants';

import styles from './Footer.module.scss';

const FooterCategoryAdditional = ({
    categories,
}) => (
    <div className={styles['footer__grid-category']}>
        <h3
            className={styles['footer__item-heading']}
        >
            А также:
        </h3>
        <ul className={styles['footer__item-list']}>
            {
                categories && categories.length
                    ? (
                        categories.map((category) => (
                            <li
                                key={nextId('footer__item-list-element')}
                            >
                                <a
                                    href={category.link}
                                    title={category.caption}
                                >
                                    <img
                                        className={`${styles.icon} ${styles['icon--default']}`}
                                        src={`${SITE_URL}assets/images/icon_${category.id}-w.svg`}
                                        alt=""
                                    />
                                    {category.caption}
                                </a>
                            </li>
                        ))
                    )
                    : null
            }
        </ul>
    </div>
);

FooterCategoryAdditional.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            title: PropTypes.string,
        }),
    ),
};

FooterCategoryAdditional.defaultProps = {
    categories: [],
};

export default FooterCategoryAdditional;
