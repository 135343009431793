import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Header from '../Header';
import UserStore from '../../stores/UserStore';
import DialogStore from '../../stores/DialogStore';
import Footer from '../Footer';
import { AUTH_STATES, EMPTY_DATA_STRING } from '../../constants';
import eventEmitter from '../../utils/eventEmitter';
import Dialog from '../Dialog/Dialog';

import styles from './PageWrapper.module.scss';
import Button from '../../components/Button';

const decorator = inject(
    'userStore',
    'dialogStore',
);

class PageWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        dialogStore: PropTypes.instanceOf(DialogStore).isRequired,
    };

    constructor() {
        super();
        this.state = {
            errorMessage: null,
            withReturnButton: true,
        };
    }

    componentDidMount() {
        eventEmitter.on('modal:error', this.handleModalError);
    }

    componentWillUnmount() {
        eventEmitter.off('modal:error', this.handleModalError);
    }

    handleModalError = (data) => {
        const { dialogStore } = this.props;
        const { message, withReturnButton } = data;
        this.setState({
            errorMessage: message,
            withReturnButton: withReturnButton || false,
        });
        dialogStore.show('modal_error');
    }

    handleErrorButtonClick = () => {
        const { dialogStore } = this.props;
        dialogStore.back(2);
    }

    render() {
        const { errorMessage, withReturnButton } = this.state;
        const { children, userStore } = this.props;
        const { isAuthorized } = userStore;
        return (
            <div
                className={`
                    ${styles['page-wrapper']}
                    ${
                        isAuthorized === AUTH_STATES.authorized
                            ? styles['page-wrapper--white']
                            : styles['page-wrapper--blue']
                    }
                `}
            >
                <div className={styles['page-wrapper__content']}>
                    <Header isAuthorized={isAuthorized} />
                    {children}
                </div>
                <Footer isAuthorized={isAuthorized} />
                <Dialog
                    name="modal_error"
                    size="sm"
                    footer={null}
                    header="Ошибка"
                >
                    {errorMessage || EMPTY_DATA_STRING}
                    {
                        withReturnButton === true
                            ? (
                                <div className={styles['button-error']}>
                                    <Button
                                        onClick={this.handleErrorButtonClick}
                                        theme="green"
                                    >
                                        Попробовать ещё раз
                                    </Button>
                                </div>
                            )
                            : null
                    }
                </Dialog>
            </div>
        );
    }
}

export default decorator(observer(PageWrapper));
