import nextId from 'react-id-generator';
import Form from '../Form/FormModel';

class SecondStepForm extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [{
                name: 'code',
                label: 'Код',
                rules: 'required',
                value: '',
                id: nextId(),
            }],
        };
    }
}

export default SecondStepForm;
