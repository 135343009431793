import {
    decorate,
    observable,
} from 'mobx';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import { getItem, setItem } from '../../utils/localStorage';
import { reqStatusesQuery } from './queries';

class StatusListStore {
    /** Загружается ли список статусов */
    loadingStatuses = false;

    /** список всех статусов */
    statusList = []

    init() {
        this.getStatusesFromLS()
            .then((statuses) => this.setStatusListData(statuses))
            .catch(() => {
                this.getStatuses()
                    .then((statuses) => {
                        if (statuses) {
                            this.setStatusListToLS(statuses);
                        }
                    });
            });
    }

    /**
     * Метод для получения статусов из локального хранилища
     */
    // eslint-disable-next-line class-methods-use-this
    getStatusesFromLS() {
        return new Promise((resolve, reject) => {
            const statuses = getItem('statuses');
            if (!statuses || statuses === null) reject();
            resolve(JSON.parse(statuses));
        });
    }

    // eslint-disable-next-line class-methods-use-this
    setStatusListToLS(statuses) {
        setItem({
            name: 'statuses',
            value: JSON.stringify(statuses),
        });
    }

    setStatusListData(newState) {
        newState.forEach((status) => {
            const duplicate = this.statusList.find(
                (item) => Number(item.id) === Number(status.id),
            );
            if (!duplicate) {
                this.statusList.push({
                    label: status.caption,
                    id: Number(status.id),
                    // FIXME add qty parameter
                    qty: 0,
                    // FIXME add hasUnread parameter
                    hasUnread: false,
                    theme: status.colorscheme
                        && status.colorscheme.textcolor,
                    active: status.active,
                });
            }
        });
    }

    /**
     * Метод для получения статусов
     *
     * @returns {Promise} запрос
     */
    getStatuses() {
        // Предотвратим вызов дублирующегося запроса
        if (this.loadingStatuses === true) return Promise.resolve(null);
        this.loadingStatuses = true;
        const authToken = getItem('accessToken');
        console.log('token', authToken);
        if (!authToken) return Promise.resolve(null);
        const activeStatus = {
            id: 0,
            caption: 'Все активные',
            colorscheme: {
                textcolor: '457eca',
            },
        };
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: reqStatusesQuery,
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.requeststatuses
                && result.res.data.requeststatuses.length
            ) {
                const statuses = [activeStatus, ...result.res.data.requeststatuses];
                this.setStatusListData(statuses);
                return Promise.resolve(statuses);
            }
        }).finally(() => {
            this.loadingStatuses = false;
        });
    }

    /**
     * Метод проверяет является ли статус активным
     *
     * @param {number|string} statusId id статуса
     * для проверки
     * @returns {boolean} активен статус или нет
     */
    statusIsActive(statusId) {
        let result = false;
        if (!statusId) return result;
        const target = this.statusList.find(
            (statusItem) => Number(statusItem.id) === Number(statusId),
        );
        if (target) {
            result = Boolean(target.active);
        }
        return result;
    }

    /**
     * Метод для полного сброса состояния модели
     */
    reset() {
        this.loadingStatuses = false;
        this.statusList = [];
    }
}

decorate(StatusListStore, {
    loadingStatuses: observable,
    statusList: observable,
    statusIsActive: observable,
});

export default StatusListStore;
