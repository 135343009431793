const membersQuery = `
    query {
        users {
            id
            email
            role
            firstname
            # lastname
            status
        }
        invitations {
            id
            email
        }
  }
`;

const addUserMutation = `
    mutation myAddUser(
        $email: String!
    ) {
        AddUser(
            email: $email
        ) {
            id
        }
    }
`;

export default membersQuery;

export {
    membersQuery,
    addUserMutation,
};
