import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { PageWrapper } from '../../containers/PageWrapper';
import LastEvents from '../../containers/LastEvents';
import Projects from '../../containers/Projects';
import MembersCompany from '../../containers/MembersCompany';
import Dialog from '../../containers/Dialog/Dialog';
import AddNewMemberWrapper from '../../containers/AddNewMember';
import { withTitle } from '../../components/TitleComponent';

import styles from './StartupPage.module.scss';

class StartupPage extends React.Component {
    render() {
        return (
            <PageWrapper>
                <Container>
                    <div className={styles.container}>
                        <Row>
                            <Col md={6}>
                                <LastEvents />
                            </Col>
                            <Col md={6}>
                                <Projects />
                                <MembersCompany />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Dialog
                    name="add_new_member"
                    size="sm"
                    footer={null}
                    header="Добавить сотрудника"
                >
                    <AddNewMemberWrapper />
                </Dialog>
            </PageWrapper>
        );
    }
}

export default withTitle({
    component: StartupPage,
    title: 'Стартовый экран',
});
