import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Hidden, Visible } from 'react-grid-system';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import Menu from '../Mobile/Menu';
import AppStore from '../../stores/AppStore';
import logo from '../../assets/images/logo-icon.png';
import { SITE_URL } from '../../constants';

import styles from './Header.module.scss';

const links = require('../../data/links.json');
const categoryData = require('../../data/data.json');

class HeaderUnAuthorized extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        appStore: PropTypes.instanceOf(AppStore).isRequired,
    };

    // eslint-disable-next-line class-methods-use-this
    get headerLinks() {
        const result = [];
        const linksEntries = Object.entries(links.header);
        linksEntries.forEach((data) => {
            if (data[0] === 'phone') return;
            result.push(data[1]);
        });
        return result;
    }

    // eslint-disable-next-line class-methods-use-this
    get phoneLink() {
        if (
            links
            && links.header
            && links.header.phone
            && links.header.phone.name
            && links.header.phone.name !== ''
            && links.header.phone.link
            && links.header.phone.link !== ''
        ) return links.header.phone;
        console.error(`Номер телефона из json-файла имеет неверный формат: ${JSON.stringify(links.header && links.header.phone)}`);
        return {
            name: '8 (499) 322-39-08',
            link: 'tel:+74993223908',
        };
    }

    // eslint-disable-next-line class-methods-use-this
    get categoryLinks() {
        return Object.values(categoryData);
    }

    handleLoginClick = () => {
        const { history, appStore } = this.props;
        history.push('/login');
        appStore.closeMenu();
    }

    getCategoryLink = (category) => {
        if (
            !category
            || !category.alias
            || !category.alias.length
        ) return `${SITE_URL}groups/error.html`;
        if (
            category.alias && category.alias[1]
        ) {
            return `${SITE_URL}groups/${category.alias[1]}.html`;
        }
        return `${SITE_URL}groups/${category.alias[0]}.html`;
    }

    render() {
        const { headerLinks, categoryLinks, phoneLink } = this;
        return (
            <>
                <Hidden xs sm md>
                    <div className={styles['header-left']}>
                        <a href={SITE_URL} className={styles['header-logo__link']}>
                            <div className={styles['header-logo']}>
                                <div className={styles['header-logo__icon']}>
                                    <img src={logo} alt="Logo" />
                                </div>
                                <div className={styles['header-logo__text']}>
                                    <span>W</span>
                                    <span>h</span>
                                    <span>i</span>
                                    <span>t</span>
                                    <span>e</span>
                                    <span>b</span>
                                    <span>o</span>
                                    <span>x</span>
                                </div>
                            </div>
                        </a>
                        <div className={styles['header-divider']} />
                        <ul className={styles['header-list']}>
                            {
                                headerLinks && headerLinks.length
                                    ? (
                                        headerLinks.map((item) => (
                                            <li
                                                className={styles['header-list__item']}
                                                key={nextId('footer__category')}
                                            >
                                                <a
                                                    href={`${SITE_URL}${item.link}`}
                                                    className={styles['header-list__link']}
                                                >
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))
                                    )
                                    : null
                            }
                        </ul>
                    </div>
                    <div className={styles['header-right']}>
                        <div className={styles['header-phone']}>
                            <a
                                href={phoneLink.link}
                                className={styles['header-phone__link']}
                            >
                                {phoneLink.name}
                            </a>
                        </div>
                        <Link to="/login" className={styles['header-login']}>
                            вход
                        </Link>
                    </div>
                </Hidden>
                <Visible xs sm md>
                    <Menu>
                        <div className={styles['header-top']}>
                            <div className={styles['header-phone']}>
                                <a
                                    href={phoneLink.link}
                                    className={styles['header-phone__link']}
                                >
                                    {phoneLink.name}
                                </a>
                            </div>
                            <div className={styles['header-top__divider']} />
                            <button
                                className={styles['header-login']}
                                type="button"
                                onClick={this.handleLoginClick}
                            >
                                вход
                            </button>
                        </div>
                        <ul className={styles['header-list']}>
                            {
                                categoryLinks && categoryLinks.length
                                    ? categoryLinks.map((category) => (
                                        <li
                                            className={styles['header-list__item']}
                                            key={nextId('header-list__item')}
                                        >
                                            <a
                                                href={this.getCategoryLink(category)}
                                                className={styles['header-list__link']}
                                            >
                                                <img
                                                    className={styles['header-list__link-icon']}
                                                    src={`${SITE_URL}assets/images/icon_${category.id}-hov.svg`}
                                                    alt=""
                                                />
                                                <span>
                                                    {category.caption}
                                                </span>
                                            </a>
                                        </li>
                                    ))
                                    : null
                            }
                        </ul>
                    </Menu>
                </Visible>
            </>
        );
    }
}

export default inject('appStore')(
    withRouter(HeaderUnAuthorized),
);
