/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import Tooltip from 'react-tooltip-lite';
import RequestStore from '../../stores/RequestStore';
import { CheckMarkIcon, CircleIcon } from '../../components/SvgIcons';

import styles from './RequestDeadlines.module.scss';
import stylesBasic from './Request.module.scss';

const STATUS_LIST = {
    FAILED: 0,
    SUCCESS: 1,
    DEFAULT: 2,
};

const DeadLineItem = (item) => {
    const icon = item.status === STATUS_LIST.SUCCESS
        ? (
            <CheckMarkIcon />
        ) : (
            <CircleIcon />
        );
    return (
        <div
            key={nextId('request_deadline')}
            className={`
                ${styles.deadlines__item}
                ${item.status === STATUS_LIST.SUCCESS ? styles['deadlines__item--success'] : ''}
                ${item.status === STATUS_LIST.FAILED ? styles['deadlines__item--failed'] : ''}
                ${item.status === STATUS_LIST.DEFAULT ? styles['deadlines__item--normal'] : ''}
            `}
        >
            <Tooltip content={item.hint}>
                <div
                    className={`
                        ${styles['deadlines__item-icon']}
                    `}
                >
                    {icon}
                </div>
                <div
                    className={styles['deadlines__item-info']}
                >
                    <div
                        className={styles['deadlines__item-date']}
                    >
                        {item.date}
                    </div>
                    <div
                        className={styles['deadlines__item-title']}
                    >
                        {item.title}
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};

class RequestDeadlines extends React.Component {
    static propTypes = {
        requestStore: PropTypes.instanceOf(RequestStore).isRequired,
    };

    render() {
        const { requestStore } = this.props;
        const { deadlines } = requestStore;
        return (
            <>
                {
                    deadlines && deadlines.length
                        ? (
                            <div
                                className={styles.deadlines}
                            >
                                <div className={styles.deadlines__inner}>
                                    {
                                        deadlines.map((item) => (
                                            <DeadLineItem
                                                key={nextId('request_deadline')}
                                                {...item}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        )
                        : (
                            <div className={stylesBasic.empty}>
                                <div className={stylesBasic.empty__inner}>
                                    Дедлайнов еще нет
                                    <br />
                                    (по идее не должно быть такой ситуации)
                                </div>
                            </div>
                        )
                }
            </>
        );
    }
}

export default inject('requestStore')(RequestDeadlines);
