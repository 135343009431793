import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import { PageWrapper } from '../../containers/PageWrapper';
import InvoiceList from '../../components/InvoiceList/InvoiceList';
import InvoiceListStore from '../../stores/InvoiceListStore';
import { withTitle } from '../../components/TitleComponent';

class InvoiceListPage extends React.Component {
    static propTypes = {
        invoiceListStore: PropTypes.instanceOf(InvoiceListStore).isRequired,
    };

    render() {
        const { invoiceListStore } = this.props;
        const {
            list,
            loading,
            pagination,
        } = invoiceListStore;
        return (
            <PageWrapper>
                <Container>
                    <Row>
                        <Col md={1} />
                        <Col md={10}>
                            <InvoiceList
                                list={list}
                                loading={loading}
                                pagination={pagination}
                                handlePaginationChange={
                                    (page) => invoiceListStore.handlePaginationChange(page)
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            </PageWrapper>
        );
    }
}

export default inject('invoiceListStore')(
    withTitle({
        component: observer(InvoiceListPage),
        title: 'Страница счетов',
    }),
);
