import { decorate, action, observable } from 'mobx';
import PaginationModel from '../Pagination';
import { getItem } from '../../utils/localStorage';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import { historyQuery } from './queries';

const DEFAULT_COUNT_PER_PAGE = 20;

class LastEventsModel {
    loading = false;

    data = []

    constructor() {
        this.pagination = new PaginationModel({
            activePage: 1,
            handleChange: this.handlePaginationChange,
            itemsCountPerPage: DEFAULT_COUNT_PER_PAGE,
            totalItemsCount: this.data.length,
            pageRangeDisplayed: 7,
        });
    }

    handlePaginationChange(page) {
        this.pagination.update({
            activePage: page,
        });
        this.getData();
    }

    init() {
        this.getData();
    }

    getData() {
        const { pagination: { activePage } } = this;
        this.loading = true;
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: historyQuery(activePage, 15),
            },
        }).then((result) => {
            if (
                result
                && result.res
                && result.res.data
                && result.res.data.history
                && result.res.data.history.data
                && result.res.data.history.paginatorInfo
            ) {
                this.setData(result.res.data.history);
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    setData(newState) {
        const { data, paginatorInfo } = newState;
        this.data = data;
        this.pagination.update({
            totalItemsCount: DEFAULT_COUNT_PER_PAGE * paginatorInfo.lastPage,
        });
    }

    reset() {
        this.loading = false;
        this.data = [];
    }
}

decorate(LastEventsModel, {
    loading: observable,
    data: observable,
    setData: action,
});

export default LastEventsModel;
