import prepareQueryOptions from '../../utils/graphql';

const requestInfo = (id) => `
    query{
        request(
            id: ${id}
        ) {
            id
            date
            caption
            text
            project {
                name
            }
            requeststatus {
                id
                caption
                text
                colorscheme {
                    textcolor
                }
            }
            params {
                id
                intval
                varcharval
                requestparamtype {
                    id
                    caption
                }
            }
            milestones {
                id
                status_id
                caption
                text
                date
            }
            files {
                id
                uid
                filename
            }
            checklist
        }
    }

`;

const requestMessages = (requestId, toId) => {
    const options = prepareQueryOptions({
        request_id: requestId,
        to_id: toId,
    });
    return `query { chatmessages${options} { id message datetime user { firstname id } } }`;
};

const sendMessageMutation = 'mutation mySendMessage( $message: String $request_id: ID! ) { SendMessage( message: $message request_id: $request_id ) { id } }';

export default requestInfo;

export {
    requestInfo,
    requestMessages,
    sendMessageMutation,
};
