import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../Loader';

import styles from './PageLoader.module.scss';

const LoadingInnerStyled = styled.div`
    &::after {
        min-height: ${(props) => (props.minheight)};
    }
`;

class PageLoader extends React.Component {
    static propTypes = {
        minHeight: PropTypes.number,
    };

    static defaultProps = {
        minHeight: 500,
    };

    render() {
        const { minHeight } = this.props;
        return (
            <div
                className={styles.loading}
            >
                <LoadingInnerStyled
                    className={styles.loading__inner}
                    minheight={`${minHeight}px`}
                >
                    <div
                        className={styles.loading__content}
                    >
                        <Loader
                            color="#457eca"
                            spaceSize={2}
                            widthLine={5}
                            heightLine={20}
                        />
                    </div>
                </LoadingInnerStyled>
            </div>
        );
    }
}

export default PageLoader;
