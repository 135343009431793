import React from 'react';
import { inject } from 'mobx-react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Hidden, Visible } from 'react-grid-system';
import UserDropdown from '../UserDropdown';
import logo from '../../assets/images/logo--blue.png';
import Button from '../../components/Button';
import DialogStore from '../../stores/DialogStore';
import UserStore from '../../stores/UserStore';
import AppStore from '../../stores/AppStore';
import RequestListStore from '../../stores/RequestListStore';
import ProjectListStore from '../../stores/ProjectListStore';
import { Menu } from '../Mobile';

import styles from './Header.module.scss';

class HeaderAuthorized extends React.Component {
    static propTypes = {
        dialogStore: PropTypes.instanceOf(DialogStore).isRequired,
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        appStore: PropTypes.instanceOf(AppStore).isRequired,
        requestListStore: PropTypes.instanceOf(RequestListStore).isRequired,
        projectListStore: PropTypes.instanceOf(ProjectListStore).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    };

    handleNewRequest = () => {
        const { dialogStore, appStore } = this.props;
        appStore.closeMenu();
        dialogStore.show('new_request');
    }

    handleRequestButtonClick = () => {
        const {
            appStore,
            requestListStore,
            projectListStore,
            location,
        } = this.props;
        appStore.closeMenu();
        requestListStore.setActiveStatuses();
        projectListStore.setActiveProjectId(null);
        if (location && location.pathname === '/requests') {
            requestListStore.getFullData();
        }
    }

    render() {
        const { userStore } = this.props;
        return (
            <>
                <Hidden xs sm md>
                    <div className={styles['header-left']}>
                        <Link
                            to="/startup"
                            className={styles['header-logo__link']}
                        >
                            <div className={styles['header-logo']}>
                                <div className={styles['header-logo__icon']}>
                                    <img src={logo} alt="Logo" />
                                </div>
                                <div className={styles['header-logo__text']}>
                                    <span>W</span>
                                    <span>h</span>
                                    <span>i</span>
                                    <span>t</span>
                                    <span>e</span>
                                    <span>b</span>
                                    <span>o</span>
                                    <span>x</span>
                                </div>
                            </div>
                        </Link>
                        <div className={styles['header-divider']} />
                        <ul className={styles['header-list']}>
                            <li className={styles['header-list__item']}>
                                <NavLink
                                    to="/startup"
                                    className={styles['header-list__link']}
                                    activeClassName={styles['header-list__link--active']}
                                >
                                    Стартовый экран
                                </NavLink>
                            </li>
                            <li className={styles['header-list__item']}>
                                <NavLink
                                    to="/requests"
                                    className={styles['header-list__link']}
                                    activeClassName={styles['header-list__link--active']}
                                    onClick={this.handleRequestButtonClick}
                                >
                                    Заявки
                                </NavLink>
                            </li>
                            <li className={styles['header-list__item']}>
                                <NavLink
                                    to="/invoice-list"
                                    className={styles['header-list__link']}
                                    activeClassName={styles['header-list__link--active']}
                                >
                                    Счета
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className={styles['header-right']}>
                        <UserDropdown />
                        <Button
                            theme="with-border"
                            className={styles['header-right__button']}
                            onClick={this.handleNewRequest}
                            fullWidth={false}
                        >
                            Новая заявка +
                        </Button>
                    </div>
                </Hidden>
                <Visible xs sm md>
                    <Menu>
                        <div className={styles['header-top']}>
                            <div className={styles.header__username}>{userStore.userName}</div>
                            <Button
                                theme="with-border"
                                className={styles['header-right__button']}
                                onClick={this.handleNewRequest}
                                fullWidth={false}
                            >
                                Новая заявка +
                            </Button>
                        </div>
                        <ul className={styles['header-list']}>
                            <li className={styles['header-list__item']}>
                                <NavLink
                                    to="/requests"
                                    className={styles['header-list__link']}
                                    activeClassName={styles['header-list__link--active']}
                                >
                                    Заявки
                                </NavLink>
                            </li>
                            <li className={styles['header-list__item']}>
                                <NavLink
                                    to="/invoice-list"
                                    className={styles['header-list__link']}
                                    activeClassName={styles['header-list__link--active']}
                                >
                                    Счета
                                </NavLink>
                            </li>
                        </ul>
                    </Menu>
                </Visible>
            </>
        );
    }
}

export default inject(
    'dialogStore',
    'userStore',
    'appStore',
    'requestListStore',
    'projectListStore',
)(withRouter(HeaderAuthorized));
