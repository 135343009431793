import React from 'react';
import Button from '../Button';

import styles from './RequestList.module.scss';

class WithoutRequests extends React.Component {
    handleClick = () => {
        // TODO add dialog (create first request) opening
        alert('show dialog for create first request');
    }

    render() {
        return (
            <div
                className={styles.empty}
            >
                <div
                    className={styles.empty__inner}
                >
                    <div
                        className={styles.empty__content}
                    >
                        Здесь будет отображаться список
                        <br />
                        заявок, у вас их пока нет
                        <div>
                            <Button
                                theme="with-border"
                                fullWidth={false}
                                className={styles.empty__button}
                                onClick={this.handleClick}
                            >
                                Создать первую заявку +
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithoutRequests;
