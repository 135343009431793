import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import RequestStore from '../../stores/RequestStore';
import { EMPTY_DATA_STRING, GRAPHQL_HOST } from '../../constants';
import {
    DragAndDropIcon,
    DocumentIcon,
    LinkIcon,
} from '../../components/SvgIcons';
import request from '../../utils/request';
import { getItem } from '../../utils/localStorage';
import { downloadFile } from './queries';

import styles from './RequestFiles.module.scss';
import stylesBasic from './Request.module.scss';

const FileDownload = require('js-file-download');

class RequestFiles extends React.Component {
    static propTypes = {
        requestStore: PropTypes.instanceOf(RequestStore).isRequired,
    };

    /**
     * Метод был написан без бэка, пока
     * не соответствует действительности.
     * Нужно будет использовать, когда бэк
     * станет полностью готов
     *
     * @deprecated
     * @param item
     */
    renderDocumentItemOld = (item) => (
        <a
            href={item.link}
            key={nextId('request_file_item')}
            className={styles.files__link}
            target="_blank"
            rel="noopener noreferrer"
            download={item.type === 'document'}
        >
            <span className={styles['files__link-icon']}>
                {
                    item.type === 'document'
                        ? (
                            <DocumentIcon />
                        )
                        : (
                            <LinkIcon />
                        )
                }
            </span>
            {`${item.name}${item.type === 'document' ? `.${item.ext}` : ''}`}
            <span className={styles['files__link-drag']}>
                <DragAndDropIcon
                    width={16}
                    height={11}
                />
            </span>
        </a>
    )

    renderDocumentItem = (item) => (
        <button
            key={nextId('request_file_item')}
            className={styles.files__link}
            type="button"
            onClick={() => { this.handleDownloadFile(item); }}
        >
            <span className={styles['files__link-icon']}>
                <DocumentIcon />
            </span>
            {`${item.filename}`}
            <span className={styles['files__link-drag']}>
                <DragAndDropIcon
                    width={16}
                    height={11}
                />
            </span>
        </button>
    )

    handleDownloadFile = (item) => {
        const { uid, filename } = item;
        const authToken = getItem('accessToken');
        if (!authToken) return;
        request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: downloadFile,
                variables: {
                    uid,
                },
            },
            responseType: 'blob',
        }).then((result) => {
            if (result && result.res) {
                FileDownload(result.res, filename);
            }
        });
    }

    renderDocumentBlock = (data) => (
        data && data.list
            ? (
                <div className={styles.files__section}>
                    <div className={styles['files__section-title']}>
                        <span>
                            {data.title || EMPTY_DATA_STRING}
                        </span>
                    </div>
                    {
                        data.list.map((item) => this.renderDocumentItem(item))
                    }
                </div>
            )
            : null
    );

    render() {
        const { requestStore } = this.props;
        const { files } = requestStore;
        return (
            <>
                {
                    files && files.length
                        ? (
                            <div
                                className={styles.files}
                            >
                                {
                                    files.map((item) => this.renderDocumentItem(item))
                                }
                            </div>
                        )
                        : (
                            <div className={stylesBasic.empty}>
                                <div className={stylesBasic.empty__inner}>
                                    Файлов задачи еще нет
                                </div>
                            </div>
                        )
                }
            </>
        );
    }
}

export default inject(
    'requestStore',
)(
    observer(RequestFiles),
);
