import nextId from 'react-id-generator';
import Form from '../Form/FormModel';

class FirstStepForm extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [
                {
                    name: 'name',
                    label: 'Имя',
                    rules: 'required',
                    value: '',
                    id: nextId(),
                },
                {
                    name: 'phone',
                    label: 'Номер телефона',
                    rules: 'required',
                    value: '',
                    id: nextId(),
                },
                {
                    name: 'email',
                    label: 'Почта',
                    placeholder: 'username@example.com',
                    rules: 'required|email|string',
                    value: '',
                    id: nextId(),
                },
                {
                    name: 'captcha_token',
                    label: '',
                    placeholder: '',
                    rules: 'required|string',
                    value: '',
                    id: nextId(),
                },
                {
                    name: 'privacy-policy',
                    rules: 'required|privacyPolicy',
                    value: 0,
                    id: nextId(),
                },
            ],
        };
    }
}

export default FirstStepForm;
