import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import styled from 'styled-components';
import RequestListStore, { LIST_ACTIVE_STATUSES } from '../../stores/RequestListStore';
import EmailIcon from '../SvgIcons/entities/EmailIcon';
import StatusListStore from '../../stores/StatusListStore';

import styles from './StatusFilter.module.scss';

const Button = styled.button`
    color: ${(props) => (props.color)};

    &.button--active {
        position: relative;
        color: #fff;
        background-color: ${(props) => (props.color)};

        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -18px;
            width: 4px;
            background-color: ${(props) => (props.color)};
            content: '';
        }
    }
`;

/**
 * Метод проверяет равенство двух массивов
 *
 * @param {Array} a первый массив
 * @param {Array} b второй массив
 * @returns {boolean} одинаковы ли массивы
 */
const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    // Склонируем первоначальные массивы
    const clonedA = [...a];
    const clonedB = [...b];
    // Отсортируем массивы по порядку
    const sortedA = clonedA.sort((aItem, bItem) => aItem - bItem);
    const sortedB = clonedB.sort((aItem, bItem) => aItem - bItem);
    // Сравним отдельные элементы массивов по порядку
    for (let i = 0; i < a.length; i += 1) {
        if (sortedA[i] !== sortedB[i]) return false;
    }
    return true;
};

/**
 * Метод для определения, активен статус или нет
 *
 * @param {Array} activeStatusesId массив активных статусов
 * @param {number} statusId id статуса для проверки
 * @returns {boolean} состояние активности статуса
 */
const statusIsActive = (activeStatusesId, statusId) => {
    const ALL_ACTIVE_STATUS_ID = 0;
    const allActiveSelected = arraysEqual(activeStatusesId, LIST_ACTIVE_STATUSES);
    if (statusId === ALL_ACTIVE_STATUS_ID) return allActiveSelected;
    return activeStatusesId.indexOf(statusId) > -1;
};

class StatusFilter extends React.Component {
    static propTypes = {
        requestListStore: PropTypes.instanceOf(RequestListStore).isRequired,
        statusListStore: PropTypes.instanceOf(StatusListStore).isRequired,
        onFilterChange: PropTypes.func.isRequired,
    };

    render() {
        const { requestListStore, statusListStore, onFilterChange } = this.props;
        const { loadingStatuses } = statusListStore;
        const { activeStatusesId, statusList, loadingSummary } = requestListStore;
        const loading = loadingSummary || loadingStatuses;
        console.log('loading', loading);
        return (
            <div
                className={styles.container}
            >
                <h3 className={styles.title}>Статусы заявок</h3>
                <ul className={styles.list}>
                    {
                        statusList && statusList.length
                            ? (
                                statusList.map((status) => (
                                    <li
                                        className={styles.item}
                                        key={nextId('status_item')}
                                    >
                                        <Button
                                            type="button"
                                            className={`
                                                ${styles.button}
                                                ${styles[`button--${status.theme}`]}
                                                ${statusIsActive(activeStatusesId, status.id) ? 'button--active' : ''}
                                            `}
                                            color={`#${status.theme}`}
                                            onClick={(event) => onFilterChange(status.id, event)}
                                        >
                                            {status.label}
                                            {
                                                status.qty && status.qty !== 0
                                                    ? (<span>{` (${status.qty})`}</span>)
                                                    : null
                                            }
                                            {
                                                status.hasUnread === true
                                                    ? (
                                                        <span className={styles.icon}>
                                                            <EmailIcon width={17} height={17} color="currentColor" />
                                                        </span>
                                                    )
                                                    : null
                                            }
                                        </Button>
                                    </li>
                                ))
                            )
                            : null
                    }
                </ul>
            </div>
        );
    }
}

export default inject(
    'requestListStore',
    'statusListStore',
)(observer(StatusFilter));
