import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import UserStore from '../../stores/UserStore';
import RequestListStore from '../../stores/RequestListStore';
import ProjectListStore from '../../stores/ProjectListStore';
import eventEmitter from '../../utils/eventEmitter';
import { AUTH_STATES } from '../../constants';
import PageLoader from '../../components/PageLoader';
import MembersCompanyStore from '../../stores/MembersCompany';
import LastEventsModel from '../../stores/LastEvents';
import InvoiceListStore from '../../stores/InvoiceListStore';
import StatusListStore from '../../stores/StatusListStore';

class NeedAuthWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        requestListStore: PropTypes.instanceOf(RequestListStore).isRequired,
        projectListStore: PropTypes.instanceOf(ProjectListStore).isRequired,
        membersStore: PropTypes.instanceOf(MembersCompanyStore).isRequired,
        lastEvents: PropTypes.instanceOf(LastEventsModel).isRequired,
        invoiceListStore: PropTypes.instanceOf(InvoiceListStore).isRequired,
        statusListStore: PropTypes.instanceOf(StatusListStore).isRequired,
    };

    componentDidMount() {
        const { userStore } = this.props;
        eventEmitter.on('user:unauthorized', this.handleUnAuthorized);
        eventEmitter.on('user:authorized', this.handleUserAuthorized);
        userStore.init();
    }

    componentWillUnmount() {
        eventEmitter.off('user:unauthorized', this.handleUnAuthorized);
        eventEmitter.off('user:authorized', this.handleUserAuthorized);
    }

    handleUnAuthorized = () => {
        // Этот метод должен быть идентичен методу
        // handleLogOut в компоненте UserDropdown
        const {
            userStore,
            requestListStore,
            projectListStore,
            membersStore,
            lastEvents,
            invoiceListStore,
            statusListStore,
        } = this.props;
        userStore.logOut();
        requestListStore.reset();
        projectListStore.reset();
        membersStore.reset();
        lastEvents.reset();
        invoiceListStore.reset();
        statusListStore.reset();
    }

    handleUserAuthorized = () => {
        const {
            projectListStore,
            membersStore,
            lastEvents,
            invoiceListStore,
            statusListStore,
        } = this.props;
        statusListStore.init();
        projectListStore.init();
        membersStore.init();
        lastEvents.init();
        invoiceListStore.init();
    }

    render() {
        const { children, userStore } = this.props;
        const { isAuthorized } = userStore;
        if (isAuthorized === AUTH_STATES.unauthorized) {
            return <Redirect to="/login" />;
        }
        if (isAuthorized === AUTH_STATES.undefined) {
            return (<PageLoader />);
        }
        return (children);
    }
}

export default inject(
    'userStore',
    'requestListStore',
    'projectListStore',
    'membersStore',
    'lastEvents',
    'invoiceListStore',
    'statusListStore',
)(observer(NeedAuthWrapper));
