/* global document */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import ArrowDownIcon from '../../components/SvgIcons/entities/ArrowDownIcon';
import UserStore from '../../stores/UserStore';
import RequestListStore from '../../stores/RequestListStore';
import ProjectListStore from '../../stores/ProjectListStore';
import MembersCompanyStore from '../../stores/MembersCompany';
import LastEventsModel from '../../stores/LastEvents';

import styles from './UserDropdown.module.scss';

class UserDropdown extends React.Component {
    refToggler = null;

    refContainerInner = null;

    static propTypes = {
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        requestListStore: PropTypes.instanceOf(RequestListStore).isRequired,
        projectListStore: PropTypes.instanceOf(ProjectListStore).isRequired,
        membersStore: PropTypes.instanceOf(MembersCompanyStore).isRequired,
        lastEvents: PropTypes.instanceOf(LastEventsModel).isRequired,
    };

    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        this.refToggler.addEventListener('click', this.handleTogglerClick);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        this.refToggler.removeEventListener('click', this.handleTogglerClick);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleTogglerClick = () => {
        this.isOpen = !this.isOpen;
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }

    handleClickOutside = (event) => {
        if (
            this.refToggler
            && !this.refToggler.contains(event.target)
            && this.refContainerInner
            && !this.refContainerInner.contains(event.target)
        ) {
            this.setState({ isOpen: false });
        }
    }

    handleLogOut = () => {
        // Этот метод должен быть идентичен методу
        // handleUnAuthorized в компоненте NeedAuthWrapper
        const {
            userStore,
            requestListStore,
            projectListStore,
            membersStore,
            lastEvents,
        } = this.props;
        userStore.logOut();
        requestListStore.reset();
        projectListStore.reset();
        membersStore.reset();
        lastEvents.reset();
    }

    render() {
        const { isOpen } = this.state;
        const { userStore } = this.props;
        return (
            <div
                className={`
                    ${styles.container}
                    ${isOpen === true ? styles['container--open'] : ''}
                `}
            >
                <button
                    type="button"
                    ref={(ref) => { this.refToggler = ref; }}
                    className={`
                        ${styles.toggler}
                    `}
                >
                    <div className={styles.toggler__name}>
                        {userStore.userName}
                    </div>
                    <div
                        className={styles.toggler__icon}
                    >
                        <ArrowDownIcon
                            color="currentColor"
                        />
                    </div>
                </button>
                <div
                    ref={(ref) => { this.refContainerInner = ref; }}
                    className={`
                        ${styles.container__inner}
                    `}
                >
                    <button
                        type="button"
                        className={styles.out}
                        onClick={this.handleLogOut}
                    >
                        Выйти
                    </button>
                </div>
            </div>
        );
    }
}

export default inject(
    'userStore',
    'requestListStore',
    'projectListStore',
    'membersStore',
    'lastEvents',
)(observer(UserDropdown));
