import React from 'react';
import PropTypes from 'prop-types';
import { UserIcon } from '../SvgIcons';

import styles from './Input.module.scss';

class Input extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['text', 'number', 'password', 'hidden']),
        icon: PropTypes.node,
        withIcon: PropTypes.bool,
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        error: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        autoComplete: PropTypes.oneOf(['on', 'off']),
        placeholder: PropTypes.string,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        isValid: PropTypes.bool,
        multiline: PropTypes.bool,
    };

    static defaultProps = {
        type: 'text',
        icon: <UserIcon color="currentColor" />,
        withIcon: true,
        label: '',
        error: '',
        onChange: null,
        onBlur: null,
        autoComplete: 'off',
        placeholder: '',
        value: '',
        disabled: false,
        readOnly: false,
        isValid: false,
        multiline: false,
    };

    render() {
        const {
            type,
            icon,
            withIcon,
            id,
            label,
            error,
            onBlur,
            onChange,
            autoComplete,
            placeholder,
            value,
            disabled,
            readOnly,
            isValid,
            multiline,
            ...rest
        } = this.props;
        return (
            <div
                className={`
                    ${styles.input__container}
                    ${error && error !== '' ? styles['input__container--error'] : ''}
                    ${type === 'hidden' ? styles['input__container--hidden'] : ''}
                `}
            >
                {
                    multiline === true
                        ? (
                            <textarea
                                type={type}
                                id={id}
                                onBlur={onBlur}
                                onChange={onChange}
                                autoComplete={autoComplete}
                                placeholder={placeholder}
                                value={value}
                                disabled={disabled}
                                readOnly={readOnly}
                                className={styles.input}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...rest}
                            />
                        )
                        : (
                            <input
                                type={type}
                                id={id}
                                onBlur={onBlur}
                                onChange={onChange}
                                autoComplete={autoComplete}
                                placeholder={placeholder}
                                value={value}
                                disabled={disabled}
                                readOnly={readOnly}
                                className={styles.input}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...rest}
                            />
                        )
                }
                <label
                    className={styles.input__label}
                    htmlFor={id}
                >
                    {
                        withIcon
                            ? (
                                <span
                                    className={styles.input__icon}
                                >
                                    {icon}
                                </span>
                            )
                            : null
                    }
                    {label}
                </label>
                {
                    error && error !== ''
                        ? (
                            <div className={styles.input__error}>{error}</div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default Input;
