/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactJsPagination from 'react-js-pagination';
import { ThinArrow } from '../SvgIcons';

import styles from './Pagination.module.scss';

class Pagination extends React.Component {
    static propTypes = {
        activePage: PropTypes.number.isRequired,
        lastPage: PropTypes.number.isRequired,
        handlePageChange: PropTypes.func.isRequired,
        itemsCountPerPage: PropTypes.number.isRequired,
        totalItemsCount: PropTypes.number.isRequired,
        pageRangeDisplayed: PropTypes.number.isRequired,
    }

    /**
     * Method called onChange event in
     * ReactJsPagination component
     *
     * @param {number} page page from input
     */
    onPageChange = (page) => {
        const {
            handlePageChange,
        } = this.props;
        handlePageChange(page);
    }

    render() {
        const {
            activePage,
            lastPage,
            itemsCountPerPage,
            totalItemsCount,
            pageRangeDisplayed,
        } = this.props;
        const hideFirstLastPages = lastPage <= pageRangeDisplayed; // 7 by default
        const hideNavigation = lastPage <= 14;
        return (
            <>
                {
                    totalItemsCount === 0
                        ? null
                        : (
                            <>
                                <ReactJsPagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    pageRangeDisplayed={pageRangeDisplayed}
                                    onChange={this.onPageChange}
                                    prevPageText={(<ThinArrow />)}
                                    nextPageText={(<ThinArrow reverse />)}
                                    firstPageText={(
                                        <>
                                            <ThinArrow />
                                            <ThinArrow />
                                        </>
                                    )}
                                    lastPageText={(
                                        <>
                                            <ThinArrow reverse />
                                            <ThinArrow reverse />
                                        </>
                                    )}
                                    innerClass={styles.pagination}
                                    itemClass={styles.pagination__item}
                                    itemClassFirst={styles['pagination__item--first']}
                                    itemClassLast={styles['pagination__item--last']}
                                    itemClassPrev={styles['pagination__item--prev']}
                                    itemClassNext={styles['pagination__item--next']}
                                    disabledClass={styles.disabled}
                                    activeClass={styles.active}
                                    hideNavigation={hideNavigation}
                                    hideFirstLastPages={hideFirstLastPages}
                                />
                            </>
                        )
                }
            </>
        );
    }
}

export default observer(Pagination);
