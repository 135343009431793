import React from 'react';
import PropTypes from 'prop-types';

const EmailIcon = ({
    width,
    height,
    color,
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.33333 3H13C13.7333 3 14.3333 3.6 14.3333 4.33333V12.3333C14.3333 13.0667 13.7333 13.6667 13 13.6667H2.33333C1.6 13.6667 1 13.0667 1 12.3333V4.33333C1 3.6 1.6 3 2.33333 3Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 4L7.5 9L1 4"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

EmailIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

EmailIcon.defaultProps = {
    width: 16,
    height: 16,
    color: '#457eca',
};

export default EmailIcon;
