import React from 'react';
import PropTypes from 'prop-types';
import RequestEdit from './RequestEdit';
import RequestEditForm from '../../stores/RequestEditForm';

class RequestEditWrapper extends React.Component {
    static propTypes = {
        handleSuccess: PropTypes.func.isRequired,
        initial: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            }),
        ).isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const { handleSuccess, initial, loading } = this.props;
        const requestEditForm = new RequestEditForm({
            hooks: {
                onSuccess: (form) => handleSuccess(form),
            },
            fields: initial,
        });
        return <RequestEdit loading={loading} form={requestEditForm} />;
    }
}

export default RequestEditWrapper;
