import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import { SITE_URL } from '../../constants';

import styles from './Footer.module.scss';

const FooterCategory = ({
    id,
    caption,
    alias,
    services,
}) => (
    <div className={styles['footer__grid-category']}>
        <a
            className={styles['footer__item-heading']}
            href={`${SITE_URL}groups/${alias[1]}.html`}
        >
            <img
                className={`${styles.icon} ${styles['icon--default']}`}
                src={`${SITE_URL}assets/images/icon_${id}-w.svg`}
                alt=""
            />
            {caption}
        </a>
        <ul className={styles['footer__item-list']}>
            {
                services && services.length
                    ? (
                        services.map((service) => (
                            <li
                                key={nextId('footer__item-list-element')}
                            >
                                <a
                                    href={service.link}
                                    title={service.caption}
                                    className={`${service.expand === true ? styles.expand : ''}`}
                                >
                                    {service.caption}
                                </a>
                            </li>
                        ))
                    )
                    : null
            }
        </ul>
    </div>
);

FooterCategory.propTypes = {
    id: PropTypes.number.isRequired,
    caption: PropTypes.string.isRequired,
    alias: PropTypes.arrayOf(PropTypes.string),
    services: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            title: PropTypes.string,
        }),
    ),
};

FooterCategory.defaultProps = {
    alias: [],
    services: [],
};

export default FooterCategory;
