import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { PageWrapper } from '../../containers/PageWrapper';
import Sidebar from '../../containers/Sidebar';
import RequestList from '../../components/RequestList';
import RequestListStore from '../../stores/RequestListStore';
import { getItem } from '../../utils/localStorage';
import request from '../../utils/request';
import { GRAPHQL_HOST } from '../../constants';
import { requestReorder } from './queries';
import { withTitle } from '../../components/TitleComponent';

import styles from './RequestListPage.module.scss';

class RequestListPage extends React.Component {
    static propTypes = {
        requestListStore: PropTypes.instanceOf(RequestListStore).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    onDragEnd = (dragged, {
        previousItem,
        nextItem,
    }) => {
        const { requestListStore } = this.props;
        const authToken = getItem('accessToken');
        if (!authToken) return;
        const variables = {
            id: Number(dragged.id),
            after: null,
        };
        if (previousItem && previousItem.id) {
            variables.after = Number(previousItem.id);
        } else {
            variables.after = -Number(nextItem.id);
        }
        requestListStore.setPendingOrderRequest(dragged);
        request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: requestReorder,
                variables,
            },
        }).finally(() => {
            requestListStore.setPendingOrderRequest(null);
        });
    }

    render() {
        const { requestListStore } = this.props;
        const {
            list,
            projectVisibility,
            withoutRequests,
            loading,
            pendingOrderRequest,
        } = requestListStore;
        return (
            <PageWrapper>
                <Container>
                    <div className={styles.container}>
                        <Row>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                            <Col md={9}>
                                <RequestList
                                    list={list}
                                    projectVisibility={projectVisibility}
                                    withoutRequests={withoutRequests}
                                    loading={loading}
                                    onDragEnd={this.onDragEnd}
                                    pendingOrderRequest={pendingOrderRequest}
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </PageWrapper>
        );
    }
}

export default inject(
    'requestListStore',
)(
    withTitle({
        component: observer(RequestListPage),
        title: 'Список заявок',
    }),
);
