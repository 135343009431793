import React from 'react';
import PropTypes from 'prop-types';

import styles from './EmptyState.module.scss';

class EmptyState extends React.Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
    };

    render() {
        const { text } = this.props;
        return (
            <div
                className={styles.empty}
            >
                <div
                    className={styles.empty__inner}
                >
                    <div
                        className={styles.empty__content}
                    >
                        {text}
                    </div>
                </div>
            </div>
        );
    }
}

export default EmptyState;
