import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import RequestChatStore from '../../stores/RequestStore/RequestChat';
import RequestChatLoader from './RequestChatLoader';
import RequestChatSocketError from './RequestChatSocketError';
import RequestChatContent from './RequestChatContent';
import socket from '../../utils/socket';

import styles from './RequestChat.module.scss';

class RequestChat extends React.Component {
    static propTypes = {
        requestChatStore: PropTypes.instanceOf(RequestChatStore).isRequired,
    };

    componentWillUnmount() {
        const { requestChatStore } = this.props;
        requestChatStore.reset();
    }

    handleRetrySocket = () => {
        const { requestChatStore } = this.props;
        const cb = {
            onOpen: () => {
                requestChatStore.reInit();
            },
        };
        socket.reConnection(cb);
    }

    render() {
        const { requestChatStore } = this.props;
        const {
            loading,
            socketError,
        } = requestChatStore;
        return (
            <div
                className={styles.chat}
            >
                {/* Отобразим прелоадер, если идёт загрузка */}
                {
                    loading === true ? (<RequestChatLoader />) : null
                }
                {/* Отобразим ошибку, если нет загрузки данных */}
                {
                    loading === false
                    && socketError === true
                        ? (
                            <RequestChatSocketError
                                onClick={this.handleRetrySocket}
                            />
                        )
                        : null
                }
                {/* Отобразим контентную часть, если нет загрузки и нет ошибки */}
                {
                    loading === false
                    && socketError === false
                        ? (<RequestChatContent />)
                        : null
                }
            </div>
        );
    }
}

export default inject(
    'requestChatStore',
)(
    observer(RequestChat),
);
