import React from 'react';
import PropTypes from 'prop-types';

const CheckMarkIcon = ({
    width,
    height,
    color,
}) => (
    <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 15 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="3.72461"
            y="9.57977"
            width="12.8916"
            height="1.75795"
            transform="rotate(-45 3.72461 9.57977)"
            fill={color}
        />
        <rect
            x="1.24316"
            y="4.6106"
            width="6.42841"
            height="1.75795"
            transform="rotate(45 1.24316 4.6106)"
            fill={color}
        />
    </svg>
);

CheckMarkIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

CheckMarkIcon.defaultProps = {
    width: 15,
    height: 11,
    color: '#38c8a8',
};

export default CheckMarkIcon;
