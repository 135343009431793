import { isEmptyObj, getCleanObj } from '.';

const prepareQueryOptions = (obj) => {
    if (!obj || isEmptyObj(obj)) return '';
    const cleanObj = getCleanObj(obj);
    if (isEmptyObj(cleanObj)) return '';
    let str = '(';
    Object.keys(cleanObj).forEach((item) => {
        str += `${item}: ${cleanObj[item]}`;
    });
    str += ')';
    return str;
};

export default prepareQueryOptions;
