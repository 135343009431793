const invoicesQuery = `
    query ($page: Int) {
        invoices (page: $page) {
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
            data  {
                id
                createdate
                docnumber
                docdate
                sum
                paid
                project {
                    name
                }
                duedate
                uid
                text
                invoicestatus {
                    name
                    color
                }
            }
        }
    }
`;

export default invoicesQuery;

export {
    invoicesQuery,
};
