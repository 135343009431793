import React from 'react';
import PropTypes from 'prop-types';

import styles from './Checkbox.module.scss';

class Checkbox extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]).isRequired,
        error: PropTypes.string,
        isValid: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onBlur: PropTypes.func,
        disabled: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    };

    static defaultProps = {
        error: null,
        isValid: false,
        onChange: null,
        onBlur: null,
        onClick: null,
        disabled: false,
        value: '',
    };

    handleChange = (e) => {
        const { onChange, onClick } = this.props;
        e.target.value = Number(e.target.checked);
        if (onClick) onClick(e);
        if (onChange) onChange(e);
    }

    render() {
        const {
            id,
            children,
            error,
            isValid,
            onBlur,
            onChange,
            disabled,
            value,
            ...rest
        } = this.props;
        return (
            <div
                className={`
                    ${styles.checkbox__container}
                `}
            >
                <input
                    type="checkbox"
                    className={styles.checkbox}
                    id={id}
                    disabled={disabled}
                    onBlur={onBlur}
                    onChange={this.handleChange}
                    value={value}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                />
                <label htmlFor={id} className={styles.checkbox__label}>
                    {children}
                </label>
                {
                    error
                        ? (
                            <div className={styles.checkbox__error}>{error}</div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default Checkbox;
