import React from 'react';
import PropTypes from 'prop-types';
import LoginFormComponent from './LoginFormComponent';
import LoginForm from '../../stores/LoginForm';

class LoginFormWrapper extends React.Component {
    static propTypes = {
        handleSuccess: PropTypes.func.isRequired,
    };

    render() {
        const { handleSuccess } = this.props;
        const loginForm = new LoginForm({
            hooks: {
                onSuccess: (form) => handleSuccess(form),
            },
        });
        return (
            <LoginFormComponent
                form={loginForm}
            />
        );
    }
}

export default LoginFormWrapper;
