import { decorate, observable, computed } from 'mobx';
import moment from 'moment';

class RequestListItem {
    constructor(data) {
        this.id = data.id;
        this.caption = data.caption;
        this.order = data.order;
        this.project = data.project;
        this.params = data.params;
        this.milestones = data.milestones;
        this.requeststatus = data.requeststatus;
    }

    id = null;

    caption = null;

    order = null;

    project = null;

    params = [];

    milestones = [];

    /**
     * Получение параметра по его имени
     *
     * @param {string} caption имя
     * @returns {object} объект параметра
     */
    getParamByCaption(caption) {
        if (!this.params || !this.params.length) return null;
        return this.params.find(
            (param) => param.requestparamtype.caption === caption,
        );
    }

    /**
     * Цена за заявку
     *
     * @returns {string} цена
     */
    get price() {
        const priceParam = this.getParamByCaption('Цена');
        return (priceParam && priceParam.varcharval) || null;
    }

    /**
     * Кол-во времени для выполнения
     *
     * @returns {string} время в формате 10ч 10м
     */
    get workingHours() {
        const workingHoursParam = this.getParamByCaption('Оценка времени');
        const result = null;
        if (
            workingHoursParam
            && workingHoursParam.varcharval
        ) {
            const hours = Math.floor(Number(workingHoursParam.varcharval) / 60);
            const minutes = Math.floor(Number(workingHoursParam.varcharval)) - (hours * 60);
            return `${hours ? `${hours} ч` : ''} ${minutes ? ` ${minutes} м` : ''}`;
        }
        return result;
    }

    /**
     * Последний актуальный дедлайн
     *
     * @returns {object} дедлайн
     */
    get lastMileStone() {
        if (!this.milestones || !this.milestones.length) return null;
        let result = {};
        this.milestones.forEach((milestone) => {
            const resultId = result.id && result.id !== '0' ? Number(result.id) : 0;
            const { date } = milestone;
            // Это условие отображает дедлайн с истёкшим сроком
            // TODO протестировать работу этого условия
            if (
                milestone.status_id === 1
                && moment().isAfter(date)
            ) {
                result = milestone;
                return;
            }
            // Это условие отображает последний активный дедлайн
            if (
                milestone.status_id === 1
                && Number(milestone.id) > (Number(resultId) || 0)
            ) {
                result = milestone;
            }
        });
        return result;
    }

    get colorItem() {
        return this.requeststatus
            && this.requeststatus.colorscheme
            && this.requeststatus.colorscheme.active_bordercolor
            ? this.requeststatus.colorscheme.active_bordercolor
            : '#000';
    }
}

decorate(RequestListItem, {
    id: observable,
    date: observable,
    caption: observable,
    order: observable,
    project: observable,
    params: observable,
    milestones: observable,
    price: computed,
});

export default RequestListItem;
