import React from 'react';
import PropTypes from 'prop-types';

const TimeCircleIcon = ({
    size,
    color,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M13.5838 2.34843C12.0809 0.838026 10.0826 0.00619507 7.95721 0.00619507C5.8318 0.00619507 3.83351 0.838026 2.33061 2.34843C0.827702 3.85883 0 5.86708 0 8.0031C0 10.1391 0.827702 12.1474 2.33061 13.6578C3.83351 15.1682 5.8318 16 7.95721 16C10.0826 16 12.0809 15.1682 13.5838 13.6578C15.0867 12.1474 15.9144 10.1391 15.9144 8.0031C15.9144 5.86708 15.0867 3.85883 13.5838 2.34843ZM12.9244 12.9951C11.5977 14.3285 9.83359 15.0629 7.95721 15.0629C6.08082 15.0629 4.31675 14.3285 2.99002 12.9951C1.66318 11.6617 0.932485 9.88884 0.932485 8.0031C0.932485 6.11735 1.66318 4.34448 2.99002 3.01113C4.31675 1.67767 6.08082 0.943332 7.95721 0.943332C9.83359 0.943332 11.5977 1.67767 12.9244 3.01113C14.2512 4.34448 14.9819 6.11735 14.9819 8.0031C14.9819 9.88884 14.2512 11.6617 12.9244 12.9951Z"
                fill={color}
            />
            <path
                d="M11.7315 7.53953H9.27726C9.21023 7.34575 9.09986 7.16394 8.94603 7.00934C8.79608 6.85839 8.61796 6.74613 8.42357 6.67707V2.85363C8.42357 2.59482 8.21485 2.38506 7.95733 2.38506C7.6998 2.38506 7.49108 2.59482 7.49108 2.85363V6.6767C7.29657 6.74552 7.11833 6.8579 6.96826 7.00885C6.42322 7.55685 6.42334 8.44872 6.96863 8.99685C7.23283 9.26249 7.58397 9.40892 7.95745 9.40892C8.33093 9.40892 8.68255 9.26274 8.94651 8.99733C9.09768 8.84542 9.20683 8.6669 9.2741 8.47666H11.7315C11.9889 8.47666 12.1977 8.26691 12.1977 8.00809C12.1977 7.74941 11.9889 7.53953 11.7315 7.53953ZM8.28698 8.33487C8.19907 8.42322 8.08227 8.47178 7.95793 8.47178C7.83336 8.47178 7.71619 8.42297 7.62804 8.33438C7.44616 8.15147 7.44604 7.85398 7.62768 7.67131C7.71534 7.58321 7.83178 7.53465 7.95575 7.53428H7.95733H7.9583C8.08227 7.53477 8.19883 7.58345 8.28649 7.6718C8.46837 7.85471 8.46862 8.15208 8.28698 8.33487Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    y="0.00619507"
                    width="15.9144"
                    height="15.9938"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
);

TimeCircleIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

TimeCircleIcon.defaultProps = {
    size: 16,
    color: '#457eca',
};

export default TimeCircleIcon;
