import React from 'react';
import PropTypes from 'prop-types';

const CircleIcon = ({
    size,
    color,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="4.15576"
            cy="4.216"
            r="4"
            fill={color}
        />
    </svg>
);

CircleIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

CircleIcon.defaultProps = {
    size: 9,
    color: 'currentColor',
};

export default CircleIcon;
