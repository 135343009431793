const reqStatusesQuery = `
    query {
        requeststatuses {
            id
            order
            caption
            colorscheme {
                textcolor
            }
        }
    }
`;

const requestsQuery = (statuses, projectId) => (`
    query{
        requests(
            project_id: ${projectId},
            request_status_id: [${statuses}],
        ){
            id
            caption
            order
            requeststatus{
                colorscheme{
                    active_bordercolor
                }
            }
            project{
                name
            }
            params{
            varcharval
            requestparamtype{
                caption
            }
            }
            milestones {
                id
                caption
                date
                status_id
            }
        }
    }
`);

const projectsSummaryQuery = (projectIds) => `
    query{
        projects(ids: ${projectIds}) {
            data {
                id
                name
                requestssummary {
                    requeststatus_id
                    count
                }
            }
        }
    }
`;


export default requestsQuery;

export {
    requestsQuery,
    reqStatusesQuery,
    projectsSummaryQuery,
};
