import {
    decorate,
    observable,
    action,
    computed,
} from 'mobx';
import moment from 'moment';
import { getItem } from '../../utils/localStorage';
import { requestMessages, sendMessageMutation } from './queries';
import socket from '../../utils/socket';
import eventEmitter from '../../utils/eventEmitter';

class RequestChatStore {
    /**
     * Обработчики событий с socket-сервера
     *
     * @deprecated пока не используем, но возможно
     * пригодится в будущем
     */
    listeners = {};

    /** ID заявки */
    requestId = null;

    /**
     * Состояние получения данных
     * с socket-сервера
     */
    loading = false;

    /** Идёт ли получение сообщений */
    loadingMessage = false;

    /**
     * Есть ли ошибка с подключением к
     * сокетам
     */
    socketError = false;

    /** Сообщения по заявке */
    _messages = [];

    /** ID сообщения до которого запрашивать данные */
    actualTargetMessageId = null;

    /** ID сообщения до которого запрашивались данные ранее */
    lastTargetMessageId = null;

    constructor() {
        // Установим статус ошибки, если сокеты не работают
        eventEmitter.on('ws:error', () => {
            this.socketError = true;
            this.loadingMessage = false;
        });
        eventEmitter.on('ws:closed', () => {
            this.socketError = true;
            this.loadingMessage = false;
        });
        // Установим нормальный статус сокетов, если они работают
        eventEmitter.on('ws:connected', () => {
            this.socketError = false;
            this.loadingMessage = false;
        });
    }

    /**
     * Метод вызывается при получение данных
     * из сокетов
     *
     * @param {object} data -
     * @param {string} data.uid уникальный идентификатор запроса
     * (используется для унификации запроса от сокетов, используется
     * в рамках одного запроса)
     * @param {string} data.response строковое представление ответа,
     * нуждается в доп. преобразовании через JSON.parse метод
     * @deprecated сейчас подпись на обновлени данных
     * происходит внутри этой модели
     */
    handleSocketUpdate(data) {
        if (
            data
            // && this.listeners
            // && data.uid
        ) {
            // const targetMethod = this.listeners[data.uid];
            // if (targetMethod) {
            //     targetMethod(JSON.parse(data.response));
            // }
            this.addNewMessages(data);
            // this.removeSocketListener(data.uid);
        }
    }

    /**
     * Метод для добавления обработчика события
     * по uid
     *
     * @param {string} uid uid
     * @param {Function} handleFunction метод для выполнения
     * по наступлению события получения uid от
     * socket-сервера
     * @deprecated пока не используем, но возможно
     * пригодится в будущем
     */
    addSocketListener(uid, handleFunction) {
        this.listeners[uid] = handleFunction;
    }

    /**
     * Метод для удаления обработчика
     *
     * @param {string} uid uid
     * @deprecated пока не используем, но возможно
     * пригодится в будущем
     */
    removeSocketListener(uid) {
        delete this.listeners[uid];
    }

    get sortMessageList() {
        const messages = this._messages;
        if (!messages || !messages.length) return [];
        const sortedArray = messages.sort(
            (a, b) => moment(a.datetime).valueOf() - moment(b.datetime).valueOf(),
        );
        return sortedArray;
    }

    get earliestMessage() {
        let earliest = null;
        this._messages.forEach((message) => {
            if (
                !earliest
            ) {
                earliest = message;
            } else {
                const currentEarliestValue = moment(earliest.datetime).valueOf();
                const messageValue = moment(message.datetime).valueOf();
                if (messageValue < currentEarliestValue) earliest = message;
            }
        });
        return earliest;
    }

    /**
     * Метод собирает сообщения по датам
     * в "секции"
     *
     * @returns {object} данные собранные по дате
     * Пример: { 2020-01-01: { list: [...messages] }}
     */
    get messages() {
        const raw = this.sortMessageList;
        const result = {};
        if (!raw || !raw.length) return result;
        raw.forEach((messageItem) => {
            const formattedDate = moment(messageItem.datetime).format('YYYY-MM-DD');
            if (!result[formattedDate] || !result[formattedDate].list) {
                result[formattedDate] = { list: [] };
            }
            if (messageItem.message && messageItem.id) {
                result[formattedDate].list.push(messageItem);
            }
        });
        return result;
    }

    /**
     * Метод для подписки на все необходимые
     * сокетные каналы
     */
    subscribeForAllChannels() {
        const { requestId } = this;
        socket.ws.subscribe(`NewChatMessage/${requestId}`, (_topic, response) => {
            const parsedResponse = JSON.parse(response);
            this.loadingMessage = false;
            if (
                parsedResponse
                && parsedResponse.message
                && parsedResponse.message !== ''
            ) {
                eventEmitter.emit('modal:error', { message: parsedResponse.message });
                return;
            }
            if (
                parsedResponse
                && parsedResponse.errors
                && parsedResponse.errors.length
                && parsedResponse.errors[0].message
            ) {
                eventEmitter.emit('modal:error', { message: parsedResponse.errors[0].message });
                return;
            }
            if (
                parsedResponse
                && parsedResponse.data
            ) {
                this.addNewMessage({
                    ...parsedResponse.data,
                    user: {
                        ...parsedResponse.data.user,
                        id: parsedResponse.data.user_id,
                    },
                });
                eventEmitter.emit('chat:new_message');
            }
        });
        socket.ws.subscribe('GQL', (_topic, response) => {
            const parsedResponse = JSON.parse(response);
            this.loadingMessage = false;
            if (
                parsedResponse
                && parsedResponse.message
                && parsedResponse.message !== ''
            ) {
                eventEmitter.emit('modal:error', { message: parsedResponse.message });
                return;
            }
            if (
                parsedResponse
                && parsedResponse.errors
                && parsedResponse.errors.length
                && parsedResponse.errors[0].message
            ) {
                eventEmitter.emit('modal:error', { message: parsedResponse.errors[0].message });
                return;
            }
            if (
                parsedResponse
                && parsedResponse.data
            ) {
                this.addNewMessages(parsedResponse.data);
                this.lastTargetMessageId = this.actualTargetMessageId;
                eventEmitter.emit('chat:message_loaded');
            }
        });
    }

    /**
     * Инициализация
     *
     * @param {number|string} requestId ID заявки
     */
    init(requestId) {
        this.requestId = requestId;
        this.subscribeForAllChannels();
        this.getData();
    }

    /**
     * Метод для повторной инициализации.
     * Вызывается при пере-подключении сокетного
     * соединения
     */
    reInit() {
        this.subscribeForAllChannels();
        this.getData();
    }

    addNewMessage(data) {
        if (
            data
            && data.message
        ) {
            this._messages.unshift(data);
        }
    }

    addNewMessages(data) {
        if (
            data
            && data.chatmessages
            && data.chatmessages.length
        ) {
            const newState = data.chatmessages.concat(this._messages);
            this._messages = newState;
        }
    }

    /**
     * Метод для получения данных с
     * socket-сервера
     *
     */
    getData() {
        if (this.loadingMessage === true) return;
        const authToken = getItem('accessToken');
        if (!authToken) return;
        // const uid = `0${uuidv4()}`;
        // this.addSocketListener(uid, (response) => this.addNewMessages(response));
        // this.loading = true;
        const { earliestMessage } = this;
        let targetMessageId = null;
        if (earliestMessage) {
            targetMessageId = earliestMessage.id;
            if (this.actualTargetMessageId === targetMessageId) return;
            this.actualTargetMessageId = targetMessageId;
        }
        socket.send({
            query: requestMessages(this.requestId, targetMessageId),
            authToken,
            // uid,
            subscribeName: 'GQL',
        });
        this.loadingMessage = true;
    }

    sendMessage(message) {
        // sendMessageMutation
        const authToken = getItem('accessToken');
        if (!authToken) return;
        socket.send({
            query: sendMessageMutation,
            variables: {
                message,
                request_id: this.requestId,
            },
            authToken,
            subscribeName: 'GQL',
        });
    }

    /**
     * Сброс состояния
     */
    reset() {
        if (
            socket.ws
            && socket.ws._subscriptions
            && socket.ws._subscriptions.GQL
        ) socket.ws.unsubscribe('GQL');
        if (
            socket.ws
            && socket.ws._subscriptions
            && socket.ws._subscriptions[`NewChatMessage/${this.requestId}`]
        ) {
            socket.ws.unsubscribe(`NewChatMessage/${this.requestId}`);
        }
        this.listeners = {};
        this.requestId = null;
        this.loading = false;
        this.loadingMessage = false;
        this._messages = [];
    }
}

decorate(RequestChatStore, {
    // listeners: observable,
    requestId: observable,
    loading: observable,
    loadingMessage: observable,
    socketError: observable,
    _messages: observable,
    actualTargetMessageId: observable,
    messages: computed,
    sortMessageList: computed,
    earliestMessage: computed,
    getData: action,
});

export default RequestChatStore;
