import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';
import Loader from '../Loader';

class Button extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['submit', 'button']),
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]).isRequired,
        theme: PropTypes.oneOf([
            'blue',
            'with-border',
            'with-border-square',
            'green',
        ]),
        fullWidth: PropTypes.bool,
        loading: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        type: 'button',
        theme: 'blue',
        fullWidth: true,
        loading: false,
        className: '',
    };

    render() {
        const {
            type,
            children,
            theme,
            fullWidth,
            loading,
            className,
            ...rest
        } = this.props;
        return (
            // eslint-disable-next-line react/button-has-type
            <button
                type={type}
                className={`
                    ${styles.button}
                    ${styles[`button--${theme}`]}
                    ${fullWidth === true ? styles['button--full-width'] : ''}
                    ${loading === true ? styles.loading : ''}
                    ${className}
                `}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
            >
                {
                    loading === true
                        ? (
                            <Loader />
                        )
                        : (children)
                }
            </button>
        );
    }
}

export default Button;
