import React from 'react';
import PropTypes from 'prop-types';
import ThirdStep from './ThirdStep';
import ThirdStepForm from '../../stores/RegistrationForm/ThirdStepForm';

class ThirdStepWrapper extends React.Component {
    static propTypes = {
        handleSuccess: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const { handleSuccess, loading } = this.props;
        const thirdStepForm = new ThirdStepForm({
            hooks: {
                onSuccess: (form) => handleSuccess(form),
            },
        });
        return <ThirdStep form={thirdStepForm} loading={loading} />;
    }
}

export default ThirdStepWrapper;
