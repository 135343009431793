/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { DragAndDropIcon, RubCircleIcon, TimeCircleIcon } from '../SvgIcons';
import Loader from '../Loader';

import styles from './RequestList.module.scss';

const StyledLink = styled(NavLink)`
    border-left: 4px solid ${(props) => (props.color)} !important;
`;

const RequestItem = ({
    id,
    project,
    price,
    projectVisibility,
    caption,
    workingHours,
    colorItem,
    lastMileStone,
    isNew,
    index,
    isPending,
    pendingOrderRequest,
}) => (
    <Draggable
        draggableId={`request_item-${id}`}
        index={index}
        isDragDisabled={pendingOrderRequest !== null}
    >
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={`
                    ${styles.item__wrapper}
                    ${snapshot.isDragging ? styles['item__wrapper--dragged'] : ''}
                `}
            >
                <StyledLink
                    to={`request?id=${id}`}
                    className={`
                        ${styles.tr}
                        ${styles.tr__item}
                        ${isNew ? styles['tr__item--new'] : ''}
                    `}
                    color={`#${colorItem}`}
                >
                    <div
                        className={`${styles.td}`}
                    >
                        {
                            projectVisibility === true
                                ? (
                                    <span className={styles.project}>
                                        {project && project.name}
                                    </span>
                                )
                                : null
                        }
                        {caption}
                    </div>
                    <div
                        className={`${styles.td} ${styles['cell--deadline']}`}
                    >
                        {
                            lastMileStone
                                ? (
                                    <>
                                        <div className={styles.date}>
                                            {lastMileStone && lastMileStone.date}
                                        </div>
                                        <div>{lastMileStone && lastMileStone.caption}</div>
                                    </>
                                )
                                : (<div className={styles['is-empty']} />)
                        }
                    </div>
                    <div
                        className={styles.td}
                    >
                        {
                            price && price !== ''
                                ? (
                                    <div>
                                        <span className={styles['icon-inline']}><RubCircleIcon /></span>
                                        {`${price} ₽`}
                                    </div>
                                )
                                : null
                        }
                        {
                            workingHours && workingHours !== ''
                                ? (
                                    <div>
                                        <span className={styles['icon-inline']}><TimeCircleIcon /></span>
                                        {workingHours}
                                    </div>
                                )
                                : null
                        }
                        {
                            (!price || price === '')
                            && (!workingHours || workingHours === '')
                                ? (<div className={styles['is-empty']} />)
                                : null
                        }
                    </div>
                    <div
                        {...provided.dragHandleProps}
                        className={`${styles.td} ${styles.icon}`}
                    >
                        {
                            isPending === true
                                ? (<div className={styles.loader}><Loader color="#457eca" /></div>)
                                : (
                                    <DragAndDropIcon color="currentColor" />
                                )
                        }
                    </div>
                </StyledLink>
            </div>
        )}
    </Draggable>
);

RequestItem.propTypes = {
    id: PropTypes.string.isRequired,
    project: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    price: PropTypes.string,
    projectVisibility: PropTypes.bool.isRequired,
    caption: PropTypes.string.isRequired,
    workingHours: PropTypes.string,
    colorItem: PropTypes.string.isRequired,
    lastMileStone: PropTypes.shape({
        date: PropTypes.string,
        caption: PropTypes.string,
    }),
    isNew: PropTypes.bool.isRequired,
    innerRef: PropTypes.shape({}),
    isPending: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    pendingOrderRequest: PropTypes.shape({}),
};

RequestItem.defaultProps = {
    price: null,
    workingHours: null,
    lastMileStone: null,
    innerRef: null,
    pendingOrderRequest: null,
};

export default RequestItem;
