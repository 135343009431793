const historyQuery = (page, perPage) => `
    query{
        history (page: ${page}, first: ${perPage}) {
            data {
                id
                datetime
                text
                url
            }
            paginatorInfo {
                currentPage
                lastPage
            }
        }
    }
`;

export default historyQuery;

export {
    historyQuery,
};
