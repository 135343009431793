import React from 'react';
import {
    Switch,
    Route,
} from 'react-router-dom';
import RequestListPage from '../RequestListPage';
import RequestPage from '../RequestPage';
import InvoiceListPage from '../InvoiceListPage';
import InvoicePage from '../InvoicePage';
import StartupPage from '../StartupPage';
import { NeedAuthWrapper } from '../../containers/PageWrapper';

class PrivatePage extends React.Component {
    render() {
        return (
            <NeedAuthWrapper>
                <Switch>
                    <Route exact path="/" component={StartupPage} />
                    <Route exact path="/startup" component={StartupPage} />
                    <Route exact path="/requests" component={RequestListPage} />
                    <Route exact path="/request" component={RequestPage} />
                    <Route exact path="/invoice-list" component={InvoiceListPage} />
                    <Route exact path="/invoice" component={InvoicePage} />
                </Switch>
            </NeedAuthWrapper>
        );
    }
}

export default PrivatePage;
