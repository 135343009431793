import React from 'react';
import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

const Loader = ({
    /** Цвет линий */
    color,
    /** Отступ от каждой линии */
    spaceSize,
    /**
     * Высота (линий) в px. Для
     * получения высоты линий, как в макете
     * необходимо разделить на 1.8 (т.к.
     * применяется эффект scale(1.8)
     */
    heightLine,
    /** Ширина линий в px */
    widthLine,
}) => (
    <div
        className={styles.loader}
        style={{
            height: `${(Number(heightLine) * 1.8)}px`,
        }}
    >
        <span
            style={{
                backgroundColor: color,
                margin: `0 ${spaceSize}px`,
                height: `${heightLine}px`,
                width: `${widthLine}px`,
            }}
        />
        <span
            style={{
                backgroundColor: color,
                margin: `0 ${spaceSize}px`,
                height: `${heightLine}px`,
                width: `${widthLine}px`,
            }}
        />
        <span
            style={{
                backgroundColor: color,
                margin: `0 ${spaceSize}px`,
                height: `${heightLine}px`,
                width: `${widthLine}px`,
            }}
        />
        <span
            style={{
                backgroundColor: color,
                margin: `0 ${spaceSize}px`,
                height: `${heightLine}px`,
                width: `${widthLine}px`,
            }}
        />
    </div>
);

Loader.propTypes = {
    color: PropTypes.string,
    spaceSize: PropTypes.number,
    heightLine: PropTypes.number,
    widthLine: PropTypes.number,
};

Loader.defaultProps = {
    color: '#fff',
    spaceSize: 1,
    heightLine: 11,
    widthLine: 3,
};

export default Loader;
