import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';
import styled from 'styled-components';
import { EMPTY_DATA_STRING } from '../../constants';
import { toTextWithWrapper } from '../../utils';

import styles from './InvoiceList.module.scss';

const StyledLink = styled(Link)`
    border-left: 4px solid ${(props) => (props.color)} !important;
`;

const InvoiceItem = ({
    id,
    docnumber,
    docdate,
    invoicestatus,
    text,
    sumFormatted,
}) => (
    <StyledLink
        to={`/invoice?id=${id}`}
        className={`${styles.tr} ${styles.tr__item}`}
        color={`#${invoicestatus.color}`}
    >
        {invoicestatus.color}
        <div
            className={`${styles.td}`}
        >
            <div
                className={`${styles.content}`}
            >
                <div className={styles.heading}>
                    {`Счет №${docnumber} от ${docdate}`}
                </div>
                <TruncateMarkup
                    tokenize="words"
                    lines={2}
                >
                    <div className={styles.description}>
                        {toTextWithWrapper(text)}
                    </div>
                </TruncateMarkup>
            </div>
        </div>
        <div
            className={styles.td}
        >
            <div
                className={styles.status}
                style={{ color: `#${invoicestatus.color}` }}
            >
                {`${invoicestatus.name}`}
            </div>
        </div>
        <div
            className={styles.td}
        >
            <div className={styles.sum}>
                {`${sumFormatted}`}
            </div>
        </div>
    </StyledLink>
);

InvoiceItem.propTypes = {
    id: PropTypes.string.isRequired,
    docnumber: PropTypes.string,
    docdate: PropTypes.string,
    invoicestatus: PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    text: PropTypes.string,
    sumFormatted: PropTypes.string.isRequired,
};

InvoiceItem.defaultProps = {
    docdate: EMPTY_DATA_STRING,
    docnumber: EMPTY_DATA_STRING,
    text: EMPTY_DATA_STRING,
};

export default InvoiceItem;
