import React from 'react';
import PropTypes from 'prop-types';

const PhoneIcon = ({
    width,
    height,
    color,
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 1.33325H2.33333C1.59695 1.33325 1 1.93021 1 2.66659V13.3333C1 14.0696 1.59695 14.6666 2.33333 14.6666H9C9.73638 14.6666 10.3333 14.0696 10.3333 13.3333V2.66659C10.3333 1.93021 9.73638 1.33325 9 1.33325Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.66602 12H5.67268"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

PhoneIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

PhoneIcon.defaultProps = {
    width: 11,
    height: 16,
    color: '#457eca',
};

export default PhoneIcon;
