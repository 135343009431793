import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import Button from '../Button';
import { PhoneIcon } from '../SvgIcons';
import FormField from '../FormField/FormField';

import styles from './Registration.module.scss';

const SecondStep = ({
    form,
    loading,
}) => (
    <>
        <h2
            className={`
                ${styles.registration__title}
                ${styles['registration__title--second']}
            `}
        >
            Регистрация
        </h2>
        <p
            className={styles.registration__text}
        >
            Подтвердите E-mail, чтобы продолжить
            <br />
            Мы выслали проверочный код на введеный
            <br />
            E-mail
        </p>
        <form onSubmit={form.onSubmit} autoComplete="off">
            <FormField field={form.$('code')} validateOnBlur>
                <Input
                    type="text"
                    id="registration_verify_code"
                    icon={(<PhoneIcon color="currentColor" />)}
                />
            </FormField>
            <div
                className={styles.registration__button}
            >
                <Button
                    type="submit"
                    loading={loading}
                >
                    Продолжить
                </Button>
            </div>
        </form>
    </>
);

SecondStep.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func.isRequired,
        $: PropTypes.func.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default SecondStep;
