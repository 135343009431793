import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({
    size,
    color,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.3332 14V12.6667C13.3332 11.9594 13.0522 11.2811 12.5521 10.781C12.052 10.281 11.3737 10 10.6665 10H5.33317C4.62593 10 3.94765 10.281 3.44755 10.781C2.94746 11.2811 2.6665 11.9594 2.6665 12.6667V14"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.00016 7.33333C9.47292 7.33333 10.6668 6.13943 10.6668 4.66667C10.6668 3.19391 9.47292 2 8.00016 2C6.5274 2 5.3335 3.19391 5.3335 4.66667C5.3335 6.13943 6.5274 7.33333 8.00016 7.33333Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

UserIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

UserIcon.defaultProps = {
    size: 16,
    color: '#457eca',
};

export default UserIcon;
