import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { EditIcon, RubCircleIcon, TimeCircleIcon } from '../SvgIcons';
import { EMPTY_DATA_STRING } from '../../constants';

import styles from './Request.module.scss';

class RequestTop extends React.Component {
    render() {
        const {
            title,
            sum,
            workingHours,
            handleEditClick,
            status,
        } = this.props;

        return (
            <div className={styles.top}>
                <div className={styles.top__title}>
                    <h1 className={styles.title}>{title}</h1>
                    <button
                        type="button"
                        className={styles['button-edit']}
                        onClick={handleEditClick}
                    >
                        <EditIcon />
                    </button>
                    <div
                        className={styles.top__status}
                        style={{
                            backgroundColor: `#${status && status.colorscheme.textcolor}`,
                        }}
                    >
                        {status && status.caption}
                    </div>
                </div>
                <div className={styles.top__info}>
                    <div>
                        <span className={styles['top__info-icon']}>
                            <RubCircleIcon />
                        </span>
                        <span className={styles['top__info-text']}>
                            {`${sum} ₽`}
                        </span>
                    </div>
                    <div>
                        <span className={styles['top__info-icon']}>
                            <TimeCircleIcon />
                        </span>
                        <span className={styles['top__info-text']}>
                            {workingHours}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
// const RequestTop = ({
//     title,
//     sum,
//     workingHours,
//     handleEditClick,
// }) => (

// );

RequestTop.propTypes = {
    title: PropTypes.string,
    sum: PropTypes.string,
    workingHours: PropTypes.string,
    handleEditClick: PropTypes.func.isRequired,
};

RequestTop.defaultProps = {
    title: EMPTY_DATA_STRING,
    sum: 0,
    workingHours: 0,
};

export default inject('statusListStore')(RequestTop);
