import validatorjs from 'validatorjs';

validatorjs.useLang('ru');

validatorjs.prototype.setAttributeNames = function setAttributeNames(attributes) {
    if (!attributes) return;
    const modified = { ...attributes };
    const keys = Object.keys(modified);
    for (let i = 0; i < keys.length; i += 1) {
        const attribute = keys[i];
        modified[attribute] = modified[attribute].toLowerCase();
    }
    this.messages._setAttributeNames(modified);
};

const rules = {
    privacyPolicy: {
        function: (value) => value > 0,
        message: 'Необходимо принять условия',
    },
};

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ({ validator }) => {
            Object.keys(rules).forEach(
                (key) => validator.register(key, rules[key].function, rules[key].message),
            );
            validator.stopOnError(true);
        },
    },
};

export default plugins;
export {
    plugins,
};
