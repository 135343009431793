import { decorate, observable, action } from 'mobx';

class AppStore {
    menuIsOpen = false;

    toggleMenu() {
        this.menuIsOpen = !this.menuIsOpen;
    }

    closeMenu() {
        this.menuIsOpen = false;
    }
}

decorate(AppStore, {
    menuIsOpen: observable,
    toggleMenu: action,
});

export default AppStore;
