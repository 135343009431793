import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import Button from '../../components/Button';
import { CloseIcon } from '../../components/SvgIcons';

import styles from './NewRequestDropZone.module.scss';

class NewRequestDropZone extends React.Component {
    static propTypes = {
        onDrop: PropTypes.func.isRequired,
        onRemoveFile: PropTypes.func.isRequired,
        list: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        multiple: PropTypes.bool,
    };

    static defaultProps = {
        list: [],
        multiple: false,
    };

    render() {
        const {
            onDrop,
            onRemoveFile,
            list,
            multiple,
        } = this.props;
        return (
            <div
                className={styles.container}
            >
                <Dropzone
                    onDrop={onDrop}
                    multiple={multiple}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <input {...getInputProps()} />
                            <Button
                                theme="with-border-square"
                                fullWidth={false}
                                {...getRootProps()}
                            >
                                + Добавить файл
                            </Button>
                        </section>
                    )}
                </Dropzone>
                {
                    list && list.length
                        ? (
                            <div
                                className={styles.list}
                            >
                                {
                                    list.map((file, index) => (
                                        <div
                                            className={styles.list__item}
                                            key={nextId('uploaded_file_for_new_request')}
                                        >
                                            <span className={styles['list__item-text']}>
                                                {file.path}
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => onRemoveFile(index)}
                                                className={styles['list__item-remove']}
                                            >
                                                <CloseIcon width={11} height={11} fill="currentColor" />
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default NewRequestDropZone;
