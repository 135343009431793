import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { PageWrapper } from '../../containers/PageWrapper';
import Sidebar from '../../containers/Sidebar';
import Request from '../../containers/Request';
import Dialog from '../../containers/Dialog/Dialog';
import RequestEditWrapper from '../../components/Request/RequestEditWrapper';
import DialogStore from '../../stores/DialogStore';
import RequestStore from '../../stores/RequestStore';
import request from '../../utils/request';
import { GRAPHQL_HOST, EMPTY_DATA_STRING } from '../../constants';
import { getItem } from '../../utils/localStorage';
import editRequest from './queries';
import { TitleComponent } from '../../components/TitleComponent';

import styles from './RequestPage.module.scss';

class RequestPage extends React.Component {
    static propTypes = {
        requestStore: PropTypes.instanceOf(RequestStore).isRequired,
        dialogStore: PropTypes.instanceOf(DialogStore).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor() {
        super();
        this.state = {
            loading: false,
            name: null,
            description: null,
        };
    }

    componentDidMount() {
        const { location, requestStore } = this.props;
        const parsed = queryString.parse(location && location.search);
        const { id } = parsed;
        requestStore.getData(id)
            .then(() => {
                this.setState({
                    name: requestStore.title,
                    description: requestStore.description,
                });
            });
    }

    componentWillUnmount() {
        const { requestStore } = this.props;
        requestStore.reset();
    }

    handleSuccess = (form) => {
        const {
            dialogStore,
            requestStore,
            requestStore: {
                id,
            },
        } = this.props;
        const formValues = form.values();
        this.setState({ loading: true });
        const authToken = getItem('accessToken');
        if (!authToken) return Promise.resolve(null);
        // Установим значения для избежания
        // "подёргивания" контента при обновлении
        this.setState({
            name: formValues.name,
            description: formValues.description,
        });
        return request({
            method: 'POST',
            url: GRAPHQL_HOST,
            authToken,
            data: {
                query: editRequest,
                variables: {
                    id,
                    caption: formValues.name,
                    text: formValues.description,
                },
            },
        }).then(() => {
            form.clear();
            dialogStore.hide();
            return requestStore.getData(id);
        }).catch(() => {
            // Установим значения из store в случае
            // ошибки
            this.setState({
                name: requestStore.title,
                description: requestStore.description,
            });
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    render() {
        const {
            requestStore: {
                id,
            },
        } = this.props;
        const { loading, name, description } = this.state;
        const initialFields = [{
            name: 'name',
            value: name,
        }, {
            name: 'description',
            value: description,
        }];
        return (
            <PageWrapper>
                <TitleComponent title={`Страница заявки #${id || EMPTY_DATA_STRING}`} />
                <Container>
                    <div className={styles.container}>
                        <Row>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                            <Col md={9}>
                                <Request />
                            </Col>
                        </Row>
                    </div>
                    <Dialog
                        name="edit_request"
                        size="lg"
                        footer={null}
                        header="Редактировать"
                    >
                        <RequestEditWrapper
                            handleSuccess={this.handleSuccess}
                            initial={initialFields}
                            loading={loading}
                        />
                    </Dialog>
                </Container>
            </PageWrapper>
        );
    }
}

export default inject(
    'dialogStore',
    'requestStore',
)(
    observer(RequestPage),
);
