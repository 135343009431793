import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { EmailIcon } from '../../components/SvgIcons';
import FormField from '../../components/FormField/FormField';
import Input from '../../components/Input';
import Button from '../../components/Button';

import styles from './AddNewMember.module.scss';

class AddNewMember extends React.Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func.isRequired,
            $: PropTypes.func.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    render() {
        const { form } = this.props;
        return (
            <form
                onSubmit={form.onSubmit}
                className={styles.form}
            >
                <FormField field={form.$('email')} validateOnBlur>
                    <Input
                        type="text"
                        id="login_phone"
                        icon={(<EmailIcon color="currentColor" />)}
                    />
                </FormField>
                <div
                    className={styles.button}
                >
                    <Button
                        type="submit"
                        loading={form.loading}
                        theme="green"
                    >
                        Добавить
                    </Button>
                </div>
            </form>
        );
    }
}

export default observer(AddNewMember);
