import axios from 'axios';
import eventEmitter from './eventEmitter';

const getErrorMessage = (errorList) => {
    if (
        errorList
        && errorList.length
    ) {
        const messages = [];
        errorList.forEach((error) => {
            messages.push(error.message);
        });
        return messages.join(' ');
    }
    return '';
};

/**
 * Метод для отправки запроса с заданными
 * параметрами
 *
 * @param {object} param0 -
 * @param {string} param0.method POST, GET
 * @param {string} param0.url целевой url
 * @param {string} param0.authToken токен пользователя
 * @param {FormData|object} param0.data значение формы
 * @param {Function} param0.onError действие при возникновение ошибки,
 * по умолчанию отображается сообщение об ошибке
 * @returns {Promise} Promise
 */
export default ({
    method,
    url,
    authToken,
    data,
    onError,
    responseType,
}) => {
    console.log('url', url, data);
    const reqOptions = {
        method,
        url,
        data,
        responseType,
    };
    if (authToken) {
        reqOptions.headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return axios(reqOptions).then(
        (res) => {
            if (
                res
                && res.data
                && res.data.errors
                && res.data.errors.length
            ) {
                return Promise.reject(res.data.errors);
            }
            return { res: res.data, raw: res };
        },
    ).catch((error) => {
        const errorMessage = getErrorMessage(error);
        if (
            errorMessage === 'Unauthenticated.'
        ) {
            eventEmitter.emit('user:unauthorized');
            eventEmitter.emit('modal:error', { message: 'Пользователь не авторизован' });
            return Promise.reject(error);
        }
        if (errorMessage && errorMessage !== '') {
            if (onError) {
                onError(error);
            } else {
                eventEmitter.emit(
                    'modal:error',
                    {
                        message: errorMessage,
                        withReturnButton: false,
                    },
                );
            }
        }
        return Promise.reject(error);
    });
};
