const mutationLogin = `
    mutation myLogin(
        $email: String!
        $password: String!
    ) {
        Login(
            email: $email
            password: $password
        ) {
            access_token
            expires_in
        }
    }
`;

const userInfo = `query { me { id email role firstname account_id } }`;

export default mutationLogin;

export {
    mutationLogin,
    userInfo,
};
