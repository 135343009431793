import {
    observable,
    action,
    decorate,
    computed,
} from 'mobx';

const DEFAULT_ITEMS_PER_PAGE = 15;
const DEFAULT_PAGE_RANGE = 7;

class PaginationModel {
    constructor({
        totalItemsCount,
        itemsCountPerPage,
        pageRangeDisplayed,
    }) {
        this.totalItemsCount = totalItemsCount;
        this.itemsCountPerPage = itemsCountPerPage || DEFAULT_ITEMS_PER_PAGE;
        this.pageRangeDisplayed = pageRangeDisplayed || DEFAULT_PAGE_RANGE;
    }

    activePage = 1;

    itemsCountPerPage;

    totalItemsCount;

    pageRangeDisplayed;

    get lastPage() {
        return Math.ceil(this.totalItemsCount / this.itemsCountPerPage);
    }

    update(newState) {
        Object.keys(newState).forEach((key) => {
            this[key] = newState[key];
        });
    }

    handleChange = (page) => {
        let activePage = page;
        if (page > this.lastPage) activePage = this.lastPage;
        if (page < 1) activePage = 1;
        this.activePage = activePage;
    }
}

decorate(PaginationModel, {
    activePage: observable,
    itemsCountPerPage: observable,
    totalItemsCount: observable,
    pageRangeDisplayed: observable,
    lastPage: computed,
    update: action,
    handleChange: action,
});

export default PaginationModel;
