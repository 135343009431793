const registerUserStep1 = `
    mutation myRegisterUserStep1(
        $email: String!
        $phone: String!
        $firstname: String!
        $token: String!
    ) {
        RegisterUserStep1(
            email: $email
            phone: $phone
            firstname: $firstname
            token: $token
        )
    }
`;

const registerUserStep2 = `
    mutation myRegisterUserStep2(
        $email: String!
        $code: String!
    ) {
        RegisterUserStep2(
            email: $email
            code: $code
        )
    }
`;

const registerUserStep3 = `
    mutation myRegisterUserStep3(
        $email: String!
        $code: String!
        $password: String!
    ) {
        RegisterUserStep3(
            email: $email
            code: $code
            password: $password
        ) {
            access_token
            expires_in
        }
    }
`;

export {
    registerUserStep1,
    registerUserStep2,
    registerUserStep3,
};
