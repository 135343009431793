const invoiceQuery = (id) => (`
    query {
        invoice(id: ${id}) {
            id
            createdate
            docnumber
            docdate
            sum
            paid
            project {
                name
            }
            duedate
            uid
            text
            files {
                uid
                filename
            }
            invoicestatus {
                name
                color
            }
        }
    }
`);

export default invoiceQuery;

export {
    invoiceQuery,
};
