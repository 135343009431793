import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = ({
    width,
    height,
    color,
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="24"
            height="2"
            fill={color}
        />
        <rect
            y="6.95801"
            width="24"
            height="2"
            fill={color}
        />
    </svg>
);

MenuIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

MenuIcon.defaultProps = {
    width: 24,
    height: 10,
    color: '#fff',
};

export default MenuIcon;
